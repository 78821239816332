/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Form, Image, Row, Tab, Tabs, } from "react-bootstrap";
import { StorageManagerService, UtilisateurService } from "../../Services";
import "bootstrap/dist/css/bootstrap.min.css";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { Avatar } from "../../Shared/Layout/Avatar";
import { ConfirmationModal } from "../../Shared/ConfirmationModal";
import { SelectOptions, User } from "../../Utils/types";
import "./Profil.scss";
import { useStore } from "../../hooks-store/store";
import { SelectField } from "../../Shared/Components";
import AlertSuccess from "../../assets/alert/alert-success.svg";
import AlertError from "../../assets/alert/alert-error.svg";
import CheckboxField from "../../Shared/Components/CheckboxField/CheckboxField";

const NOTIF_DELAY = 50000;

const Profil = () => {
  const store = useStore()[0];
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [showConfirmEditUserModal, setShowConfirmEditUserModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  // Messages
  const [messageSuccess, setMessageSuccess] = useState("");
  const [messageError, setMessageError] = useState("");
  const [messagePasswordSuccess, setMessagePasswordSuccess] = useState("");
  const [messagePasswordError, setMessagePasswordError] = useState("");
  const [messageNotificationSuccess, setMessageNotificationSuccess] = useState("");
  const [messageNotificationError, setMessageNotificationError] = useState("");

  const [services, setServices] = useState<SelectOptions[]>([]);
  const [fonctions, setFonctions] = useState<SelectOptions[]>([]);
  const [modeContactPriv, setModeContactPriv] = useState<SelectOptions[]>([]);
  const [notificationFrequence, setNotificationFrequence] = useState<any>(null);
  const [notificationFrequences, setNotificationFrequences] = useState<any>([]);
  const [fonctionSelected, setFonctionSelected] = useState<string>("");
  const [serviceSelected, setServiceSelected] = useState<string>("");
  const [modeContactPrivSelected, setModeContactPrivSelected] = useState<string>("");
  const [updateId, setUpdateId] = useState<number>(1);
  const formikRef = useRef(null as any);
  const phoneRegEx = /^[0-9 -\\.]*$/;
  const [user, setUser] = useState<User>({
    nom: "",
    prenom: "",
    email: "",
    id: "",
    active: false,
    telephone: "",
    telephoneMobile: "",
    representants: [],
    forets: [],
    titre: "",
    service: "",
    serviceONF: "",
    fonctionONF: "",
    codeOrigine: "",
    modeContactPriviligier: "",
    role: 0,
    notifMessage: false,
    avatar: undefined,
    complementforets: [],
    horaires: "",
    notificationFrequence: 0,
  });

  useEffect(() => {
    const getUserById = () => {
      const getAllNotificationFrequences = (userId: string) => {
        UtilisateurService.getAllNotificationFrequences(userId)
          .then((res: any) => {
            setNotificationFrequences(res?.value?.data);
          })
          .catch((error: any) => {
            throw error;
          });
      };
      if (!user.id || user.id === "" || user.id === "0") {
        setLoading(true);
        UtilisateurService.getCurrentUser().then((res: any) => {
          if (res.isSuccess) {
            setUser({ ...res?.value?.data, role: res?.value?.data?.role?.id });
            setFonctionSelected(res?.value?.data.titre);
            setServiceSelected(res?.value?.data.service);
            setModeContactPrivSelected(res?.value?.data.modeContactPriviligier);
            getAllNotificationFrequences(res?.value?.data?.id);
            setNotificationFrequence(res?.value?.data?.notificationFrequence);
          }
          setLoading(false);
        });
        setUpdateId(0);
      }
    };

    getUserById();
  }, []);

  const addUserShowModal = (isValid: boolean) => {
    if (isValid) setShowConfirmEditUserModal(true);
  };

  const updateUser = (values: any) => {
    setUser({ ...user, ...values });
  };

  const editUser = () => {
    setLoadingSave(true);

    const newUser: any = {
      id: user.id,
      version: user.version,
      telephone: user.telephone,
      telephoneMobile: user.telephoneMobile,
      titre: user.titre,
      service: user.service,
      modeContactPriviligier: user.modeContactPriviligier,
      horaires: user.horaires,
    };

    UtilisateurService.updateCurrentUser(newUser).then((res: any) => {
      if (res.isSuccess()) {
        setShowConfirmEditUserModal(false);
        setMessageSuccess("Vos modifications ont bien été enregistrées.");
        setMessageError("");
      }
      setLoadingSave(false);
    });
  };

  const changePassword = (password: string) => {
    UtilisateurService.changePassword(password)
      .then((res: any) => {
        if (res.isSuccess()) {
          setMessagePasswordSuccess(
            "Bravo, votre mot de passe a bien été modifié."
          );
          setMessagePasswordError("");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const changeNotificationFrequence = (notificationFrequence: string) => {
    UtilisateurService.changeNotificationFrequence(notificationFrequence)
      .then((res: any) => {
        if (res.isSuccess()) {
          setMessageNotificationSuccess("Bravo, votre fréquence de notification a bien été modifiée.");
          setMessageNotificationError("");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    if (messageError !== "")
      setTimeout(() => {
        setMessageError("");
      }, NOTIF_DELAY);
  }, [messageError]);

  useEffect(() => {
    if (messageSuccess !== "")
      setTimeout(() => {
        setMessageSuccess("");
      }, NOTIF_DELAY);
  }, [messageSuccess]);

  useEffect(() => {
    if (messagePasswordError !== "")
      setTimeout(() => {
        setMessagePasswordError("");
      }, NOTIF_DELAY);
  }, [messagePasswordError]);

  useEffect(() => {
    if (messagePasswordSuccess !== "")
      setTimeout(() => {
        setMessagePasswordSuccess("");
      }, NOTIF_DELAY);
  }, [messagePasswordSuccess]);

  useEffect(() => {
    if (messageNotificationError !== "")
      setTimeout(() => {
        setMessageNotificationError("");
      }, NOTIF_DELAY);
  }, [messageNotificationError]);

  useEffect(() => {
    if (messageNotificationSuccess !== "")
      setTimeout(() => {
        setMessageNotificationSuccess("");
      }, NOTIF_DELAY);
  }, [messageNotificationSuccess]);

  useEffect(() => {
    const getAllFonctions = () => {
      UtilisateurService.getAllFonction()
        .then((res: any) => {
          let list: SelectOptions[] = [];
          res?.value?.data.map((item: any) => {
            list.push({ value: item.code, label: item.fonctionLabel });
          });
          setFonctions(list);
        })
        .catch((error: any) => {
          throw error;
        });
    };
    const getAllModeContactPriviligie = () => {
      UtilisateurService.getAllModeContactPriviligier().then((res: any) => {
        let list: SelectOptions[] = [];
        res?.value?.data.map((mode: any) => {
          list.push({ value: mode.code, label: mode.modeLabel });
        });
        setModeContactPriv(list);
      });
    };
    const getAllServices = () => {
      UtilisateurService.getAllService()
        .then((res: any) => {
          let list: SelectOptions[] = [];
          res?.value?.data.map((item: any) => {
            list.push({ value: item.code, label: item.serviceLabel });
          });
          setServices(list);
        })
        .catch((error: any) => {
          throw error;
        });
    };
    if (updateId === 1) {
      getAllFonctions();
      getAllServices();
      getAllModeContactPriviligie();
    }
  }, [updateId]);

  const handleChangeSelectService = (e: any, setFieldValue: Function) => {
    setServiceSelected(e.value);
    setFieldValue("service", e.value);
  };

  const handleChangeSelectFonction = (e: any, setFieldValue: Function) => {
    setFonctionSelected(e.value);
    setFieldValue("titre", e.value);
  };

  const handleChangeSelectModeContactPriv = (
    e: any,
    setFieldValue: Function
  ) => {
    setModeContactPrivSelected(e.value);
    setFieldValue("modeContactPriviligier", e.value);
  };

  const disablePrimaryExternalUser = () => {
    return StorageManagerService.getCurrentOrigin() === "portail-formulaire";
  };

  const generateNotificationFrequenciesElement = (notificationFrequences:any[], values: any, setFieldValue: any) => {
    let radioButtons: any[] = [];
    for (let i = 0; i < notificationFrequences.length; i++) {
      let f = notificationFrequences[i];
      let radioButton = (
        <Row className="form-inline">
          <Field
            id={f.id}
            type="radio"
            name="frequence"
            className="d-flex"
            value={f.id}
            checked={notificationFrequence == f.id}
            component={CheckboxField}
            onChange={(e: any) => {
              setNotificationFrequence(e.target.id);
              setFieldValue("frequence", e.target.id)
            }}
            label={f.label}
          />
        </Row>
      );
      radioButtons.push(radioButton);
    }
    return radioButtons;
  };

  return (
    <Tabs
      defaultActiveKey="informations"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="informations" title="Mes informations">
        <div className="container-bloc">
          <Avatar
            upload={true}
            loading={loading}
            binary={store.imgPath || user.avatar}
            width="200px"
            height="200px"
          />

          {messageError !== "" && (
            <Alert
              className="custom-alert error"
              show={messageError !== ""}
              onClose={() => setMessageError("")}
              dismissible
            >
              <div>
                <Image src={AlertError} />
              </div>
              <div>{messageError}</div>
            </Alert>
          )}

          {messageSuccess !== "" && (
            <Alert
              className="custom-alert success"
              show={messageSuccess !== ""}
              onClose={() => setMessageSuccess("")}
              dismissible
            >
              <div>
                <Image src={AlertSuccess} />
              </div>
              <div>{messageSuccess}</div>
            </Alert>
          )}

          <Formik
            innerRef={formikRef}
            enableReinitialize
            validateOnMount
            initialValues={{
              ...user,
              tels: user.telephone + user.telephoneMobile,
            }}
            validationSchema={Yup.object().shape({
              nom: Yup.string().required("Le nom est obligatoire"),
              prenom: Yup.string().required("Le prénom est obligatoire"),
              email: Yup.string()
                .email("Le formail du mail est invalide")
                .required("Le mail est obligatoire"),
              telephoneMobile: Yup.string().matches(
                phoneRegEx,
                "Le format du numéro est invalide"
              ),
              telephone: Yup.string().matches(
                phoneRegEx,
                "Le format du numéro est invalide"
              ),
            })}
            onSubmit={(values) => {
              updateUser(values);
            }}
            validateOnBlur
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              isValid,
            }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  onBlur={() => {
                    setFieldValue(
                      "tels",
                      values.telephone + values.telephoneMobile
                    );
                  }}
                >
                  <div className="row">
                    <div className="col">
                      <Form.Label>Nom</Form.Label>
                      <Field
                        as={Form.Control}
                        name="nom"
                        type="text"
                        isInvalid={touched.nom && !!errors.nom}
                        disabled={true}
                      />
                      <ErrorMessage
                        name="nom"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col">
                      <Form.Label>Prénom</Form.Label>
                      <Field
                        as={Form.Control}
                        name="prenom"
                        type="text"
                        isInvalid={touched.prenom && !!errors.prenom}
                        disabled={true}
                      />
                      <ErrorMessage
                        name="prenom"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Form.Label>Mail</Form.Label>
                      <Field
                        as={Form.Control}
                        name="email"
                        type="text"
                        isInvalid={touched.email && !!errors.email}
                        disabled={true}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Form.Label>Service</Form.Label>
                      {!StorageManagerService.isInternalUser() ? (
                        <Field
                          name="service"
                          component={SelectField}
                          options={services}
                          isInvalid={touched.service && !!errors.service}
                          onChange={(e: any) =>
                            handleChangeSelectService(e, setFieldValue)
                          }
                          value={
                            services.filter(
                              (obj) => obj.value === serviceSelected
                            )[0]
                          }
                          isDisabled={disablePrimaryExternalUser()}
                        />
                      ) : (
                        <Field
                          as={Form.Control}
                          name="serviceONF"
                          type="text"
                          disabled={true}
                        />
                      )}
                      <ErrorMessage
                        name="service"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col">
                      <Form.Label>Fonction</Form.Label>
                      {!StorageManagerService.isInternalUser() ? (
                        <Field
                          name="titre"
                          component={SelectField}
                          options={fonctions}
                          isInvalid={touched.titre && !!errors.titre}
                          onChange={(e: any) =>
                            handleChangeSelectFonction(e, setFieldValue)
                          }
                          value={
                            fonctions.filter(
                              (obj) => obj.value === fonctionSelected
                            )[0]
                          }
                          isDisabled={disablePrimaryExternalUser()}
                        />
                      ) : (
                        <Field
                          as={Form.Control}
                          name="fonctionONF"
                          type="text"
                          disabled={true}
                        />
                      )}
                      <ErrorMessage
                        name="titre"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Form.Label>Téléphone mobile </Form.Label>
                      <Field
                        as={Form.Control}
                        name="telephoneMobile"
                        type="text"
                        isInvalid={
                          touched.telephoneMobile &&
                          (!!errors.telephoneMobile || !!errors.tels)
                        }
                        disabled={StorageManagerService.isInternalUser()}
                      />
                      <ErrorMessage
                        name="telephoneMobile"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col">
                      <Form.Label>Téléphone fixe</Form.Label>
                      <Field
                        as={Form.Control}
                        name="telephone"
                        type="text"
                        isInvalid={
                          touched.telephone &&
                          (!!errors.telephone || !!errors.tels)
                        }
                        disabled={StorageManagerService.isInternalUser()}
                      />
                      <ErrorMessage
                        name="telephone"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <ErrorMessage
                    name="tels"
                    component="div"
                    className="invalid-feedback"
                  />
                  {!StorageManagerService.isInternalUser() && (
                    <div className="row">
                      <div className="col">
                        <Form.Label>Mode de contact privilégié</Form.Label>
                        <Field
                          name="modeContactPriviligier"
                          component={SelectField}
                          options={modeContactPriv}
                          isInvalid={
                            touched.modeContactPriviligier &&
                            !!errors.modeContactPriviligier
                          }
                          onChange={(e: any) =>
                            handleChangeSelectModeContactPriv(e, setFieldValue)
                          }
                          value={
                            modeContactPriv.filter(
                              (obj) => obj.value === modeContactPrivSelected
                            )[0]
                          }
                          isDisabled={StorageManagerService.isInternalUser()}
                        />
                        <ErrorMessage
                          name="service"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col">
                        <Form.Label className="horaires-width">Horaires privilégiés</Form.Label>
                        <Field
                          as="textarea"
                          name="horaires"
                          className="horaires-width form-control"
                          rows={3}
                          disabled={StorageManagerService.isInternalUser()}
                          isInvalid={touched.horaires && !!errors.horaires}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "12px",
                    }}
                  >
                    {!StorageManagerService.isInternalUser() && (
                      <Button
                        id="save-modification-button"
                        type="submit"
                        variant="outline-success"
                        onClick={() => addUserShowModal(isValid)}
                      >
                        Enregistrer les modifications
                      </Button>
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>

        <ConfirmationModal
          showConfirmModal={showConfirmEditUserModal}
          role=""
          loading={loadingSave}
          setShowConfirmModal={setShowConfirmEditUserModal}
          action={editUser}
        />
      </Tab>

      {!StorageManagerService.isInternalUser() && (
          <Tab eventKey="password" title="Mon mot de passe">
            <Formik
              initialValues={{
                password: "",
                confirm: "",
                checkEqual: "",
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required("Entrer le mot de passe")
                  .min(8, "8 caractères minimum")
                  .matches(new RegExp("(?=.*[a-z])"), "Au moins une minuscule")
                  .matches(new RegExp("(?=.*[A-Z])"), "Au moins une majuscule")
                  .matches(new RegExp("(?=.*[0-9])"), "Au moins un chiffre")
                  .matches(
                    new RegExp("(?=.*[-+_!@#$%^&*.,?])"),
                    "Au moins un caractère spécial"
                  ),
                confirm: Yup.string().required("Confirmer le mot de passe"),
                checkEqual: Yup.boolean().isTrue(
                  "Les mots de passe ne sont pas identiques"
                ),
              })}
              onSubmit={(values, { resetForm }) => {
                changePassword(values.password);
                resetForm({
                  values: {
                    password: "",
                    confirm: "",
                    checkEqual: "",
                  },
                });
              }}
              enableReinitialize
            >
              {({ values, errors, touched, handleSubmit, setFieldValue }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    onBlur={() => {
                      setFieldValue(
                        "checkEqual",
                        !values.confirm || values.password === values.confirm
                      );
                    }}
                  >
                    <div className="change-password-container">
                      <div className="password-form-container">
                        <p className="message">
                          Pour changer votre mot de passe, complétez les
                          informations ci-dessous.
                        </p>

                        {messagePasswordError !== "" && (
                          <Alert
                            className="custom-alert danger"
                            show={messagePasswordError !== ""}
                            onClose={() => setMessagePasswordError("")}
                            dismissible
                          >
                            <div>
                              <Image src={AlertError} />
                            </div>
                            <div>{messagePasswordError}</div>
                          </Alert>
                        )}

                        {messagePasswordSuccess !== "" && (
                          <Alert
                            className="custom-alert success"
                            show={messagePasswordSuccess !== ""}
                            onClose={() => setMessagePasswordSuccess("")}
                            dismissible
                          >
                            <div>
                              <Image src={AlertSuccess} />
                            </div>
                            <div>{messagePasswordSuccess}</div>
                          </Alert>
                        )}
                        <Row>
                          <Col>
                            <Form.Label>
                              Saisissez votre nouveau mot de passe
                            </Form.Label>
                            <Field
                              className="password-field"
                              as={Form.Control}
                              name="password"
                              type="password"
                              isInvalid={touched.password && !!errors.password}
                              placeholder="Mot de passe"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                          <Col>
                            <Form.Label>
                              Ressaisissez votre nouveau mot de passe
                            </Form.Label>
                            <Field
                              className="password-field"
                              as={Form.Control}
                              name="confirm"
                              type="password"
                              isInvalid={
                                touched.confirm &&
                                (!!errors.confirm || !!errors.checkEqual)
                              }
                              placeholder="Confirmation mot de passe"
                            />
                            <ErrorMessage
                              name="confirm"
                              component="div"
                              className="invalid-feedback"
                            />
                            <ErrorMessage
                              name="checkEqual"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                        </Row>

                        <div className="actions py-4">
                          <Button
                            type="submit"
                            variant="outline-success"
                            className="submit-button"
                          >
                            Enregistrer
                          </Button>
                        </div>
                        <Row className="container-rules">
                          <Col className="container-left">
                            * Au moins une majuscule
                            <br /> * Au moins une minuscule
                            <br /> * Au moins un caractère spécial
                          </Col>
                          <Col className="container-right">
                            * Au moins un chiffre
                            <br /> * 8 caractères minimum
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Tab>
      )}

      {!StorageManagerService.isInternalUser() && (
          <Tab eventKey="notification" title="Mes notifications">
            <Formik
              initialValues={{
                notificationFrequence: "",
              }}
              validationSchema={Yup.object().shape({
                notificationFrequence: Yup.string().required("sélectionnez parmi les options"),
              })}
              onSubmit={() => {}}
              enableReinitialize
            >
              {({ values, errors, touched, handleSubmit, setFieldValue }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                  >
                    <div className="change-frequence-notification-container">
                      <div className="frequence-notification-form-container">
                        <p className="message">
                          Pour changer votre fréquence de notification, sélectionnez parmi les options ci-dessous puis enregistrez.
                        </p>

                        {messageNotificationError !== "" && (
                          <Alert
                            className="custom-alert danger"
                            show={messageNotificationError !== ""}
                            onClose={() => setMessageNotificationError("")}
                            dismissible
                          >
                            <div>
                              <Image src={AlertError} />
                            </div>
                            <div>{messageNotificationError}</div>
                          </Alert>
                        )}

                        {messageNotificationSuccess !== "" && (
                          <Alert
                            className="custom-alert success"
                            show={messageNotificationSuccess !== ""}
                            onClose={() => setMessageNotificationSuccess("")}
                            dismissible
                          >
                            <div>
                              <Image src={AlertSuccess} />
                            </div>
                            <div>{messageNotificationSuccess}</div>
                          </Alert>
                        )}

                        <div role="group">
                          <label className="radio-label">Fréquences de notification</label>
                          {generateNotificationFrequenciesElement(notificationFrequences, values, setFieldValue)}
                        </div>

                        <div className="actions py-4 d-flex justify-content-center">
                          <Button
                            type="submit"
                            variant="outline-success"
                            className="submit-button"
                            onClick={() => {
                              changeNotificationFrequence(notificationFrequence);

                              let newUser = {...user};
                              newUser.notificationFrequence = Number(notificationFrequence);
                              setUser(newUser);              
                            }}
                          >
                            Enregistrer
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Tab>
      )}

    </Tabs>
  );
};

export default Profil;
