import React, {useState} from "react";
import "jspdf-autotable";
import Button from "react-bootstrap/Button";
import {ExportModal} from "./ExportModal";
import {Image} from "react-bootstrap";
import DownloadIcon from "../assets/svg/download-circle.svg";

export interface Props {
  textButton?: string;
  isExcelLoading?: boolean;
  isPDFLoading?: boolean;
  exportAllCSV?: () => void;
  exportAllPDF?: () => void;
  exportSelectedCSV?: () => void;
  exportSelectedPDF?: () => void;
  disabled?: boolean;
}

export const ExportData: React.FC<Props> = (props) => {
  const {
    textButton,
    isExcelLoading,
    isPDFLoading,
    exportAllCSV,
    exportAllPDF,
    exportSelectedCSV,
    exportSelectedPDF,
    disabled,
  } = props;
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  return (
    <>
      <Button className="ml-3 mr-0" variant="link" onClick={handleShowModal} disabled={disabled}>
        <Image src={DownloadIcon} />{" "}
        <span>{textButton}</span>
      </Button>
      <ExportModal
        exportPdf={() =>
          exportSelectedPDF
            ? exportSelectedPDF()
            : exportAllPDF
            ? exportAllPDF()
            : null
        }
        exportExcel={() =>
          exportSelectedCSV
            ? exportSelectedCSV()
            : exportAllCSV
            ? exportAllCSV()
            : null
        }
        setShowModal={setShowModal}
        showModal={showModal}
        pdfLoading={isPDFLoading}
        excelLoading={isExcelLoading}
      />
    </>
  );
};
