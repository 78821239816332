import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import SvgLogout from "../../assets/svg/logout.svg";

const Logout = () => {
  const [redirect, setRedirect] = useState(false);

  const logout = () => {    
    localStorage.clear();
    setRedirect(true);
  };

  if (redirect) return <Redirect to="/login" />;

  return (
    <Button variant="link" onClick={logout} className="pl-0">
      Déconnexion{" "}
      <Image
        src={SvgLogout}
        height={14}
        width={14}
        style={{ marginLeft: "5px" }}
      />
    </Button>
  );
};

export default Logout;
