import { AbstractSocleService, ToasterService, ToastType } from "@onf/socleweb-react";

export function applyEmailExceptionInterception(service: AbstractSocleService) {
    service.httpService.axiosInstance.interceptors.response.use(function (response) {
        if (response?.status === 500 && response?.data?.code === 'EMAIL_FICHIER_INEXISTANT') {
            ToasterService.push({
                type: ToastType.Error,
                content: response.data.message,
                dateTime: true,
                timeout: -1
            });
            response.status = 400;
            response.data.status = 400;
        }
        return response;
    }, function (error) {
        return Promise.reject(error);
    });
}
