import configureCartoStore from "./carto-store";
import configureCoupesStore from './coupes-store'
import configureTFTStore from "./tft-store";
import configureForetStore from "./foret-store";
import configureAvatarStore from "./avatar-store";

export const initConfigStore = () => {
    configureCartoStore();
    configureCoupesStore();
    configureTFTStore();
    configureForetStore();
    configureAvatarStore();
}