/** Administrer le texte page d'accueil */
export const ACCUEIL_ADMIN = "PERM1";
/** Consulter le détail d'un représentant */
export const SHOW_DETAILS_REP = "PERM2";
/** Consulter la fiche contact d'un utilisateur */
export const SHOW_DETAILS_USER = "PERM3";
/** Créer un utilisateur */
export const USER_CREATE = "PERM4";
/** Modifier un utilisateur */
export const USER_MODIFY = "PERM5";
/** Activer un utilisateur */
export const USER_ACTIVATE = "PERM6";
/** Désactiver un utilisateur */
export const USER_DEACTIVATE = "PERM7";
/** Activer des utilisateurs en masse */
export const USER_ACTIVATE_MASS = "PERM8";
/** Désactiver des utilisateurs en masse */
export const USER_DEACTIVATE_MASS = "PERM9";
/** Supprimer un utilisateur */
export const USER_DELETE = "PERM10";
/** Créer un rôle */
export const ROLE_CREATE = "PERM11";
/** Modifier un rôle */
export const ROLE_MODIFY = "PERM12";
/** Supprimer un rôle */
export const ROLE_DELETE = "PERM13";
/** Affecter un rôle */
export const ROLE_AFFECT = "PERM14";
/** Ajouter/supprimer un périmètre complémentaire */
export const USER_ADD_PERIMETER = "PERM15";
/** Accéder à la gestion des utilisateurs */
export const USER_MANAGEMENT_ACCESS = "PERM16";
/** Administrer la FAQ */
export const FAQ_ADMIN_ACCESS = "PERM17";
/** Administrer le texte d'accueil de la gestion des utilisateurs */
export const USER_MANAGEMENT_ACCUEIL_ACCESS = "PERM18";
/** Créer un mémo */
export const MEMO_ADD = "PERM19";
/** Modifier un mémo */
export const MEMO_UPDATE = "PERM20";
/** Accéder au tableau de bord foret / groupement */
export const DASHBOARD_ACCESS = "PERM21";
/** Accéder à l'onglet info TFT */
export const TFT_ACCESS = "PERM22";
/** Accéder à l'onglet Coupes */
export const COUPE_ACCESS = "PERM23";
/** Accéder à l'onglet GED */
export const GED_ACCESS = "PERM24";
/** Accéder à l'onglet Mémos */
export const MEMO_ACCESS = "PERM25";
/** Accéder à l'onglet Bilan financier */
export const FINANCIAL_ACCESS = "PERM26";
/** Accéder à l'onglet Faq FAQ */
export const FAQ_ACCESS = "PERM27";
/** Accéder au menu Admin des inscriptions */
export const VALIDATION_COMPTES_ACCESS = "PERM28";
/** Accéder au suivi des inscriptions */
export const SDI_ACCESS = "ACCESS_SDI";
/** Modifier le détail du représentant */
export const REPRESENTANT_DETAIL_MODIFY = "PERM31";
/** Modifier le Principal responsable */
export const PRINCIPAL_RESPONSABLE_MODIFY = "PERM32";
/** Accéder à l'onglet Recettes */
export const RECETTE_ACCESS = "PERM33";
