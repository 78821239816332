import * as React from "react";

function SvgQuestionCircle(props: any) {
  return (
    <svg
      id="question-circle_svg__Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="question-circle_svg__clip-path">
          <path className="question-circle_svg__cls-1" d="M.43.62h99v99h-99z" />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-2">
          <path
            className="question-circle_svg__cls-1"
            d="M.43.62h103.3v103.3H.43z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-3">
          <path
            className="question-circle_svg__cls-3"
            d="M49.94.62a49.5 49.5 0 1049.49 49.5A49.54 49.54 0 0049.94.62zm0 5a44.55 44.55 0 11-44.56 44.5A44.5 44.5 0 0149.94 5.57z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-4">
          <path className="question-circle_svg__cls-1" d="M.43.62h99v99h-99z" />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-5">
          <path
            className="question-circle_svg__cls-2"
            d="M498.07-93.32l25.79-36.15 1.35 1.05-26.73 37.17-.79.29zm-56.25-45.41h58.77v24.21a3.14 3.14 0 002.7 3.13l-10.66 15a3.11 3.11 0 00-.56 1.35l-1.36 8.45a3.2 3.2 0 001.08 2.94 3.1 3.1 0 003 .54l6.56-2.46a3.18 3.18 0 00-.64 1.12c-.2.59-.3.89-.18 18.69l-11.04 11.39h-47.67zm-3.1-6.32a3.13 3.13 0 00-3.1 3.16v90.68a3.14 3.14 0 003.1 3.16h52.06A3.14 3.14 0 00493-49l12.91-13.3a3.23 3.23 0 00.9-2.24c-.06-8.08-.09-17.47 0-18.51a3.18 3.18 0 00-2.09-3.45 3.1 3.1 0 00-1.95 0 2.56 2.56 0 00.2-.26L532-127.2a3.18 3.18 0 00.6-1.83v-.05a3.19 3.19 0 00-1.24-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3 3 0 00-2.46-1 3.07 3.07 0 00-2.37 1.25l-14 19.58v-25.59a3.13 3.13 0 00-3.1-3.16z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-6">
          <path
            className="question-circle_svg__cls-2"
            d="M447.1-119.75c-1.68 0-3.05 1-3.05 2.11s1.37 2.11 3.05 2.11h44.52c1.68 0 3-1 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-7">
          <path
            className="question-circle_svg__cls-2"
            d="M447.1-107.1c-1.68 0-3.05.95-3.05 2.11s1.37 2.11 3.05 2.11h44.52c1.68 0 3-1 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-8">
          <path
            className="question-circle_svg__cls-2"
            d="M447.09-94.44c-1.67 0-3 .94-3 2.1s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.1-3-2.1z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-9">
          <path
            className="question-circle_svg__cls-2"
            d="M447.09-77.58c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-10">
          <path
            className="question-circle_svg__cls-2"
            d="M-108.4 26.77c-1.71 0-3.11 1-3.11 2.15s1.4 2.16 3.11 2.16H-63c1.71 0 3.11-1 3.11-2.16s-1.4-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-11">
          <path
            className="question-circle_svg__cls-3"
            d="M-108.4 39.69c-1.71 0-3.11 1-3.11 2.15s1.4 2.15 3.11 2.15H-63c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-12">
          <path
            className="question-circle_svg__cls-2"
            d="M-108.4 52.6c-1.71 0-3.11 1-3.11 2.15s1.4 2.15 3.11 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-13">
          <path
            className="question-circle_svg__cls-2"
            d="M-108.4 69.82c-1.71 0-3.11 1-3.11 2.15s1.4 2.15 3.11 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-14">
          <path
            className="question-circle_svg__cls-2"
            d="M630-107.31a2.2 2.2 0 00-2.15 2.26v42.82A2.2 2.2 0 00630-60a2.21 2.21 0 002.16-2.26V-105a2.21 2.21 0 00-2.16-2.26"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-15">
          <path
            className="question-circle_svg__cls-2"
            d="M604.2-107.31A2.21 2.21 0 00602-105v42.82a2.21 2.21 0 002.2 2.18 2.2 2.2 0 002.15-2.26V-105a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="question-circle_svg__clip-path-16">
          <path
            className="question-circle_svg__cls-3"
            d="M617.11-107.31A2.2 2.2 0 00615-105v42.82a2.2 2.2 0 002.11 2.18 2.2 2.2 0 002.15-2.26V-105a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <style>
          {
            ".question-circle_svg__cls-1,.question-circle_svg__cls-3{fill:none}.question-circle_svg__cls-10,.question-circle_svg__cls-2,.question-circle_svg__cls-9{fill:#333}.question-circle_svg__cls-2,.question-circle_svg__cls-3{clip-rule:evenodd}.question-circle_svg__cls-10{fill-rule:evenodd}"
          }
        </style>
      </defs>
      <g clipPath="url(#question-circle_svg__clip-path)">
        <g clipPath="url(#question-circle_svg__clip-path-2)">
          <g clipPath="url(#question-circle_svg__clip-path-3)">
            <g clipPath="url(#question-circle_svg__clip-path-4)">
              <path
                className="question-circle_svg__cls-9"
                d="M-2.9-2.71h105.66v105.66H-2.9z"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        className="question-circle_svg__cls-10"
        d="M498.07-93.32l25.79-36.15 1.35 1.05-26.73 37.17-.79.29zm-56.25-45.41h58.77v24.21a3.14 3.14 0 002.7 3.13l-10.66 15a3.11 3.11 0 00-.56 1.35l-1.36 8.45a3.2 3.2 0 001.08 2.94 3.1 3.1 0 003 .54l6.56-2.46a3.18 3.18 0 00-.64 1.12c-.2.59-.3.89-.18 18.69l-11.04 11.39h-47.67zm-3.1-6.32a3.13 3.13 0 00-3.1 3.16v90.68a3.14 3.14 0 003.1 3.16h52.06A3.14 3.14 0 00493-49l12.91-13.3a3.23 3.23 0 00.9-2.24c-.06-8.08-.09-17.47 0-18.51a3.18 3.18 0 00-2.09-3.45 3.1 3.1 0 00-1.95 0 2.56 2.56 0 00.2-.26L532-127.2a3.18 3.18 0 00.6-1.83v-.05a3.19 3.19 0 00-1.24-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3 3 0 00-2.46-1 3.07 3.07 0 00-2.37 1.25l-14 19.58v-25.59a3.13 3.13 0 00-3.1-3.16z"
      />
      <path
        className="question-circle_svg__cls-10"
        d="M447.1-119.75c-1.68 0-3.05 1-3.05 2.11s1.37 2.11 3.05 2.11h44.52c1.68 0 3-1 3-2.11s-1.36-2.11-3-2.11zM447.1-107.1c-1.68 0-3.05.95-3.05 2.11s1.37 2.11 3.05 2.11h44.52c1.68 0 3-1 3-2.11s-1.36-2.11-3-2.11zM447.09-94.44c-1.67 0-3 .94-3 2.1s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.1-3-2.1zM447.09-77.58c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.11-3-2.11zM-113.78 7.4h60s-.14 56.27 0 74.43L-65.14 93.5h-48.64zm-3.17-6.4a3.19 3.19 0 00-3.16 3.22v92.5a3.2 3.2 0 003.16 3.23h53.14a3.17 3.17 0 002.25-1l13.18-13.58a3.27 3.27 0 00.91-2.28c-.06-8.24 0-79 0-79A3.19 3.19 0 00-50.63 1z"
      />
      <path
        className="question-circle_svg__cls-10"
        d="M-108.4 26.77c-1.71 0-3.11 1-3.11 2.15s1.4 2.16 3.11 2.16H-63c1.71 0 3.11-1 3.11-2.16s-1.4-2.15-3.11-2.15z"
      />
      <g clipPath="url(#question-circle_svg__clip-path-10)">
        <path
          className="question-circle_svg__cls-9"
          d="M-2255.07-950.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="question-circle_svg__cls-10"
        d="M-108.4 52.6c-1.71 0-3.11 1-3.11 2.15s1.4 2.15 3.11 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#question-circle_svg__clip-path-12)">
        <path
          className="question-circle_svg__cls-9"
          d="M-2255.07-950.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="question-circle_svg__cls-10"
        d="M-108.4 69.82c-1.71 0-3.11 1-3.11 2.15s1.4 2.15 3.11 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#question-circle_svg__clip-path-13)">
        <path
          className="question-circle_svg__cls-9"
          d="M-2255.07-950.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="question-circle_svg__cls-10"
        d="M630-107.31a2.2 2.2 0 00-2.15 2.26v42.82A2.2 2.2 0 00630-60a2.21 2.21 0 002.16-2.26V-105a2.21 2.21 0 00-2.16-2.26"
      />
      <g clipPath="url(#question-circle_svg__clip-path-14)">
        <path
          className="question-circle_svg__cls-9"
          d="M-1778.26-1097.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="question-circle_svg__cls-10"
        d="M604.2-107.31A2.21 2.21 0 00602-105v42.82a2.21 2.21 0 002.2 2.18 2.2 2.2 0 002.15-2.26V-105a2.2 2.2 0 00-2.15-2.26"
      />
      <path
        className="question-circle_svg__cls-9"
        d="M36.18 28.88a34.9 34.9 0 016.93-3.39 22.93 22.93 0 017.48-1.13q7.47 0 11.29 3.62a12.74 12.74 0 013.82 9.7q0 4.37-3.7 8.76a28.41 28.41 0 01-9.78 7.28l-.93 12.86-5.38-2.18-.62-14.49a27.42 27.42 0 009.58-5.14q3.66-3.19 3.66-6.94a6.6 6.6 0 00-2.14-5.37 9.18 9.18 0 00-6-1.79 18 18 0 00-6.46 1.21 33.25 33.25 0 00-6.78 3.62zm7.4 45.41a4.66 4.66 0 014.67-4.68A4.63 4.63 0 0151.6 71a4.61 4.61 0 010 6.62A4.62 4.62 0 0148.25 79a4.64 4.64 0 01-4.67-4.67z"
      />
    </svg>
  );
}

export default SvgQuestionCircle;
