import React from "react";
import "./CTooltip.scss";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import styled from "@emotion/styled";

const Text = styled.div`
  font-size: 17px;
`;

const RenderTooltip = (props: any, id: any, value: any) => {
  return (
    <Tooltip id={id} {...props} className={`${(value === "" || !value) && "d-none"} custom-tooltip`}>
      <Text>{value}</Text>
    </Tooltip>
  );
};

interface IProps {
  id: string;
  value: any;
  children: any;
  placement?: any;
}

const CTooltip = ({ id, children, value, placement }: IProps) => {
  return (
    <OverlayTrigger
      placement={placement || "auto"}
      delay={{ show: 250, hide: 400 }}
      overlay={(props) => RenderTooltip(props, id, value)}
    >
      {children}
    </OverlayTrigger>
  );
};

export default CTooltip;
