import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    UPDATE_REPRESENTANT_FORET: (_: any, repForet: any) => {
      return { repForet: repForet };
    },
  };

  initStore(actions, {
    repForet: null,
  });
};

export default configureStore;
