import { ISIGBioEndPoint } from "../interface";
import BIO_NAT2000_ZSC from "../../assets/biodiversite/BIO_NAT2000_ZSC.svg";
import BIO_NAT2000_ZPS from "../../assets/biodiversite/BIO_NAT2000_ZPS.svg";
import BIO_SITE_CLASSE from "../../assets/biodiversite/BIO_SITE_CLASSE.svg";
import BIO_PNR from "../../assets/biodiversite/BIO_PNR.svg";
import BIO_ZNIEFF1 from "../../assets/biodiversite/BIO_ZNIEFF1.svg";
import BIO_ZNIEFF2 from "../../assets/biodiversite/BIO_ZNIEFF2.svg";
import BIO_APB from "../../assets/biodiversite/BIO_APB.svg";
import BIO_RESBIO_DIRIGEE from "../../assets/biodiversite/BIO_RESBIO_DIRIGEE.svg";
import BIO_RESBIO_INTEGRALE from "../../assets/biodiversite/BIO_RESBIO_INTEGRALE.svg";
import BIO_PARCNAT_COEUR from "../../assets/biodiversite/BIO_PARCNAT_COEUR.svg";
import BIO_PARCNAT_AIREDADHESION from "../../assets/biodiversite/BIO_PARCNAT_AIREDADHESION.svg";
import PRSF from "../../assets/biodiversite/PRSF.svg";
import { bioRenderer, prsfRenderer } from "../renderers";

export const SITE = {
  mail: "support.portaildescollectivites@onf.fr",
  contact: "(+33) 9 68 50 49 80",
  contact_href: "+33968504980",
  url: "https://portaildescollectivites.onf.fr",
  dns: "portaildescollectivites.onf.fr",
};

export const BIO_SIG_ENDPOINT: ISIGBioEndPoint[] = [
  {
    id: "BIO_NAT2000_ZSC",
    label: "NATURA 2000/ZSC",
    renderer: bioRenderer(BIO_NAT2000_ZSC, {
      width: 0.5,
      color: [110, 110, 110, 0.5],
    }),
    endPoint: 16,
    filter: "NOM like '%'",
  },
  {
    id: "BIO_NAT2000_ZPS",
    label: "NATURA 2000/ZPS",
    renderer: bioRenderer(BIO_NAT2000_ZPS),
    endPoint: 14,
    filter: "NOM like '%'",
  },
  {
    id: "BIO_SITE_CLASSE",
    label: "Site classé",
    renderer: bioRenderer(BIO_SITE_CLASSE, {
      width: 0.5,
      color: [255, 255, 255, 1],
    }),
    endPoint: 6,
    filter: "NOM_SC like '%'",
  },
  {
    id: "BIO_ZNIEFF1",
    label: "ZNIEFF1",
    renderer: bioRenderer(BIO_ZNIEFF1, {
      width: 0.5,
      color: [0, 0, 0, 0.25],
    }),
    endPoint: 23,
    filter: "NOM like '%'",
  },
  {
    id: "BIO_ZNIEFF2",
    label: "ZNIEFF2",
    renderer: bioRenderer(BIO_ZNIEFF2),
    endPoint: 24,
    filter: "NOM like '%'",
  },

  {
    id: "BIO_APB",
    label: "APB",
    renderer: bioRenderer(BIO_APB),
    endPoint: 1,
    filter: "NOM like '%'",
  },
  {
    id: "BIO_RESERVEBIO",
    label: "Réserves biologiques",
    children: [
      {
        id: "BIO_RESBIO_DIRIGEE",
        label: "Dirigée",
        renderer: bioRenderer(BIO_RESBIO_DIRIGEE, {
          width: 0.5,
          color: [137, 90, 68, 1],
        }),
        endPoint: 26,
        filter: "CCOD_TRB = 'D'",
      },
      {
        id: "BIO_RESBIO_INTEGRALE",
        label: "Intégrale",
        renderer: bioRenderer(BIO_RESBIO_INTEGRALE, {
          width: 0.5,
          color: [137, 90, 68, 1],
        }),
        endPoint: 26,
        filter: "CCOD_TRB = 'I'",
      },
    ].reverse(),
  },
  {
    id: "BIO_PARCNAT",
    label: "Parcs nationaux",
    children: [
      {
        id: "BIO_PARCNAT_AIREDADHESION",
        label: "Aire d'adhésion",
        renderer: bioRenderer(BIO_PARCNAT_AIREDADHESION),
        endPoint: 2,
        filter: "NOM like '%[aire d''adhésion]'",
      },
      {
        id: "BIO_PARCNAT_COEUR",
        label: "Coeur",
        renderer: bioRenderer(BIO_PARCNAT_COEUR),
        endPoint: 2,
        filter: "NOM like '%[coeur]'",
      },
    ].reverse(),
  },
  {
    id: "BIO_PNR",
    label: "PNR",
    renderer: bioRenderer(BIO_PNR),
    endPoint: 18,
    filter: "NOM like '%'",
  },
].reverse();

export const PRSF_SIG_ENDPOINT: ISIGBioEndPoint = {
  id: "PRSF",
  label: "PRSF",
  renderer: prsfRenderer(PRSF),
  endPoint: 0,
  filter: "LLIB_PRS like '%'",
};

export const MESSAGE_COCHE: string =
  "Je ne souhaite plus voir ce message lors de mes prochaines connexions";

export const DATATABLE_EMPTY_MSG = "Pas de résultat à afficher.";