import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import React from "react";
import { Toasts } from "@onf/socleweb-react";
import {
  InitPassword,
  Layout,
  Login,
  PrivateRoute,
  ForgotPwd,
  SignUp
} from "./Shared";
import jwtDecode from "jwt-decode";
//import ReCaptchaProvider from "./Shared/SignUp/ReCaptchaProvider";

export interface IAppState {
  isAuth: boolean;
  token: string | null;
  id: string | null;
  matomoConfig: any | null;
}

export class App extends React.Component<{}, IAppState> {
  public baseUrl = window.origin + process.env.PUBLIC_URL + "/api";

  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      isAuth: true,
      token: new URLSearchParams(window.location.search).get("token"),
      id: new URLSearchParams(window.location.search).get("id"),
      matomoConfig: null,
    };

  }

  render() {
    const token: any = localStorage.getItem("user");

    if (token) {
      const decodedToken: any = jwtDecode(token);
      if(decodedToken.exp * 1000 < Date.now()){
          localStorage.removeItem('user');
      }
    }
    
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <div className="App theme-territoire-portail">
            <Toasts />
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/signUp*" component={SignUp} />
              <Route path="/initPass*" component={InitPassword} />
              <Route path="/forgot-pass" component={ForgotPwd} />
              <PrivateRoute path="/">
                <Layout />
              </PrivateRoute>
            </Switch>
          </div>
        </BrowserRouter>
    );
  }
}

export default App;
