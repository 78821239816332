import React from "react";
import "./NotFound.scss";
import logoOnf from "../../assets/logo/logo-onf.svg";
import { SITE } from "../../Utils/Constants/Constants";

interface IProps {
  type: "menu" | "user";
}

export const NotFound = ({ type }: IProps) => {
  return (
    <div className="not-found">
      {type === "menu" ? (
        <NotFoundContent title="rôle désactivé">
          <p>Votre rôle est désactivé.</p>
          
        </NotFoundContent>
      ) : (
        <NotFoundContent title="compte désactivé">
          <p>Votre compte est désactivé.</p>
        </NotFoundContent>
      )}
    </div>
  );
};

export default NotFound;

const NotFoundContent = ({ children, title }: any) => {
  return (
    <div className="not-found-content">
      <img src={logoOnf} alt="logo ONF" />
      <hr/>
      <div className="not-found-title">{title}</div>
      <div className="not-found-body">
        {children}
        <p>
          Merci de nous contacter sur l'adresse mail suivante: <br />
          <a href={`mailto:${SITE.mail}`}>{SITE.mail}</a>
        </p>
      </div>
    </div>
  );
};
