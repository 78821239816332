import React from 'react'
import "./Spinner.scss"

const Spinner = () => {
    return (
      <div className="Spinner">
        <div className="loader">Loading...</div>
      </div>
    );
}

export default Spinner;