import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    UPDATE_COUPES_AMG_UG_SELECTED: (_: any, coupesAmgUGIds: any[]) => {
      return { coupesAmgUGIds: coupesAmgUGIds };
    },
    UPDATE_COUPES_INS_UG_SELECTED: (_: any, coupesInsUGIds: any[]) => {
      return { coupesInsUGIds: coupesInsUGIds };
    },
    UPDATE_COUPES_DES_UG_SELECTED: (_: any, coupesDesUGIds: any[]) => {
      return { coupesDesUGIds: coupesDesUGIds };
    },
    UPDATE_CLEAR_AMG_UG: (_: any, clear: boolean) => {
      return { clearAmgUG: clear };
    },
    UPDATE_CLEAR_INS_UG: (_: any, clear: boolean) => {
      return { clearInsUG: clear };
    },
    UPDATE_CLEAR_DES_UG: (_: any, clear: boolean) => {
      return { clearDesUG: clear };
    },
  };

  initStore(actions, {
    coupesAmgUGIds: [],
    coupesInsUGIds: [],
    coupesDesUGIds: [],
    clearAmgUG: false,
    clearInsUG: false,
    clearDesUG: false,
  });
};

export default configureStore;
