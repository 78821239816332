import React from 'react'
import {Button} from 'react-bootstrap';

interface IProps {
  onSubmit: (e: any) => void;
  onReset: () => void;
  onFilter?: () => void;
}
const CActionsButtons = ({onSubmit, onReset}: IProps) => {
    return (
      <div className="table-space text-center">
        <div className="table-space-item">
          <Button type="submit" variant="primary" size="sm" onClick={onSubmit}>
            Filtrer
          </Button>
        </div>
        <div className="table-space-item">
          <Button variant="light" size="sm" onClick={onReset}>
            Réinitialiser
          </Button>
        </div>
        {/* <div className="table-space-item">
          <Button variant="link" size="sm" onClick={onFilter}>
            Filtrer
          </Button>
        </div> */}
      </div>
    );
}

export default CActionsButtons;
