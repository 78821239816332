import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import filterFactory, { Comparator, textFilter } from "react-bootstrap-table2-filter";
import LoadingOverlay from "react-loading-overlay-ts";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./CDataTable.scss";
import { normalizeText } from "../../../Utils/Helpers/TextHelper";
import { Image } from "react-bootstrap";
import classNames from "classnames";
import CDefaultFilter from "./CDefaultFilter";
import CHeaderFormatter from "./CHeaderFormatter";
import { IDataTableProps } from "./CInterface";
// Icônes pagination
import IconDoubleRightEnable from "../../../assets/pagination/chevron-double-droite-actif.svg";
import IconDoubleRightDisable from "../../../assets/pagination/chevron-double-droite-inactif.svg";
import IconDoubleLeftEnable from "../../../assets/pagination/chevron-double-gauche-actif.svg";
import IconDoubleLeftDisable from "../../../assets/pagination/chevron-double-gauche-inactif.svg";

import IconSimpleRightEnable from "../../../assets/pagination/chevron-simple-droite-actif.svg";
import IconSimpleRightDisable from "../../../assets/pagination/chevron-simple-droite-inactif.svg";
import IconSimpleLeftEnable from "../../../assets/pagination/chevron-simple-gauche-actif.svg";
import IconSimpleLeftDisable from "../../../assets/pagination/chevron-simple-gauche-inactif.svg";
import CSelectFilter from "./CSelectFilter";

import SvgDefaultSort from "../../../assets/svg/general-sort.svg";
import SvgAscSort from "../../../assets/svg/asc-sort.svg";
import SvgDescSort from "../../../assets/svg/desc-sort.svg";
import SvgAscSortNumber from "../../../assets/svg/asc-sort-number.svg";
import SvgDescSortNumber from "../../../assets/svg/desc-sort-number.svg";

const customTotal = (from: any, to: any, size: any) => (
  <span className="react-bootstrap-table-pagination-total">
    <span className="font-weight-600">{from}</span> -{" "}
    <span className="font-weight-600">{to}</span> /{" "}
    <span className="font-weight-600">{size}</span>
  </span>
);

const pageListRenderer = ({ pages, onPageChange }: any) => {
  // just exclude <, <<, >>, c
  const pageWithoutIndication = pages.filter(
    (p: any) => typeof p.page === "string"
  );

  return (
    <div className="btn-block-pagination">
      {pageWithoutIndication.map((p: any, index: number) => {
        let icon: any = null;
        switch (p.page) {
          case "<":
            icon = !p?.disabled ? IconSimpleLeftEnable : IconSimpleLeftDisable;
            break;

          case "<<":
            icon = !p?.disabled ? IconDoubleLeftEnable : IconDoubleLeftDisable;
            break;

          case ">>":
            icon = !p?.disabled
              ? IconDoubleRightEnable
              : IconDoubleRightDisable;
            break;

          case ">":
            icon = !p?.disabled
              ? IconSimpleRightEnable
              : IconSimpleRightDisable;
            break;

          default:
            break;
        }
        return (
          <div
            className={`btn-pagination ${p?.disabled ? "disable" : ""}`}
            key={index}
            onClick={() => onPageChange(p.page)}
          >
            <Image src={icon} />
          </div>
        );
      })}
    </div>
  );
};

const sortCaret = (order: any, column: any) => {
  if (!order)
    return (
      <span className="table-column-sorter-inner">
        <span className="sorticon">
          <Image src={SvgDefaultSort} height={17} width={17} />
        </span>
      </span>
    );
  else if (order === "asc")
    return (
      <span className="table-column-sorter-inner">
        <span className="sorticon">
          {column?.number ? (
            <Image src={SvgAscSortNumber} height={17} width={17} />
          ) : (
            <Image src={SvgAscSort} height={17} width={17} />
          )}
        </span>
      </span>
    );
  else if (order === "desc")
    return (
      <span className="table-column-sorter-inner">
        <span className="sorticon">
          {column?.number ? (
            <Image src={SvgDescSortNumber} height={17} width={17} />
          ) : (
            <Image src={SvgDescSort} height={17} width={17} />
          )}
        </span>
      </span>
    );
  return null;
};

const CDataTable = forwardRef(
  (
    {
      keyField,
      columns,
      data,
      remote,
      loading,
      selectRowMode,
      currentPage,
      sizePerPage,
      totalSize,
      selectableRows,
      selectableRowSelected,
      onRowClick,
      onRowDoubleClick,
      onSelectedRowsChange,
      onSelectAllRows,
      onTableChange,
      noDataIndication,
      defaultSorted,
      className,
      onFilterApplied
    }: IDataTableProps,
    ref?: any
  ) => {
    const filterRef: any = useRef<any>([]);
    const [customColumns, setCustomColumns] = useState<ColumnDescription[]>([]);
    const [filtredData, setFiltredData] = useState<any[]>([]);
    const [filtersValues, setFiltersValues] = useState<any>({});
    const [show, setShow] = useState<any>({});
    const [sort, setSort] = useState<{ dataField: any, order: "asc" | "desc" } | undefined>(defaultSorted ? defaultSorted[0] : undefined);
    const tableRef = useRef<any>(null);
    const [filterHash, setFilterHash] = useState<string>("");

    const refreshFilterHash = (onFilterAppliedCB?: () => any) => {

      if (onFilterAppliedCB === undefined) {
        onFilterAppliedCB = onFilterApplied;
      }
      // hash the filtersValues object
      let hash = 0;
      if (filtersValues) {
        const str = JSON.stringify(filtersValues);
        for (let i = 0; i < str.length; i++) {
          const char = str.charCodeAt(i);
          hash = (hash << 5) - hash + char;
          hash = hash & hash; // Convert to 32bit integer
        }
      }

      if (filterHash !== hash.toString() && onFilterAppliedCB) {
        onFilterAppliedCB();
        setFilterHash(hash.toString());
      }

    }

    // any time the filtersValues change, we update the filterHash and call the filterApplied Callback
    useEffect(() => {
      refreshFilterHash();
    }, [filtersValues, data]);

    useEffect(() => {
      setFiltredData(data);
    }, [data]);

    const onToggle = (col: string, isOpen: boolean) => {
      setShow((prevState: any) => ({
        ...prevState,
        [col]: isOpen,
      }));
    };

    const filterByDefault = (
      filterVal: any,
      column: ColumnDescription | any
    ) => {
      if (remote && column?.onFilter) {
        return column?.onFilter(filterVal);
      }

      if (
        filterVal &&
        ((typeof filterVal === "object" && filterVal.length > 0) ||
          (typeof filterVal === "string" && filterVal !== ""))
      ) {
        const newFiltersValues: any = { ...filtersValues };
        newFiltersValues[column?.dataField] = filterVal;

        const filters: any[] = Object.entries(newFiltersValues);
        let newFiltredData: any[] = [...data];

        filters.forEach(([key, value]) => {
          if (typeof value === "object") {
            newFiltredData = newFiltredData.filter((d: any) =>
              value?.includes(d[key])
            );
          } else {
            newFiltredData = newFiltredData.filter((d: any) =>
              normalizeText(d[key])
                .toLowerCase()
                .includes(normalizeText(value).toLowerCase())
            );
          }
        });
        return newFiltredData;
      }

      return data;
    };

    useEffect(() => {
      if (columns && columns.length > 0) {
        setCustomColumns(
          columns?.map((col: any) => ({
            ...col,
            dataField: col.dataField || "",
            text: col.text,
            headerFormatter: (column: any, _: number, components: any) => (
              <CHeaderFormatter
                column={column}
                components={components}
                filter={col?.filter}
                show={show}
                onToggle={onToggle}
              />
            ),
            dataList: col?.dataList,
            defaultFilter: col?.defaultFilter,
            filter: textFilter({
              comparator: col.comparator || Comparator.LIKE, // default is Comparator.LIKE
              onFilter: (value: any) => filterByDefault(value, col),
              getFilter: (filter: any) => {
                col.resetFilter = filter;
              },
            }),

            filterRenderer: (onFilter: any, column: any) => {
              const onFilterHandler = (filter: any) => {
                col.isFilter = !filter || filter?.length === 0 ? false : true;
                onFilter(filter);
              };
              return !col?.dataList ? (
                <CDefaultFilter
                  ref={(instance: any) => {
                    filterRef.current[col.dataField] = instance;
                  }}
                  show={show}
                  onFilter={onFilterHandler}
                  column={column}
                  onToggle={onToggle}
                />
              ) : (
                <CSelectFilter
                  ref={(instance: any) => {
                    filterRef.current[col.dataField] = instance;
                  }}
                  show={show}
                  onFilter={onFilterHandler}
                  column={column}
                  onToggle={onToggle}
                  defaultFilter={col?.defaultFilter || []}
                  comparator= {col.comparator} // default is Comparator.LIKE
                />
              );
            },
            sort: col?.sort,
            sortCaret: (order: any) => sortCaret(order, col),
            isDummyField: col?.isDummyField,
            formatter: col?.formatter,
            resetFilter: col?.resetFilter,
            onSort: col?.onSort,
            sortFunc: col?.sortFunc,
          }))
        );
      }
    }, [data, columns, show]);

    useImperativeHandle(ref, () => ({
      clearAllFilters: () => {
        clearAllFilters();
      },
      getExportableData: () => {
        return (tableRef.current as any).paginationContext.props.data;    
      },
    }));

    const clearAllFilters = () => {
        setSort(defaultSorted ? defaultSorted[0] : undefined);
          columns.forEach((col: any) => {
            if (col?.resetFilter) col?.resetFilter();
            if (filterRef?.current[col.dataField])
              filterRef?.current[col.dataField]?.onReset();
          });
          if(onTableChange && defaultSorted) {
            onTableChange("sort", {sortField: defaultSorted[0].dataField, sortOrder: defaultSorted[0].order});
          }
    };

    const options = {
      page: currentPage,
      sizePerPage: sizePerPage || 5,
      showTotal: !loading && filtredData.length > 0 ? true : false,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      alwaysShowAllBtns: remote ? true : sizePerPage !== filtredData.length,
      disablePageTitle: true,
      paginationTotalRenderer: customTotal,
      pageListRenderer: pageListRenderer,
      totalSize: totalSize,
    };

    const selectRowProps: any = {
      mode: selectRowMode || "checkbox",
      bgColor: "#E8F4E8",
      //clickToSelect: true,
      selected: selectableRowSelected,
      onSelect: onSelectedRowsChange,
      onSelectAll: onSelectAllRows,
    };

    // Handle row click and double click
    const getRowEvents = () => {
      let rowEvents: any = {};

      if (onRowClick) {
        rowEvents = {
          ...rowEvents,
          onClick: onRowClick,
        };
      }

      if (onRowDoubleClick) {
        rowEvents = {
          ...rowEvents,
          onDoubleClick: onRowDoubleClick,
        };
      }

      return Object.keys(rowEvents).length > 0 ? rowEvents : undefined;
    };

    const afterFilter = (_: any, filters: any) => {
      const newFiltersValues: any = { ...filtersValues };
      const newFilters: any = {};
      const filterTmp: any[] = Object.entries(filters);

      filterTmp.forEach(([key, value]) => {
        newFilters[key] = value.filterVal;
      });

      if (JSON.stringify(newFilters) !== JSON.stringify(newFiltersValues)) {
        setFiltersValues(newFilters);
      }
    };

    const customNoDataIndication = () => {
      return (
        <div className="table-empty">
          <div className="table-empty-image">
            <FontAwesomeIcon icon={faBoxOpen} size="10x" />
          </div>
          <div className="table-empty-description">
            {noDataIndication || "Aucune donnée trouvée"}
          </div>
        </div>
      );
    };

      return (
      <div className={classNames("CDataTable", className)}>
        {customColumns.length > 0 && (
          <LoadingOverlay active={loading} spinner text="Chargement..." className={loading ? "loading-overlay" : ""}>
            <BootstrapTable
            ref={tableRef}
            sort={sort && (typeof remote !== "boolean" && remote?.sort) ? {dataField: sort.dataField, order: sort.order} : undefined}
              wrapperClasses="table-responsive"
              remote={remote}
              keyField={keyField || "id"}
              columns={customColumns}
              data={filtredData}
              hover
              bordered={false}
              striped
              defaultSorted={defaultSorted}
              filter={filterFactory({ afterFilter })}

              pagination={paginationFactory(options)}
              selectRow={selectableRows && selectRowProps}
              rowEvents={getRowEvents()}
              noDataIndication={
                (!loading && customNoDataIndication) || (
                  <div className="p-5"></div>
                )
              }
              onTableChange={(t, newState) => {
                  console.log(newState)
                  // if sort actualize sort state
                  if(t === "sort") {
                      setSort({dataField: newState.sortField, order: newState.sortOrder})
                  }

                  if (onTableChange) {
                      onTableChange(t, newState)
                  }

              }}
            />
          </LoadingOverlay>
        )}
      </div>
    );
  }
);

export default React.memo(CDataTable);
