import React from 'react'
import { URL_ONF } from '../../Constants';
import "./CHeaderForm.scss";
import logoOnfHeader from "../../../assets/logo/logo-onf-header.svg";
import logoFR from "../../../assets/logo/logo-france.svg";

const CHeaderForm = () => {
  return (
    <div className="CHeaderForm">
      <div className="header-logo">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            left: "5px",
          }}
        >
          <a href="#">
            <img src={logoFR} alt="logo RF" />
          </a>
          <a href={URL_ONF} target="_blank">
            <img src={logoOnfHeader} alt="logo ONF" />
          </a>
        </div>
        <div className="logo-title">Portail des collectivités</div>
      </div>
    </div>
  );
}

export default CHeaderForm;