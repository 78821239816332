import React from 'react';
import Select from 'react-select';
import { Option } from 'react-select/src/filters';
import { InputSelectStyles } from '../../Constants';

const SelectField: React.FC<any> = ({
  options,
  field,
  form,
  menuPlacement,
  ...props
}) => {
  return (
    <Select
      styles={InputSelectStyles}
      options={options}
      name={field.name}
      placeholder={props.placeholder || ""}
      value={
        options && field.value !== ""
          ? options.find((option: Option) => option.value === field.value)
          : { value: "", label: "" }
      }
      isInvalid={form.touched[field.name] && !!form.errors[field.name]}
      onChange={(option: Option) =>
        form.setFieldValue(field.name, option.value)
      }
      onBlur={field.onBlur}
      {...props}
      noOptionsMessage={() => "Aucune donnée trouvée"}
      menuPortalTarget={document.body}
      menuPlacement={menuPlacement || "auto"}
      menuPosition="fixed"
      loadingMessage={() => "Chargement..."}
    />
  );
};

  export default SelectField;
