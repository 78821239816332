export const bioRenderer = (image: any, outline?: any): any => {
  const renderer: any = {
    type: "simple",
    symbol: {
      type: "picture-fill",
      url: image,
      contentType: "image/svg+xml",
      width: 42,
      height: 42,
      angle: 0,
      xoffset: 0,
      yoffset: 0,
    },
  };

  if (outline) {
    renderer.symbol.outline = outline;
  }
  return renderer;
};

export const prsfRenderer = (image: any): any => {
  const renderer: any = {
    type: "unique-value",
    field: "CVAL_PRS",
    defaultSymbol: {
      type: "picture-marker",
      url: image,
      contentType: "image/svg+xml",
      width: 10,
      height: 10,
      angle: 0,
      xoffset: 0,
      yoffset: 0,
    },
  };
  return renderer;
};
