import React, { Suspense, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "../../routes";
import { StorageManagerService } from "../../Services";
import { IRoute } from "../../Utils/interface";

const Content = () => {
  useEffect(() => {
    const proSideBar = document.querySelector(".pro-sidebar");
    proSideBar?.addEventListener("click", handleResize, true);
  });

  const handleResize = (event: any) => {
    const btnToggle: any = event?.path?.find(
      (p: any) => p?.className === "btn-toggle"
    );

    if (
      btnToggle ||
      event?.target?.className === "btn-toggle" ||
      event?.target?.parentNode?.className === "btn-toggle" ||
      event?.target?.parentNode?.parentNode?.className === "btn-toggle"
    ) {
      const footer: any = document.querySelector(".footer");
      const sideBarCollapsed: any = document.querySelector(".pro-sidebar");

      if (sideBarCollapsed?.clientWidth !== 70) {
        footer?.classList?.add("collapsed");
      } else {
        footer?.classList?.remove("collapsed");
      }
    }
  };


  const checkAccessRoute = (route: IRoute, component: any): any => {
    let routeTmp: any = null;

    if (route?.isAdmin && StorageManagerService.isAdmin()) {
      routeTmp = component;
    }

    if (
      route?.permissions && (route?.permissions.length == 0 ||
      StorageManagerService.hasPermission(route?.permissions))
    ) {
      routeTmp = component;
    }

    if (route?.isTft && StorageManagerService.isTft()) {
      routeTmp = component;
    } 

    if (route?.isInternalUser && StorageManagerService.isInternalUser()) {
      routeTmp = component;
    }

    if (
      !route?.isAdmin &&
      !route?.permissions &&
      !route?.isInternalUser &&
      !route?.isTft
    )
      routeTmp = component;
            
      return routeTmp;
  }

  return (
    <main>
      <Container fluid id="container-fluid">
        <Suspense fallback={null}>
          <Switch>
            {routes.map((route: IRoute, index: number) => {
              const component: any = (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => <route.component {...props} />}
                />
              );
              
              return checkAccessRoute(route, component);
            })}
            <Redirect from="/" to="/accueil" />
          </Switch>
        </Suspense>
      </Container>
    </main>
  );
};

export default React.memo(Content);
