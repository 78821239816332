import React from 'react'
import {Link} from "react-router-dom";
import {URL_ONF} from '../Constants';
import "./Footer.scss";

const Footer = () => {
    return (
      <div className="footer">
        <Link to="/mentions-legales">Mentions légales</Link>
        <Link to="/politique-confidentialite">Politique de confidentialité</Link>
        <Link to="/conditions-generales-utilisations">
          Conditions générales d'utilisation
        </Link>
        <Link to="/contact">Contact</Link>
        <a href={URL_ONF} target="_blank">
          ONF.fr
        </a>
      </div>
    );
};

export default Footer;
