import React from "react";
import "./Feedback.scss";
import logoOnf from "../../assets/logo/logo-onf.svg";

interface IProps {
  title?: string;
  children: any;
}

export const Feedback = ({ title, children }: IProps) => {
  return (
    <div className="Feedback">
      <div className="feedback-content">
        <img src={logoOnf} alt="logo ONF" />
        <hr/>
        {title && (
            <div className="feedback-title">{title}</div>
        )}
        <div className="feedback-body">{children}</div>
      </div>
    </div>
  );
};

export default Feedback;
