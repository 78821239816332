import * as React from "react";

function SvgFile(props: any) {
  return (
    <svg
      id="file_svg__Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="file_svg__clip-path">
          <path
            className="file_svg__cls-1"
            d="M632.44-93.82L658.23-130l1.35 1.05-26.73 37.2-.79.29zm-56.25-45.41H635V-115a3.14 3.14 0 002.7 3.13L627-96.93a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.2 3.2 0 001.08 2.94 3.1 3.1 0 003.05.54l6.56-2.46a3.06 3.06 0 00-.65 1.12c-.19.59-.29.89-.17 18.69l-11.1 11.43h-47.67zm-3.1-6.32a3.13 3.13 0 00-3.1 3.16v90.68a3.14 3.14 0 003.1 3.16h52.06a3.14 3.14 0 002.21-.94l12.91-13.3a3.23 3.23 0 00.9-2.24c-.06-8.08-.09-17.47 0-18.51A3.18 3.18 0 00639-87a3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26l29.09-40.45a3.18 3.18 0 00.6-1.83v-.05a3.19 3.19 0 00-1.24-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3 3 0 00-2.46-1 3.07 3.07 0 00-2.37 1.25l-14 19.58v-25.59a3.14 3.14 0 00-3.11-3.16z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-2">
          <path
            className="file_svg__cls-1"
            d="M581.46-120.25c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11H626c1.68 0 3-1 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-3">
          <path
            className="file_svg__cls-1"
            d="M581.46-107.6c-1.67 0-3 .95-3 2.11s1.37 2.11 3 2.11H626c1.68 0 3-1 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-4">
          <path
            className="file_svg__cls-1"
            d="M581.46-94.94c-1.67 0-3 .94-3 2.1s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.1-3-2.1z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-5">
          <path
            className="file_svg__cls-1"
            d="M581.46-78.08c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-6">
          <path
            className="file_svg__cls-1"
            d="M26 26.27c-1.71 0-3.11 1-3.11 2.15s1.4 2.16 3.11 2.16h45.41c1.71 0 3.11-1 3.11-2.16s-1.4-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-7">
          <path
            className="file_svg__cls-2"
            d="M-2120.7-950.81h6973.04v4317.26H-2120.7z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-8">
          <path className="file_svg__cls-2" d="M22.86 26.27h51.65v4.3H22.86z" />
        </clipPath>
        <clipPath id="file_svg__clip-path-11">
          <path
            className="file_svg__cls-4"
            d="M26 39.19c-1.71 0-3.11 1-3.11 2.15s1.4 2.15 3.11 2.15h45.41c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-12">
          <path
            className="file_svg__cls-5"
            d="M-2120.7-950.81h6973.04v4317.26H-2120.7z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-13">
          <path className="file_svg__cls-5" d="M22.86 39.19h51.65v4.3H22.86z" />
        </clipPath>
        <clipPath id="file_svg__clip-path-16">
          <path
            className="file_svg__cls-1"
            d="M26 52.1c-1.71 0-3.1 1-3.1 2.15s1.35 2.15 3.1 2.15h36.8c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-18">
          <path className="file_svg__cls-2" d="M22.86 52.1H65.9v4.3H22.86z" />
        </clipPath>
        <clipPath id="file_svg__clip-path-21">
          <path
            className="file_svg__cls-1"
            d="M26 69.32c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.8c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-23">
          <path className="file_svg__cls-2" d="M22.86 69.32H65.9v4.3H22.86z" />
        </clipPath>
        <clipPath id="file_svg__clip-path-26">
          <path
            className="file_svg__cls-1"
            d="M764.39-107.81a2.2 2.2 0 00-2.15 2.26v42.82a2.2 2.2 0 002.15 2.26 2.2 2.2 0 002.15-2.26v-42.82a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-27">
          <path
            className="file_svg__cls-1"
            d="M738.57-107.81a2.21 2.21 0 00-2.16 2.26v42.82a2.21 2.21 0 002.16 2.26 2.21 2.21 0 002.15-2.26v-42.82a2.21 2.21 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="file_svg__clip-path-28">
          <path
            className="file_svg__cls-4"
            d="M751.48-107.81a2.2 2.2 0 00-2.15 2.26v42.82a2.2 2.2 0 002.15 2.26 2.2 2.2 0 002.15-2.26v-42.82a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <style>
          {
            ".file_svg__cls-1,.file_svg__cls-2,.file_svg__cls-3{fill:#333}.file_svg__cls-1,.file_svg__cls-4{clip-rule:evenodd}.file_svg__cls-3{fill-rule:evenodd}.file_svg__cls-4,.file_svg__cls-5{fill:none}.file_svg__cls-12{clip-path:url(#file_svg__clip-path-6)}.file_svg__cls-13{clip-path:url(#file_svg__clip-path-7)}.file_svg__cls-14{isolation:isolate}.file_svg__cls-15{clip-path:url(#file_svg__clip-path-8)}.file_svg__cls-16{clip-path:url(#file_svg__clip-path-11)}.file_svg__cls-18{clip-path:url(#file_svg__clip-path-13)}.file_svg__cls-19{clip-path:url(#file_svg__clip-path-16)}.file_svg__cls-20{clip-path:url(#file_svg__clip-path-18)}.file_svg__cls-21{clip-path:url(#file_svg__clip-path-21)}.file_svg__cls-22{clip-path:url(#file_svg__clip-path-23)}"
          }
        </style>
      </defs>
      <path
        className="file_svg__cls-3"
        d="M632.44-93.82L658.23-130l1.35 1.05-26.73 37.2-.79.29zm-56.25-45.41H635V-115a3.14 3.14 0 002.7 3.13L627-96.93a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.2 3.2 0 001.08 2.94 3.1 3.1 0 003.05.54l6.56-2.46a3.06 3.06 0 00-.65 1.12c-.19.59-.29.89-.17 18.69l-11.1 11.43h-47.67zm-3.1-6.32a3.13 3.13 0 00-3.1 3.16v90.68a3.14 3.14 0 003.1 3.16h52.06a3.14 3.14 0 002.21-.94l12.91-13.3a3.23 3.23 0 00.9-2.24c-.06-8.08-.09-17.47 0-18.51A3.18 3.18 0 00639-87a3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26l29.09-40.45a3.18 3.18 0 00.6-1.83v-.05a3.19 3.19 0 00-1.24-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3 3 0 00-2.46-1 3.07 3.07 0 00-2.37 1.25l-14 19.58v-25.59a3.14 3.14 0 00-3.11-3.16z"
      />
      <path
        className="file_svg__cls-3"
        d="M581.46-120.25c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11H626c1.68 0 3-1 3-2.11s-1.36-2.11-3-2.11zM581.46-107.6c-1.67 0-3 .95-3 2.11s1.37 2.11 3 2.11H626c1.68 0 3-1 3-2.11s-1.36-2.11-3-2.11zM581.46-94.94c-1.67 0-3 .94-3 2.1s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.1-3-2.1zM581.46-78.08c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.11-3-2.11zM20.58 6.9h60s-.14 56.27 0 74.43L69.23 93H20.58zM17.42.45a3.19 3.19 0 00-3.17 3.22v92.55a3.2 3.2 0 003.17 3.23h53.14a3.17 3.17 0 002.25-1L86 84.91a3.32 3.32 0 00.91-2.28c-.06-8.24 0-79 0-79A3.19 3.19 0 0083.73.45z"
      />
      <path
        className="file_svg__cls-3"
        d="M26 26.27c-1.71 0-3.11 1-3.11 2.15s1.4 2.16 3.11 2.16h45.41c1.71 0 3.11-1 3.11-2.16s-1.4-2.15-3.11-2.15z"
      />
      <g className="file_svg__cls-12">
        <path
          className="file_svg__cls-2"
          d="M-2120.7-950.81h6973.04v4317.26H-2120.7z"
        />
        <g className="file_svg__cls-13">
          <g className="file_svg__cls-14">
            <path
              className="file_svg__cls-2"
              d="M22.86 26.27h51.65v4.3H22.86z"
            />
            <g className="file_svg__cls-15">
              <path
                className="file_svg__cls-3"
                d="M26 26.27c-1.71 0-3.11 1-3.11 2.15s1.4 2.16 3.11 2.16h45.41c1.71 0 3.11-1 3.11-2.16s-1.4-2.15-3.11-2.15z"
              />
              <g className="file_svg__cls-12">
                <path
                  className="file_svg__cls-2"
                  d="M22.86 26.27h51.65v4.3H22.86z"
                />
                <g className="file_svg__cls-15">
                  <path
                    className="file_svg__cls-2"
                    d="M18.56 21.97h60.26v12.91H18.56z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g className="file_svg__cls-16">
        <g className="file_svg__cls-14" clipPath="url(#file_svg__clip-path-12)">
          <g className="file_svg__cls-18">
            <g className="file_svg__cls-16">
              <g className="file_svg__cls-18">
                <path
                  className="file_svg__cls-2"
                  d="M18.56 34.88h60.26v12.91H18.56z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        className="file_svg__cls-3"
        d="M26 52.1c-1.71 0-3.1 1-3.1 2.15s1.35 2.15 3.1 2.15h36.8c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
      />
      <g className="file_svg__cls-19">
        <path
          className="file_svg__cls-2"
          d="M-2120.7-950.81h6973.04v4317.26H-2120.7z"
        />
        <g className="file_svg__cls-13">
          <g className="file_svg__cls-14">
            <path className="file_svg__cls-2" d="M22.86 52.1H65.9v4.3H22.86z" />
            <g className="file_svg__cls-20">
              <path
                className="file_svg__cls-3"
                d="M26 52.1c-1.71 0-3.1 1-3.1 2.15s1.35 2.15 3.1 2.15h36.8c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
              />
              <g className="file_svg__cls-19">
                <path
                  className="file_svg__cls-2"
                  d="M22.86 52.1H65.9v4.3H22.86z"
                />
                <g className="file_svg__cls-20">
                  <path
                    className="file_svg__cls-2"
                    d="M18.56 47.79h51.65V60.7H18.56z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        className="file_svg__cls-3"
        d="M26 69.32c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.8c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
      />
      <g className="file_svg__cls-21">
        <path
          className="file_svg__cls-2"
          d="M-2120.7-950.81h6973.04v4317.26H-2120.7z"
        />
        <g className="file_svg__cls-13">
          <g className="file_svg__cls-14">
            <path
              className="file_svg__cls-2"
              d="M22.86 69.32H65.9v4.3H22.86z"
            />
            <g className="file_svg__cls-22">
              <path
                className="file_svg__cls-3"
                d="M26 69.32c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.8c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
              />
              <g className="file_svg__cls-21">
                <path
                  className="file_svg__cls-2"
                  d="M22.86 69.32H65.9v4.3H22.86z"
                />
                <g className="file_svg__cls-22">
                  <path
                    className="file_svg__cls-2"
                    d="M18.56 65.01h51.65v12.91H18.56z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        className="file_svg__cls-3"
        d="M764.39-107.81a2.2 2.2 0 00-2.15 2.26v42.82a2.2 2.2 0 002.15 2.26 2.2 2.2 0 002.15-2.26v-42.82a2.2 2.2 0 00-2.15-2.26"
      />
      <g clipPath="url(#file_svg__clip-path-26)">
        <path
          className="file_svg__cls-2"
          d="M-1643.89-1097.81h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="file_svg__cls-3"
        d="M738.57-107.81a2.21 2.21 0 00-2.16 2.26v42.82a2.21 2.21 0 002.16 2.26 2.21 2.21 0 002.15-2.26v-42.82a2.21 2.21 0 00-2.15-2.26"
      />
    </svg>
  );
}

export default SvgFile;
