import React from "react";
import EventHelper from "../../../Utils/Helpers/EventHelper";
import SvgSearch from "../../../assets/svg/filter.svg";
import SvgSearchBlank from "../../../assets/svg/filter-blank.svg";
import {Image} from "react-bootstrap";

const CToggle = React.forwardRef(
  ({ isFilter, children, onClick }: any, ref: any) => {
    return (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          EventHelper.stopPropagation(e);
          onClick(e);
        }}
        onKeyUp={EventHelper.stopPropagation}
      >
        {children}
        <Image src={isFilter ? SvgSearchBlank : SvgSearch} height={17} width={17} />
      </a>
    );
  }
);

export default CToggle;
