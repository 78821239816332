import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import logoFr from "../../assets/logo/logo-france.svg";
import logoOnfHeader from "../../assets/logo/logo-onf-header.svg";
import { URL_ONF } from "../Constants";
import { Logout } from "../Logout";
import { Avatar } from "./Avatar";
import "./Header.scss";
import { useStore } from "../../hooks-store/store";

interface Props {
  currentUser: any;
  isNotActive: boolean;
}
const Header: React.FC<Props> = (props) => {
  const store = useStore()[0];
  const user = props?.currentUser;
  const history = useHistory();

  const profil = () => {
    history.push("/profil");
  };

  return (
    <header className="header">
      <div className="title h-100">
        <div className="onf-logo-header h-100">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              position: "relative",
              left: "5px",
              }}
          >
            <a href="#">
              <img src={logoFr} alt="logo RF" />
            </a>
            <a href={URL_ONF} target="_blank">
              <img src={logoOnfHeader} alt="logo ONF" />
            </a>
          </div>

          <div className="logo-title">Portail des collectivités</div>
        </div>
      </div>
      <div className="actions">
        <>
          <Dropdown id="dropdown-avatar" navbar={true} alignRight={true}>
            <Dropdown.Toggle id="my-dropdown-toggle">
              <div
                className="pull-left"
                style={{ color: "black", fontWeight: "bold" }}
              >
                {user.avatar || store.imgPath ? (
                  <>
                    <Avatar
                      width="40px"
                      height="40px"
                      binary={store.imgPath || user.avatar}
                    />{" "}
                    {user?.prenom} {user?.nom}
                  </>
                ) : (
                  <>
                    <Avatar width="40px" height="40px" /> {user?.nom}{" "}
                    {user?.prenom}
                  </>
                )}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {!props?.isNotActive && (
                <Dropdown.Item onClick={() => profil()} eventKey="1">
                  <span>Profil</span>
                </Dropdown.Item>
              )}
              <Dropdown.Item eventKey="2">
                <Logout />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      </div>
    </header>
  );
};

export default React.memo(Header);
