import {AbstractCrudService, PostPutModel} from "@onf/socleweb-react";
import {Parametres} from "../Utils/types";

class ParametreService extends AbstractCrudService {
  getParameters() {
    return this.httpService.get<any>("/parametres");
  }

  public async getCartoParameters() {
    return this.httpService.get("/parametrage");
  }

  createParams(params: Parametres) {
    return this.httpService.post<any>(
      "/parametres",
      new PostPutModel(params)
    );
  }

  getEndpointPath(): string {
    return "/parametrage";
  }
}

export default new ParametreService();