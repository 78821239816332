import { AbstractCrudService, PostPutModel } from "@onf/socleweb-react";
import { applyEmailExceptionInterception } from "../Utils/Interceptors";
import { User, UtilisateurAInscrire } from "../Utils/types";
export type TypeRetourPagination<T> = {
  resultats: T[];
  total: number;
}
class UtilisateurService extends AbstractCrudService {
  constructor() {
    super();
    applyEmailExceptionInterception(this);
  }

  postRecherche(criteres: any) {
    return this.httpService.post<TypeRetourPagination<any>>(
      `/utilisateur/recherche`,
      new PostPutModel(criteres)
    );
  }

  rechercheUtilisateursColumnFilters() {
    return this.httpService.get(`/utilisateur/recherche/filters`);
  }

  rechercheUtilisateurAInscrire(criteres: any) {
    return this.httpService.post(
      `/utilisateur/inscription/recherche-a-inscrire`,
      new PostPutModel(criteres)
    );
  }

  rechercheUtilisateurAInscrireSuivi(criteres: any) {
    return this.httpService.post(
      `/utilisateur/inscription/recherche-a-inscrire-suivi`,
      new PostPutModel(criteres)
    );
  }

  statistiqueInscriptionSuivi() {
    return this.httpService.get(
      `/utilisateur/inscription/statistique-inscription-suivi`
    );
  }

  changeStatutUtilisateurAInscrire(utilisateurAInscrireStatut: any) {
    return this.httpService.post(
      `/utilisateur/inscription/statut`,
      new PostPutModel(utilisateurAInscrireStatut)
    );
  }

  rejectUtilisateurAInscrire(id: string) {
    return this.httpService.get(`/utilisateur/inscription/reject/${id}`);
  }

  acceptUtilisateurAInscrire(id: string, isMailgenerique: any) {
    return this.httpService.get(
      `/utilisateur/inscription/accept/${id}/${isMailgenerique}`
    );
  }

  renvoyerMailUtilisateurAInscrire(id: string) {
    return this.httpService.get(`/utilisateur/inscription/renvoyer-mail/${id}`);
  }

  renvoyerMailsUtilisateurAInscrire(criteres: any) {
    return this.httpService.post(`/utilisateur/inscription/renvoyer-mails`, new PostPutModel(criteres));
  }

  getCountMailsUtilisateurAInscrire(criteres: any) {
    return this.httpService.post(`/utilisateur/inscription/renvoyer-mails-count`, new PostPutModel(criteres));
  }

  supprimerUtilisateurAInscrire(id: string) {
    return this.httpService.delete(`/utilisateur/inscription/${id}`);
  }

  postRechercheActions(criteres: any) {
    return this.httpService.post(
      `/utilisateur/rechercheActions`,
      new PostPutModel(criteres)
    );
  }

  inscrire(user: UtilisateurAInscrire) {
    return this.httpService.post(
      `/utilisateur/inscription/externe`,
      new PostPutModel(user)
    );
  }
  getReCpatchaPublicKey() {
    return this.httpService.get<any[]>(
      "/utilisateur/inscription/externe/recaptcha"
    );
  }

  searchUsersRepresentant(criteres: any) {
    return this.httpService.post(
      `/utilisateur/representant/recherche`,
      new PostPutModel(criteres)
    );
  }

  getUsersRepresentantSearchFilters(representantId: string) {
    return this.httpService.get(`/utilisateur/representant/recherche/filters/${representantId}`);
  }

  getTypes() {
    return this.httpService.get<any[]>("/utilisateur/types");
  }

  areAllActive(typeCode: string) {
    return this.httpService.axiosInstance({
      url: "/utilisateur/areAllActive",
      params: { typeCode },
      method: "GET",
    });
  }

  activateAll(typeCode: string, active: boolean) {
    return this.httpService.axiosInstance({
      url: "/utilisateur/activateAll",
      params: { typeCode, active },
      method: "POST",
    });
  }

  getById(id: string | null) {
    return this.httpService.get<any>(`/utilisateur/${id}`);
  }

  getForetsByUtilisateurId(id: string | null, criteres: any) {
    return this.httpService.post<any>(
      `/utilisateur/foret/${id}`,
      new PostPutModel(criteres)
    );
  }

  addForetComplement(data: any) {
    return this.httpService.post<any>(
      `/utilisateur/foret/complement`,
      new PostPutModel(data)
    );
  }

  deleteForetComplement(userId: string, foretId: string) {
    return this.httpService.delete<any>(
      `/utilisateur/foret/complement/${userId}/${foretId}`
    );
  }

  getForetsComplementByUtilisateurId(id: string | null, criteres: any) {
    return this.httpService.post<any>(
      `/utilisateur/foret/complement/${id}`,
      new PostPutModel(criteres)
    );
  }

  verifyRecaptchaResponse(captchaResponse: string) {
    return this.httpService.get<any>(
      `/utilisateur/inscription/externe/verify-captcha/${captchaResponse}`
    );
  }

  getAInscrireById(token: string | null) {
    return this.httpService.get<any>(
      `/utilisateur/inscription/externe/${token}`
    );
  }

  public async uploadUserToSignUpCSV(file: any) {
    const formData = new FormData();

    formData.append("file", file);

    return this.httpService.axiosInstance.post<any>(
      "/utilisateur/inscription/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 1000 * 60 * 60 * 24,
      }
    );
  }

  getAllModeContactPriviligier() {
    return this.httpService.get<any>(
      "/utilisateur/inscription/externe/mode-contact-priviligier"
    );
  }

  getByEmail(email: string) {
    return this.httpService.post<any>(
      "/utilisateur/email/",
      new PostPutModel(email)
    );
  }
  getByEmailForInitPass(email: string) {
    return this.httpService.post<any>(
      "/utilisateur/mot-passe-oublie/email",
      new PostPutModel(email)
    );
  }

  sendMailMotPasseOublie(email: string) {
    return this.httpService.post<any>(
      "/utilisateur/mot-passe-oublie",
      new PostPutModel(email)
    );
  }

  getConnectedUser() {
    const token: string = localStorage.getItem("user") + "";
    return JSON.parse(
      atob(token.slice(token.indexOf(".") + 1, token.lastIndexOf(".")))
    );
  }

  updateNotifMessage(id: string) {
    return this.httpService.get<any>("/utilisateur/notif/" + id);
  }

  getCurrentUser() {
    return this.httpService.get<any>("/utilisateur/current");
  }

  getReInitPassTokenStatus(token: string) {
    return this.httpService.get<any>("/utilisateur/token-status/" + token);
  }

  updateCurrentUser(user: any) {
    return this.httpService.put<any>(
      "/utilisateur/current",
      new PostPutModel(user)
    );
  }

  insertUser(userCreate: User) {
    return this.httpService.post<any>(
      "/utilisateur",
      new PostPutModel(userCreate)
    );
  }

  updateUser(id: string, userUpdate: User) {
    return this.httpService.put<any>(
      `/utilisateur/${id}`,
      new PostPutModel(userUpdate)
    );
  }

  actifInactifUser(users: any[]) {
    return this.httpService.post<any>(
      "/utilisateur/activation",

      new PostPutModel(users)
    );
  }

  deleteUser(id: string) {
    return this.httpService.delete<any>("/utilisateur/" + id);
  }

  initPassword(id: string, password: string, emailToken: string, type: string) {
    return this.httpService.post<any>(
      `/utilisateur/init/${encodeURIComponent(id)}/${type}`,
      new PostPutModel({ password, emailToken })
    );
  }

  uploadAvatar(avatar: any) {
    return this.httpService.axiosInstance.put("/utilisateur/avatar", avatar, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  changePassword(password: string) {
    return this.httpService.put<any>(
      `/utilisateur/password`,
      new PostPutModel({ password })
    );
  }

  validateCGU(id: string) {
    return this.httpService.get<any>(`/utilisateur/cgu/${id}`);
  }

  updateFlPremCnx(id: string) {
    return this.httpService.get<any>(`/utilisateur/premCnx/${id}`);
  }

  getEndpointPath(): string {
    return "/utilisateur";
  }

  getAllService() {
    return this.httpService.get<any>("/utilisateur/services");
  }

  getAllFonction() {
    return this.httpService.get<any>("/utilisateur/fonctions");
  }

  getUrlVideo() {
    return this.httpService.get<any>("/utilisateur/urlVideo");
  }

  getUrlVideoCarte() {
    return this.httpService.get<any>("/utilisateur/urlVideo/carte");
  }

  getUrlVideoCoupe() {
    return this.httpService.get<any>("/utilisateur/urlVideo/coupe");
  }

  public async exportUtilisateursInPDF(criteres: any) {
    return this.httpService.axiosInstance({
      url: "/utilisateur/export/pdf",
      method: "POST",
      responseType: "blob",
      data:criteres
    });
  }

  public async exportUtilisateursInExcel(criteres: any) {
    return this.httpService.axiosInstance({
      url: "/utilisateur/export/excel",
      method: "POST",
      responseType: "arraybuffer",
      data:criteres
    });
  }

  public async exportUtilisateursAInscrireInPDF(criteres: any) {
    return this.httpService.axiosInstance({
      url: "/utilisateur/inscription/export/pdf",
      method: "POST",
      responseType: "blob",
      data:criteres
    });
  }

  public async exportUtilisateursSuiviInscriptionInPDF(criteres: any) {
    return this.httpService.axiosInstance({
      url: "/utilisateur/inscription/suivi/export/pdf",
      method: "POST",
      responseType: "blob",
      data:criteres
    });
  }

  public async exportUtilisateursAInscrireInExcel(criteres: any) {
    return this.httpService.axiosInstance({
      url: "/utilisateur/inscription/export/excel",
      method: "POST",
      responseType: "arraybuffer",
      data:criteres
    });
  }

  public async exportUtilisateursSuiviInscriptionInExcel(criteres: any) {
    return this.httpService.axiosInstance({
      url: "/utilisateur/inscription/suivi/export/excel",
      method: "POST",
      responseType: "arraybuffer",
      data:criteres
    });
  }

  getAllNotificationFrequences(id: string) {
    return this.httpService.get<any>(`/utilisateur/notif/frequence/${id}`);
  }

  changeNotificationFrequence(notificationFrequence: string) {
    return this.httpService.put<any>(`/utilisateur/notif/frequence`, new PostPutModel({ notificationFrequence }));
  }

  updateLastConnectionDateTime() {
    return this.httpService.get<any>("/utilisateur/last_connection");
  }

}

export default new UtilisateurService();
