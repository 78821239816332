import * as React from "react"

function  SvgAlertBull(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
            viewBox="0 0 22 22"
        >
            <circle cx="11" cy="11" r="10.5" fill="#D9601C" stroke="#D9601C"></circle>
            <path
                fill="#fff"
                d="M10.02 13.22l-.28-7.08-.031-1.112a1 1 0 011-1.028h.033a1 1 0 011 1.037L11.7 6.14l-.28 7.08h-1.4zm.7 4.42c-.387 0-.713-.133-.98-.4a1.45 1.45 0 01-.4-1.04c0-.427.133-.78.4-1.06s.593-.42.98-.42.713.14.98.42c.267.28.4.633.4 1.06 0 .413-.133.76-.4 1.04-.267.267-.593.4-.98.4z"
            ></path>
        </svg>
    );
}

export default SvgAlertBull