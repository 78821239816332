import * as React from "react"

function  SvgInfoBull(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 23 22"
            {...props}
        >
            <ellipse
                cx="11.483"
                cy="11.282"
                fill="#21A6C3"
                rx="11"
                ry="10.718"
            ></ellipse>
            <path
                fill="#fff"
                d="M11.443 5c.382 0 .672.103.868.308.205.205.308.476.308.812 0 .383-.14.7-.42.952a1.45 1.45 0 01-.98.364 1.21 1.21 0 01-.826-.308 1.056 1.056 0 01-.35-.812c0-.364.144-.672.434-.924.289-.261.61-.392.966-.392zm.679 3.802c.19.071.315.252.315.454v5.924c0 .26.2.476.459.498l1.202.1a.408.408 0 01-.034.814H8.893a.41.41 0 01-.036-.817l1.207-.108a.5.5 0 00.456-.498v-4.01a.5.5 0 00-.31-.463l-1.352-.559a.38.38 0 01.065-.722l2.927-.633a.485.485 0 01.273.02z"
            ></path>
        </svg>
    );
}

export default SvgInfoBull