import { AbstractCrudService, PostPutModel } from "@onf/socleweb-react";

class RepresentantService extends AbstractCrudService {
  getById(id: string) {
    return this.httpService.get<any>(`/representant/${encodeURIComponent(id)}`);
  }

  recherche(filters: any) {
    return this.httpService.post<any>(
      "/representant/recherche",
      new PostPutModel(filters)
    );
  }

  verifyGrantedUser(id: string) {
    return this.httpService.get<any>(
      `/representant/granted/${encodeURIComponent(id)}`
    );
  }

  rechercheParForet(filters: any) {
    return this.httpService.post<any>(
      "/representant/recherche/foret",
      new PostPutModel(filters)
    );
  }

  getForetsAmenagements(filters: any) {
    return this.httpService.post<any>(
      "/representant/amenagements",
      new PostPutModel(filters)
    );
  }

  public async exportAllForetsAmenagementsRepresentantInPDF(data: any) {
    return this.httpService.axiosInstance({
      url: `/representant/${data.representantId}/amenagements/export/pdf`,
      method: "POST",
      responseType: "blob",
      data
    });
  }

  public async exportAllForetsAmenagementsRepresentantInExcel(data: any) {
    return this.httpService.axiosInstance({
      url: `/representant/${data.representantId}/amenagements/export/excel`,
      method: "POST",
      responseType: "arraybuffer",
      data
    });
  }

  public async exportSelectedForetsAmenagementsRepresentantInPDF(
    representantId: string,
    ids: string[] | undefined,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/representant/${representantId}/amenagements/export/pdf`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "blob",
      data: ids,
    });
  }

  public async exportSelectedForetsAmenagementsRepresentantInExcel(
    representantId: string,
    ids: string[] | undefined,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/representant/${representantId}/amenagements/export/excel`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "arraybuffer",
      data: ids,
    });
  }

  getAllRepresentantsForUser() {
    return this.httpService.get<any>("/representant/all");
  }

  public async exportRepresentantsInPDF(data: object) {
    return this.httpService.axiosInstance({
      url: "/representant/export/pdf",
      data,
      method: "POST",
      responseType: "blob",
    });
  }

  public async exportRepresentantsInExcel(data: object) {
    return this.httpService.axiosInstance({
      url: "/representant/export/excel",
      data,
      method: "POST",
      responseType: "arraybuffer",
    });
  }

  getRepresentantsByIds(ids: string[]) {
    return this.httpService.post("/representant/ids", new PostPutModel(ids));
  }

  public async exportForetRepresentantInPDF(filters: any) {
    return this.httpService.axiosInstance({
      url: `${this.getEndpointPath()}/recherche/foret/export/pdf`,
      method: "POST",
      responseType: "blob",
      data: filters,
    });
  }

  public async exportForetRepresentantInExcel(filters: any) {
    return this.httpService.axiosInstance({
      url: `${this.getEndpointPath()}/recherche/foret/export/excel`,
      method: "POST",
      responseType: "arraybuffer",
      data: filters,
    });
  }

  getRepresentantIdsByUserId() {
    return this.httpService.get<any>(`${this.getEndpointPath()}/ids`);
  }

  getFicheRepresentant(id: string) {
    return this.httpService.get<any>(`/representant/fiche/${encodeURIComponent(id)}`);
  }

  updateRepresentantCoordonnees(data: any) {
    return this.httpService.put<any>(
      `/representant/coordonnees`,
      new PostPutModel(data)
    );
  }

  updatePrincipalResponsable(representantId: string, utilisateurId: string) {
    return this.httpService.put<any>(
      `/representant/responsable/${encodeURIComponent(representantId)}/${encodeURIComponent(utilisateurId)}`,
      new PostPutModel()
    );
  }

  getEndpointPath(): string {
    return "/representant";
  }
}

export default new RepresentantService();
