export const EXCEL_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
export const EXCEL_FILE_EXTENSION = ".xlsx";
export const PDF_FILE_EXTENSION = ".pdf";
export const URL_ONF = "https://www.onf.fr/aux-cotes-des-territoires";
export const NULL_VALUE = ["#NA", "#??"];

export const InputSelectStyles = {
  container: (styles: any, state: any) => ({
    ...styles,
    width: "100%",
    boxShadow:
      state.isFocused || state.isSelected
        ? "0 0 0 0.05rem #408c7a !important"
        : "",
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: 20000000,
  }),
  menuPortal: (styles: any) => ({
    ...styles,
    zIndex: 2000000,
    //paddingLeft: "2px !important",
  }),
  input: (styles: any) => ({
    ...styles,
    width: "100%",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    borderColor: state.selectProps.isInvalid ? "#ff2323" : "hsl(0,0%,80%)",
    "&:hover": {
      borderColor: state.selectProps.isInvalid ? "#ff2323" : "hsl(0,0%,80%)",
    },
    boxShadow: "0 transparent",
    borderRadius: "0",
    minHeight: "100%",
    height: "100%",
  }),
  option: (theme: any, state: any) => ({
    ...theme,
    backgroundColor: state.isFocused
      ? "#c7ece3"
      : (state.isSelected && "#49a792") || "white",
    color: "black",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.selectProps.isInvalid ? "#ff2323" : "hsl(0,0%,80%)",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    div: {
      color: state.selectProps.isInvalid ? "#ff2323" : "hsl(0,0%,80%)",
      "&:hover": {
        color: state.selectProps.isInvalid ? "#ff2323" : "hsl(0,0%,80%)",
      },
    },
  }),
  menuList: (provided: any) => ({
    ...provided,
    "::-webkit-scrollbar": {
      height: "10px",
      width: "10px",
      background: "#d7d7cd",
    },

    "::-webkit-scrollbar-thumb": {
      background: "#fff",
      border: "2px solid #408c7a",
    },
    "::-webkit-scrollbar-corner": {
      background: "#d7d7cd",
    },
  }),
};

export const styleSelectMemo = {
  container: (provided: any, state: any) => ({
    ...provided,
    border: "none",
    borderBottom: state.selectProps.isInvalid
      ? "2px solid #ff2323"
      : "2px solid",
    marginRight: "1rem",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    borderColor: state.selectProps.isInvalid ? "#ff2323" : "hsl(0,0%,80%)",
    "&:hover": {
      borderColor: state.selectProps.isInvalid ? "#ff2323" : "hsl(0,0%,80%)",
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "black",
  }),
  option: (theme: any, state: any) => ({
    ...theme,
    backgroundColor: state.isFocused
      ? "#c7ece3"
      : (state.isSelected && "#49a792") || "white",
    color: "black",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "#FFF",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    div: {
      color: state.selectProps.isInvalid ? "#ff2323" : "hsl(0,0%,80%)",
      "&:hover": {
        color: state.selectProps.isInvalid ? "#ff2323" : "hsl(0,0%,80%)",
      },
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    whiteSpace: "nowrap",
  }),
  menuList: (provided: any) => ({
    ...provided,
    "::-webkit-scrollbar": {
      height: "10px",
      width: "10px",
      background: "#d7d7cd",
    },

    "::-webkit-scrollbar-thumb": {
      background: "#fff",
      border: "2px solid #408c7a",
    },
    "::-webkit-scrollbar-corner": {
      background: "#d7d7cd",
    },
  }),
};
