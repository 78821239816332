import { StorageManagerService } from "../../Services";
import { IMenu } from "../interface";

export const recursiveMenu = (menu: IMenu) => {
  if (checkAccessPermission(menu)) {
    if(!menu?.subMenu) return menu;
    const subMenu: IMenu[] = [];
    menu?.subMenu?.forEach((sbMenu: IMenu) => {
        const menuTmp = recursiveMenu(sbMenu);
        if(menuTmp) subMenu.push(menuTmp)
    });
    menu.subMenu = subMenu;
    return menu;
  }
};

const checkAccessPermission = (menu: IMenu): IMenu | null => {
  if (
    (menu?.permissions &&
      StorageManagerService.hasPermission(menu?.permissions)) ||
    (menu?.isAdmin && StorageManagerService.isAdmin()) ||
    (menu?.isTft && StorageManagerService.isTft()) ||
    (menu?.isInternalUser && StorageManagerService.isInternalUser()) ||
    (!menu?.isAdmin && !menu?.permissions && !menu?.isTft)
  )
    return menu;

  return null;
};
