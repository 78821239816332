import {AbstractCrudService, PostPutModel} from "@onf/socleweb-react";
import axios from "axios";

class ForetService extends AbstractCrudService {
  recherche(filters: any) {
    return this.httpService.post<any>(
      "/foret/recherche",
      new PostPutModel(filters)
    );
  }

  getParcelles(foretIds: string[]) {
    return this.httpService.post<any>(
      "/foret/parcelles",
      new PostPutModel(foretIds)
    );
  }

  getAgents(foretId: string) {
    return this.httpService.get<any>(`/foret/${foretId}/agents`);
  }

  getAllRegions() {
    return this.httpService.get<any>(`/foret/regions`);
  }

  getCoupes(foretId: string) {
    return this.httpService.get<any>(`/foret/${foretId}/coupes`);
  }

  getForetById(id: string) {
    return this.httpService.get<any>("/foret/" + id);
  }

  getForetByIdNatFrt(id: string, amenagementId: string) {
    return this.httpService.get<any>(
      "/foret/id_nat_frt/" + id + "/" + amenagementId
    );
  }

  //---------------------------------------- START PARCELLES -----------------------------
  public async exportParcellesInPDF(
    foretIds: string[] | undefined,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/foret/parcelles/export/pdf`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "blob",
      data: foretIds,
    });
  }

  public async exportParcellesInExcel(
    foretIds: string[] | undefined,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/foret/parcelles/export/excel`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "arraybuffer",
      data: foretIds,
    });
  }
  //---------------------------------------- END PARCELLES -----------------------------

  //---------------------------------------- START COUPES AGENTS -----------------------------
  public async exportAllAgentsInPDF(id: string, triPar: string[]) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/agents/export/all/pdf`,
      params: { triPar: triPar.join(",") },
      method: "GET",
      responseType: "blob",
    });
  }

  public async exportAllAgentsInExcel(id: string, triPar: string[]) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/agents/export/all/excel`,
      params: { triPar: triPar.join(",") },
      method: "GET",
      responseType: "arraybuffer",
    });
  }

  public async exportSelectedAgentsInPDF(
    id: string,
    agentIds: string[] | undefined,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/agents/export/pdf`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "blob",
      data: agentIds,
    });
  }

  public async exportSelectedAgentsInExcel(
    id: string,
    agentIds: string[] | undefined,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/agents/export/excel`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "arraybuffer",
      data: agentIds,
    });
  }
  //---------------------------------------- END COUPES AGENTS -----------------------------

  //---------------------------------------- START COUPES AMENAGEMENTS -----------------------------
  public async exportAllCoupesAmenagementsInPDF(id: string, triPar: string[]) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/amenagements/export/all/pdf`,
      params: { triPar: triPar.join(",") },
      method: "GET",
      responseType: "blob",
    });
  }

  public async exportAllCoupesAmenagementsInExcel(
    id: string,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/amenagements/export/all/excel`,
      params: { triPar: triPar.join(",") },
      method: "GET",
      responseType: "arraybuffer",
    });
  }

  public async exportSelectedCoupesAmenagementsInPDF(
    id: string,
    amenagementIds: string[] | undefined,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/amenagements/export/pdf`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "blob",
      data: amenagementIds,
    });
  }

  public async exportSelectedCoupesAmenagementsInExcel(
    id: string,
    amenagementIds: string[] | undefined,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/amenagements/export/excel`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "arraybuffer",
      data: amenagementIds,
    });
  }
  //---------------------------------------- END COUPES AMENAGEMENTS -----------------------------

  //---------------------------------------- START COUPES INSCRITES -----------------------------
  public async exportAllCoupesInscritesInPDF(id: string, triPar: string[]) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/inscrites/export/all/pdf`,
      params: { triPar: triPar.join(",") },
      method: "GET",
      responseType: "blob",
    });
  }

  public async exportAllCoupesInscritesInExcel(id: string, triPar: string[]) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/inscrites/export/all/excel`,
      params: { triPar: triPar.join(",") },
      method: "GET",
      responseType: "arraybuffer",
    });
  }

  public async exportSelectedCoupesInscritesInPDF(
    id: string,
    inscriteIds: string[] | undefined,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/inscrites/export/pdf`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "blob",
      data: inscriteIds,
    });
  }

  public async exportSelectedCoupesInscritesInExcel(
    id: string,
    inscriteIds: string[] | undefined,
    triPar: string[]
  ) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/inscrites/export/excel`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "arraybuffer",
      data: inscriteIds,
    });
  }
  //---------------------------------------- END COUPES INSCRITES -----------------------------

  //---------------------------------------- START COUPES DESIGNEES -----------------------------
  public async exportAllCoupesDesigneesInPDF(id: string, triPar: string[]) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/designees/export/all/pdf`,
      params: { triPar: triPar.join(",") },
      method: "GET",
      responseType: "blob",
    });
  }

  public async exportAllCoupesDesigneesInExcel(id: string, triPar: string[]) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/designees/export/all/excel`,
      params: { triPar: triPar.join(",") },
      method: "GET",
      responseType: "arraybuffer",
    });
  }

  public async exportSelectedCoupesDesigneesInPDF(id: string, designeeIds: string[] | undefined, triPar: string[]) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/designees/export/pdf`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "blob",
      data: designeeIds,
    });
  }

  public async exportSelectedCoupesDesigneesInExcel(id: string, designeeIds: string[] | undefined, triPar: string[] ) {
    return this.httpService.axiosInstance({
      url: `/foret/${id}/coupes/designees/export/excel`,
      params: { triPar: triPar.join(",") },
      method: "POST",
      responseType: "arraybuffer",
      data: designeeIds,
    });
  }
  //---------------------------------------- END COUPES DESIGNEES -----------------------------

  saveForetStateActivation(foret: any[], userId: string) {
    return this.httpService.post<any>(
      "/foret/activation/" + userId,
      new PostPutModel(foret)
    );
  }

  getEndpointPath(): string {
    return "/foret";
  }



  //--------------------------------- GET SURFACE FROM ONF API --------------------------------------

  getSurface(url: string, idForet:string, type?: string){    
    return axios.get<any>(
      url + "/25/query?" +
        "where=TYPE_RDF%3D%27" +
        type +
        "%27+AND+ID_RDF%3D%27" +
        idForet +
        "%27&outFields=TYPE_ZONAGE%2C+" +
        "SURFACE&returnDistinctValues=true&featureEncoding=esriDefault&f=pjson"
    );
  }
}



export default new ForetService();