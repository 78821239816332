import * as React from "react"

function  SvgParametre(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <path
                fill="#fff"
                d="M14.758 11.618a5.616 5.616 0 015.2-5.602 5.621 5.621 0 012.732.482.624.624 0 01.185 1.01l-2.863 2.862 1.614 1.614 2.863-2.862a.624.624 0 011.012.183 5.616 5.616 0 01-6.502 7.76l-7.949 8.048a2.944 2.944 0 01-4.213-4.11l8.024-8.312a5.646 5.646 0 01-.103-1.073zm5.62-4.369a4.372 4.372 0 00-4.23 5.475.624.624 0 01-.155.59L7.736 21.87a1.694 1.694 0 102.426 2.366l8.209-8.313a.625.625 0 01.64-.155 4.371 4.371 0 005.645-5.048l-2.588 2.588a.624.624 0 01-.883 0l-2.497-2.496a.624.624 0 010-.884l2.587-2.586a4.392 4.392 0 00-.897-.093z"
            ></path>
            <circle
                cx="16"
                cy="16"
                r="15.25"
                stroke="#fff"
                strokeWidth="1.5"
            ></circle>
        </svg>
    );
}

export default SvgParametre