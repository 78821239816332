import * as React from "react";

function SvgHome(props: any) {
  return (
    <svg
      id="home_svg__Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="home_svg__clip-path">
          <path
            className="home_svg__cls-1"
            d="M789.46-93.32l25.78-36.15 1.36 1.05-26.73 37.17-.8.29zm-56.26-45.41H792v24.21a3.14 3.14 0 002.69 3.13L784-96.43a3.37 3.37 0 00-.56 1.35l-1.35 8.45a3.18 3.18 0 001.08 2.94 3.09 3.09 0 003.05.54l6.55-2.46a3.18 3.18 0 00-.64 1.12c-.2.59-.3.89-.17 18.69l-11.1 11.43H733.2zm-3.1-6.27a3.13 3.13 0 00-3.1 3.16v90.68a3.14 3.14 0 003.1 3.16h52.07a3.12 3.12 0 002.2-.94l12.92-13.3a3.22 3.22 0 00.89-2.24c-.06-8.08-.09-17.47 0-18.51a3.17 3.17 0 00-2.09-3.45 3.1 3.1 0 00-1.95 0 2.65 2.65 0 00.21-.26l29.09-40.45A3.16 3.16 0 00824-129v-.05a3.18 3.18 0 00-1.23-2.51c-3.18-2.43-5.59-4.34-5.8-4.52a3 3 0 00-2.45-1 3.08 3.08 0 00-2.38 1.25l-14 19.58v-25.59a3.13 3.13 0 00-3.1-3.16z"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-2">
          <path
            className="home_svg__cls-1"
            d="M738.48-119.75c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11H783c1.68 0 3-1 3-2.11s-1.37-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-3">
          <path
            className="home_svg__cls-1"
            d="M738.48-107.1c-1.68 0-3 .95-3 2.11s1.36 2.11 3 2.11H783c1.68 0 3-1 3-2.11s-1.37-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-4">
          <path
            className="home_svg__cls-1"
            d="M738.48-94.44c-1.68 0-3 .94-3 2.1s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.1-3-2.1z"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-5">
          <path
            className="home_svg__cls-1"
            d="M738.48-77.58c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-6">
          <path
            className="home_svg__cls-1"
            d="M183 26.77c-1.71 0-3.1 1-3.1 2.15s1.39 2.16 3.1 2.16h45.44c1.72 0 3.11-1 3.11-2.16s-1.39-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-7">
          <path
            className="home_svg__cls-2"
            d="M183 39.69c-1.71 0-3.1 1-3.1 2.15S181.27 44 183 44h45.44c1.72 0 3.11-1 3.11-2.15s-1.39-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-8">
          <path
            className="home_svg__cls-1"
            d="M183 52.6c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-9">
          <path
            className="home_svg__cls-1"
            d="M183 69.82c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-10">
          <path
            className="home_svg__cls-1"
            d="M921.41-107.31a2.21 2.21 0 00-2.16 2.26v42.82a2.21 2.21 0 002.16 2.23 2.21 2.21 0 002.15-2.26V-105a2.21 2.21 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-11">
          <path
            className="home_svg__cls-1"
            d="M895.58-107.31a2.2 2.2 0 00-2.15 2.26v42.82a2.2 2.2 0 002.15 2.23 2.2 2.2 0 002.15-2.26V-105a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="home_svg__clip-path-12">
          <path
            className="home_svg__cls-2"
            d="M908.49-107.31a2.2 2.2 0 00-2.15 2.26v42.82a2.2 2.2 0 002.15 2.23 2.21 2.21 0 002.16-2.26V-105a2.21 2.21 0 00-2.16-2.26"
          />
        </clipPath>
        <style>
          {
            ".home_svg__cls-1,.home_svg__cls-3,.home_svg__cls-4{fill:#333}.home_svg__cls-1,.home_svg__cls-2{clip-rule:evenodd}.home_svg__cls-2{fill:none}.home_svg__cls-4{fill-rule:evenodd}"
          }
        </style>
      </defs>
      <path
        className="home_svg__cls-3"
        d="M98.59 46.72L80.65 31.58v-22a2.54 2.54 0 00-5.07 0v17.73L52.23 7.61a2.55 2.55 0 00-3.24 0L1.59 46.7a2.57 2.57 0 00-.91 1.72 2.53 2.53 0 00.57 1.85 2.6 2.6 0 003.57.34l45.76-37.76L75.8 34.12a2.52 2.52 0 001.79 1.51l17.74 15a2.5 2.5 0 001.63.6h.22a2.49 2.49 0 001.72-.89 2.54 2.54 0 00-.31-3.62z"
      />
      <path
        className="home_svg__cls-3"
        d="M51.71 19.41a2.54 2.54 0 00-3.24 0l-35 28.81a2.53 2.53 0 00-.92 1.95V84a2.54 2.54 0 002.53 2.54H42.4A2.54 2.54 0 0044.93 84V63h10.89v21a2.54 2.54 0 002.53 2.54h26.93A2.54 2.54 0 0087.82 84V50.85a2.51 2.51 0 00-.91-1.94zm6.64 38.51h-16a2.54 2.54 0 00-2.54 2.53v21H17.64V51.36l32.43-26.71L82.75 52v29.5H60.88v-21a2.53 2.53 0 00-2.53-2.58z"
      />
      <path
        className="home_svg__cls-4"
        d="M789.46-93.32l25.78-36.15 1.36 1.05-26.73 37.17-.8.29zm-56.26-45.41H792v24.21a3.14 3.14 0 002.69 3.13L784-96.43a3.37 3.37 0 00-.56 1.35l-1.35 8.45a3.18 3.18 0 001.08 2.94 3.09 3.09 0 003.05.54l6.55-2.46a3.18 3.18 0 00-.64 1.12c-.2.59-.3.89-.17 18.69l-11.1 11.43H733.2zm-3.1-6.27a3.13 3.13 0 00-3.1 3.16v90.68a3.14 3.14 0 003.1 3.16h52.07a3.12 3.12 0 002.2-.94l12.92-13.3a3.22 3.22 0 00.89-2.24c-.06-8.08-.09-17.47 0-18.51a3.17 3.17 0 00-2.09-3.45 3.1 3.1 0 00-1.95 0 2.65 2.65 0 00.21-.26l29.09-40.45A3.16 3.16 0 00824-129v-.05a3.18 3.18 0 00-1.23-2.51c-3.18-2.43-5.59-4.34-5.8-4.52a3 3 0 00-2.45-1 3.08 3.08 0 00-2.38 1.25l-14 19.58v-25.59a3.13 3.13 0 00-3.1-3.16z"
      />
      <path
        className="home_svg__cls-4"
        d="M738.48-119.75c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11H783c1.68 0 3-1 3-2.11s-1.37-2.11-3-2.11zM738.48-107.1c-1.68 0-3 .95-3 2.11s1.36 2.11 3 2.11H783c1.68 0 3-1 3-2.11s-1.37-2.11-3-2.11zM738.48-94.44c-1.68 0-3 .94-3 2.1s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.1-3-2.1zM738.48-77.58c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.11-3-2.11zM177.6 7.4h60s-.14 56.27 0 74.43L226.25 93.5H177.6zM174.43 1a3.19 3.19 0 00-3.16 3.22v92.5a3.2 3.2 0 003.16 3.23h53.14a3.15 3.15 0 002.25-1L243 85.41a3.23 3.23 0 00.91-2.28c-.06-8.24 0-79 0-79A3.19 3.19 0 00240.75 1z"
      />
      <path
        className="home_svg__cls-4"
        d="M183 26.77c-1.71 0-3.1 1-3.1 2.15s1.39 2.16 3.1 2.16h45.44c1.72 0 3.11-1 3.11-2.16s-1.39-2.15-3.11-2.15z"
      />
      <g clipPath="url(#home_svg__clip-path-6)">
        <path
          className="home_svg__cls-3"
          d="M-1963.69-950.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="home_svg__cls-4"
        d="M183 52.6c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#home_svg__clip-path-8)">
        <path
          className="home_svg__cls-3"
          d="M-1963.69-950.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="home_svg__cls-4"
        d="M183 69.82c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#home_svg__clip-path-9)">
        <path
          className="home_svg__cls-3"
          d="M-1963.69-950.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="home_svg__cls-4"
        d="M921.41-107.31a2.21 2.21 0 00-2.16 2.26v42.82a2.21 2.21 0 002.16 2.23 2.21 2.21 0 002.15-2.26V-105a2.21 2.21 0 00-2.15-2.26"
      />
      <g clipPath="url(#home_svg__clip-path-10)">
        <path
          className="home_svg__cls-3"
          d="M-1486.88-1097.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="home_svg__cls-4"
        d="M895.58-107.31a2.2 2.2 0 00-2.15 2.26v42.82a2.2 2.2 0 002.15 2.23 2.2 2.2 0 002.15-2.26V-105a2.2 2.2 0 00-2.15-2.26"
      />
    </svg>
  );
}

export default SvgHome;
