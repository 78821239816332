import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    UPDATE_AVATAR: (_: any, imgPath: any) => {
      return { imgPath: imgPath };
    },
  };

  initStore(actions, {
    imgPath: null,
  });
};

export default configureStore;