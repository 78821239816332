import React from "react";
import {Button, Image} from "react-bootstrap";
import SvgReloadCircle from "../../../assets/svg/reload-circle.svg";
import {ExportData} from "../../ExportData";
import CTooltip from "../CTooltip/CTooltip";

interface IProps {
  isExcelLoading?: boolean;
  isPDFLoading?: boolean;
  exportAllCSV?: () => void;
  exportAllPDF?: () => void;
  exportSelectedCSV?: () => void;
  exportSelectedPDF?: () => void;
  onReload?: () => void;
  disabled?: boolean;
}

const ExportDataBlock = ({
  isExcelLoading,
  isPDFLoading,
  exportAllCSV,
  exportAllPDF,
  exportSelectedCSV,
  exportSelectedPDF,
  onReload,
  disabled,
}: IProps) => {
  return (
    <div className="btns-container py-3">
      <div className="btns-export-container">
        {onReload && (
          <CTooltip id="reload" value="Réinitialiser l'affichage du tableau">
            <Button variant="link" onClick={onReload}>
              <Image src={SvgReloadCircle} /> <span>Réinitialiser</span>
            </Button>
          </CTooltip>
        )}
        <ExportData
          textButton="Exporter toutes les données"
          isExcelLoading={isExcelLoading}
          isPDFLoading={isPDFLoading}
          exportAllCSV={exportAllCSV}
          exportAllPDF={exportAllPDF}
        />
        <ExportData
          textButton="Exporter la sélection en cours"
          isExcelLoading={isExcelLoading}
          isPDFLoading={isPDFLoading}
          exportSelectedCSV={exportSelectedCSV}
          exportSelectedPDF={exportSelectedPDF}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default ExportDataBlock;
