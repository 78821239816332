import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    UPDATE_CARTO: (_: any, mapFeatures: any[]) => {
      return { mapFeatures: mapFeatures };
    },
    UPDATE_CARTO_PARCELLES_SELECTED: (_: any, parcelleIds: any) => {
      return { parcelleIds: parcelleIds };
    },
    UPDATE_CARTO_UG_SELECTED: (_: any, ugIds: any) => {
      return { ugIds: ugIds };
    },
  };

  initStore(actions, { mapFeatures: {}, ugIds: [], parcelleIds: [] });
};

export default configureStore;
