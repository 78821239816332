import React from "react";
import { IRoute } from "./Utils/interface";
import { FAQ_ADMIN_ACCESS, GED_ACCESS, ROLE_CREATE, ROLE_DELETE, ROLE_MODIFY, SDI_ACCESS, USER_ACTIVATE_MASS, USER_DEACTIVATE_MASS, USER_MANAGEMENT_ACCESS, VALIDATION_COMPTES_ACCESS } from "./Utils/Permissions";
import {Profil} from "./Pages/Profil";
import ConditionsGeneralesUtilisation from "./Pages/FooterPages/ConditionsGeneralesUtilisation";

const Accueil = React.lazy(() => import("./Pages/Accueil/Accueil"));

const Contact = React.lazy(() => import("./Pages/FooterPages/Contact"));
const InformationLegal = React.lazy(
  () => import("./Pages/FooterPages/MentionsLegales")
);
const PolitiqueDeConfidentialite = React.lazy(
  () => import("./Pages/FooterPages/PolitiqueConfidentialite")
);

const Document = React.lazy(() => import("./Pages/Documents/Documents"));
const Glossaire = React.lazy(() => import("./Pages/Glossaire/Glossaire"));
const Parametre = React.lazy(() => import("./Pages/Parametre/Parametre"));
const Gestion = React.lazy(() => import("./Pages/Gestion/Gestion"));
const Feedback = React.lazy(() => import("./Shared/Feedback"));
const Representant = React.lazy(
  () => import("./Pages/Gestion/Representant/Representant")
);
const Utilisateur = React.lazy(
  () => import("./Pages/Gestion/Utilisateurs/Utilisateurs")
);
const Role = React.lazy(
  () => import("./Pages/Gestion/Roles/Roles")
);
const ActionMasse = React.lazy(() => import("./Pages/Gestion/ActionMasse/ActionMasse"));
const GestionGlossaire = React.lazy(() => import("./Pages/Gestion/GestionGlossaire/GestionGlossaire"));
const ValidationsComptes = React.lazy(() => import("./Pages/Gestion/ValidationComptes/ValidationComptes"));
const SuiviInscriptions = React.lazy(() => import("./Pages/Gestion/SuiviInscriptions/SuiviInscriptions"));

const routes: IRoute[] = [
  {
    path: "/accueil",
    name: "Mes données",
    component: Accueil,
  },
  {
    path: "/profil",
    name: "Mon profil",
    component: Profil,
  },
  {
    path: "/accueil/dashboard/representant/:representantId",
    name: "Dashboard du représentant",
    component: Accueil,
  },
  {
    path: "/glossaire",
    name: "En savoir plus",
    component: Glossaire,
    exact: true,
  },
  {
    path: "/documents/:documentInfo?",
    name: "Mes documents",
    component: Document,
    permissions: [GED_ACCESS],
    isAdmin: true,
  },
  {
    path: "/parametre",
    name: "Paramétrage",
    component: Parametre,
    isAdmin: true,
  },
  {
    path: "/gestion",
    name: "Gestion des utilisateurs",
    component: Gestion,
    permissions: [USER_MANAGEMENT_ACCESS],
    exact: true,
  },
  {
    path: "/gestion/representant",
    name: "Représentant",
    component: Representant,
    permissions: [USER_MANAGEMENT_ACCESS],
  },
  {
    path: "/gestion/utilisateurs",
    name: "Utilisateurs",
    component: Utilisateur,
    permissions: [USER_MANAGEMENT_ACCESS],
  },
  {
    path: "/gestion/feedback",
    name: "Feedback",
    component: Feedback,
  },
  {
    path: "/gestion/validation_comptes",
    name: "Validation des comptes",
    component: ValidationsComptes,
    permissions: [VALIDATION_COMPTES_ACCESS],
    isAdmin: true,
  },
  {
    path: "/gestion/suivi_inscription",
    name: "Suivi des inscriptions",
    component: SuiviInscriptions,
    permissions: [SDI_ACCESS],
    isAdmin: true,
    isTft: true,
  },
  {
    path: "/gestion/roles",
    name: "Rôles et permissions",
    component: Role,
    permissions: [ROLE_CREATE, ROLE_MODIFY, ROLE_DELETE],
    isAdmin: true,
  },
  {
    path: "/gestion/action_masse",
    name: "Actions de masse",
    component: ActionMasse,
    permissions: [USER_ACTIVATE_MASS, USER_DEACTIVATE_MASS],
    isAdmin: true,
  },
  {
    path: "/gestion/glossaire_faq",
    name: "Glossaire",
    component: GestionGlossaire,
    permissions: [FAQ_ADMIN_ACCESS],
    isAdmin: true,
  },
  {
    path: "/mentions-legales",
    name: "Mentions légales",
    component: InformationLegal,
    exact: true,
  },
  {
    path: "/politique-confidentialite",
    name: "Politique de confidentialité",
    component: PolitiqueDeConfidentialite,
    exact: true,
  },
  {
    path: "/conditions-generales-utilisations",
    name: "Conditions générales d'utilisation",
    component: ConditionsGeneralesUtilisation,
    exact: true,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    exact: true,
  },
];

export default routes;