export default class ArrayHelper {
  static unique(x: any[]): any[] {
    return x.filter(function (elem, index) {
      return x.indexOf(elem) === index;
    });
  }

  /**
   * Get updated list of currently selected rows, taking into account on newly selected/deselected single row.
   * @param isSelected if selected: true, else: false
   * @param rows newly selected/deselected single row
   * @param selectedRows currently selected rows
   * @param id attribute to identify rows
   * @returns updated list
   */
  static getSelectedRows(
    row: any,
    isSelected: boolean,
    selectedRows: any[],
    id: string
  ): any[] {
    const newSelectedRows: any[] = [...selectedRows];

    if (isSelected) {
      newSelectedRows.push(row);
    } else {
      const index: number = newSelectedRows.findIndex(
        (s: any) => s[id] === row[id]
      );
      if (index > -1) newSelectedRows.splice(index, 1);
    }

    return ArrayHelper.unique(newSelectedRows);
  }

  /**
   * Get updated list of currently selected rows, taking into account on newly selected/deselected multiple rows.
   * @param isSelected if selected: true, else: false
   * @param rows newly selected/deselected multiple rows
   * @param selectedRows currently selected rows
   * @param id attribute to identify rows
   * @returns updated list
   */
  static getAllSelectedRows(
    isSelected: boolean,
    rows: any[],
    selectedRows: any[],
    id: string
  ): any[] {
    let newSelectedRows: any[] = [...selectedRows];
    if (isSelected) {
      newSelectedRows = newSelectedRows.concat(rows);
    } else {
      rows.forEach((row: any) => {
        const index: number = newSelectedRows.findIndex(
          (s: any) => s[id] === row[id]
        );
        if (index > -1) newSelectedRows.splice(index, 1);
      });
    }

    return ArrayHelper.unique(newSelectedRows);
  }

  static arrayMove(arr: any[], old_index: number, new_index: number) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

}
