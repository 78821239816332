import * as React from "react";

function SvgReloadCircle(props: any) {
  return (
    <svg
      id="reload-circle_svg__Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="reload-circle_svg__clip-path">
          <path
            className="reload-circle_svg__cls-1"
            d="M-3.83.45h107.61v103.3H-3.83z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-2">
          <path
            className="reload-circle_svg__cls-2"
            d="M50 .45a49.5 49.5 0 1049.48 49.49A49.54 49.54 0 0050 .45zm0 5A44.55 44.55 0 115.43 49.94 44.5 44.5 0 0150 5.4z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-3">
          <path className="reload-circle_svg__cls-1" d="M.48.45h99v99h-99z" />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-4">
          <path
            className="reload-circle_svg__cls-3"
            d="M632.44 52.18L658.23 16l1.35 1-26.73 37.25-.79.29zM576.19 6.77H635V31a3.14 3.14 0 002.7 3.13L627 49.07a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.2 3.2 0 001.08 2.94 3.1 3.1 0 003.05.54l6.56-2.46a3.06 3.06 0 00-.65 1.12c-.19.59-.29.89-.17 18.69l-11.1 11.43h-47.67zm-3.1-6.32A3.13 3.13 0 00570 3.61v90.68a3.14 3.14 0 003.1 3.16h52.06a3.14 3.14 0 002.21-.94l12.91-13.3a3.23 3.23 0 00.9-2.24c-.06-8.08-.09-17.47 0-18.51A3.18 3.18 0 00639 59a3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26l29.13-40.44a3.18 3.18 0 00.6-1.83 3.19 3.19 0 00-1.24-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3.14 3.14 0 00-4.83.23l-14 19.58V3.61a3.14 3.14 0 00-3.07-3.16z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-5">
          <path
            className="reload-circle_svg__cls-3"
            d="M581.46 25.75c-1.67 0-3 .95-3 2.11s1.37 2.11 3 2.11H626c1.68 0 3-.95 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-6">
          <path
            className="reload-circle_svg__cls-3"
            d="M581.46 38.4c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11H626c1.68 0 3-.95 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-7">
          <path
            className="reload-circle_svg__cls-3"
            d="M581.46 51.06c-1.67 0-3 .94-3 2.1s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.1-3-2.1z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-8">
          <path
            className="reload-circle_svg__cls-3"
            d="M581.46 67.92c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-9">
          <path
            className="reload-circle_svg__cls-3"
            d="M26 172.27c-1.71 0-3.11 1-3.11 2.15s1.4 2.16 3.11 2.16h45.41c1.71 0 3.11-1 3.11-2.16s-1.4-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-10">
          <path
            className="reload-circle_svg__cls-2"
            d="M26 185.19c-1.71 0-3.11 1-3.11 2.15s1.4 2.15 3.11 2.15h45.41c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-11">
          <path
            className="reload-circle_svg__cls-3"
            d="M26 198.1c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.8c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-12">
          <path
            className="reload-circle_svg__cls-3"
            d="M26 215.32c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.8c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-13">
          <path
            className="reload-circle_svg__cls-3"
            d="M764.39 38.19a2.2 2.2 0 00-2.15 2.26v42.82a2.15 2.15 0 104.3 0V40.45a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-14">
          <path
            className="reload-circle_svg__cls-3"
            d="M738.57 38.19a2.21 2.21 0 00-2.16 2.26v42.82a2.16 2.16 0 104.31 0V40.45a2.21 2.21 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="reload-circle_svg__clip-path-15">
          <path
            className="reload-circle_svg__cls-2"
            d="M751.48 38.19a2.2 2.2 0 00-2.15 2.26v42.82a2.15 2.15 0 104.3 0V40.45a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <style>
          {
            ".reload-circle_svg__cls-1,.reload-circle_svg__cls-2{fill:none}.reload-circle_svg__cls-2,.reload-circle_svg__cls-3{clip-rule:evenodd}.reload-circle_svg__cls-3,.reload-circle_svg__cls-8,.reload-circle_svg__cls-9{fill:#333}.reload-circle_svg__cls-9{fill-rule:evenodd}"
          }
        </style>
      </defs>
      <g clipPath="url(#reload-circle_svg__clip-path)">
        <g clipPath="url(#reload-circle_svg__clip-path-2)">
          <g clipPath="url(#reload-circle_svg__clip-path-3)">
            <path
              className="reload-circle_svg__cls-8"
              d="M-3.83-3.86h107.61v107.61H-3.83z"
            />
          </g>
        </g>
      </g>
      <path
        className="reload-circle_svg__cls-8"
        d="M22.33 48.38a28 28 0 0128-23.75l-2-2a2.43 2.43 0 113.43-3.43l6.91 6.9a2.47 2.47 0 01.32 3.75L51.69 37a2.43 2.43 0 11-3.43-3.43l4-4a23.11 23.11 0 00-25.13 19.54 2.43 2.43 0 01-4.8-.73zm36.05 25.76a23.12 23.12 0 01-29.47-12l5.47 1.47a2.43 2.43 0 001.26-4.69l-9.87-2.65a2.47 2.47 0 00-3.08 2.12l-2.54 9.47a2.43 2.43 0 004.69 1.25l.75-2.79a27.62 27.62 0 0011.67 11.19 28 28 0 0022.88 1.15 2.43 2.43 0 00-1.76-4.52zm21.42-6.3a2.41 2.41 0 00-3-1.72l-2.8.75a28 28 0 00-6.58-36.1 2.43 2.43 0 10-3 3.79 23.14 23.14 0 014.33 31.53l-1.47-5.48a2.43 2.43 0 00-4.69 1.26l2.65 9.87a2.45 2.45 0 003.4 1.6l9.44-2.53a2.43 2.43 0 001.72-2.97z"
      />
      <path
        className="reload-circle_svg__cls-9"
        d="M632.44 52.18L658.23 16l1.35 1-26.73 37.25-.79.29zM576.19 6.77H635V31a3.14 3.14 0 002.7 3.13L627 49.07a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.2 3.2 0 001.08 2.94 3.1 3.1 0 003.05.54l6.56-2.46a3.06 3.06 0 00-.65 1.12c-.19.59-.29.89-.17 18.69l-11.1 11.43h-47.67zm-3.1-6.32A3.13 3.13 0 00570 3.61v90.68a3.14 3.14 0 003.1 3.16h52.06a3.14 3.14 0 002.21-.94l12.91-13.3a3.23 3.23 0 00.9-2.24c-.06-8.08-.09-17.47 0-18.51A3.18 3.18 0 00639 59a3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26l29.13-40.44a3.18 3.18 0 00.6-1.83 3.19 3.19 0 00-1.24-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3.14 3.14 0 00-4.83.23l-14 19.58V3.61a3.14 3.14 0 00-3.07-3.16z"
      />
      <path
        className="reload-circle_svg__cls-9"
        d="M581.46 25.75c-1.67 0-3 .95-3 2.11s1.37 2.11 3 2.11H626c1.68 0 3-.95 3-2.11s-1.36-2.11-3-2.11zM581.46 38.4c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11H626c1.68 0 3-.95 3-2.11s-1.36-2.11-3-2.11zM581.46 51.06c-1.67 0-3 .94-3 2.1s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.1-3-2.1zM581.46 67.92c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.11-3-2.11zM20.58 152.9h60s-.14 56.27 0 74.43L69.23 239H20.58zm-3.16-6.45a3.19 3.19 0 00-3.17 3.22v92.55a3.2 3.2 0 003.17 3.23h53.14a3.17 3.17 0 002.25-1L86 230.91a3.32 3.32 0 00.91-2.28c-.06-8.24 0-79 0-79a3.19 3.19 0 00-3.17-3.22z"
      />
      <path
        className="reload-circle_svg__cls-9"
        d="M26 172.27c-1.71 0-3.11 1-3.11 2.15s1.4 2.16 3.11 2.16h45.41c1.71 0 3.11-1 3.11-2.16s-1.4-2.15-3.11-2.15z"
      />
      <g clipPath="url(#reload-circle_svg__clip-path-9)">
        <path
          className="reload-circle_svg__cls-8"
          d="M-2120.7-804.81h6973.04v4317.26H-2120.7z"
        />
      </g>
      <path
        className="reload-circle_svg__cls-9"
        d="M26 198.1c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.8c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
      />
      <g clipPath="url(#reload-circle_svg__clip-path-11)">
        <path
          className="reload-circle_svg__cls-8"
          d="M-2120.7-804.81h6973.04v4317.26H-2120.7z"
        />
      </g>
      <path
        className="reload-circle_svg__cls-9"
        d="M26 215.32c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.8c1.71 0 3.11-1 3.11-2.15s-1.4-2.15-3.11-2.15z"
      />
      <g clipPath="url(#reload-circle_svg__clip-path-12)">
        <path
          className="reload-circle_svg__cls-8"
          d="M-2120.7-804.81h6973.04v4317.26H-2120.7z"
        />
      </g>
      <path
        className="reload-circle_svg__cls-9"
        d="M764.39 38.19a2.2 2.2 0 00-2.15 2.26v42.82a2.15 2.15 0 104.3 0V40.45a2.2 2.2 0 00-2.15-2.26"
      />
      <g clipPath="url(#reload-circle_svg__clip-path-13)">
        <path
          className="reload-circle_svg__cls-8"
          d="M-1643.89-951.81h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="reload-circle_svg__cls-9"
        d="M738.57 38.19a2.21 2.21 0 00-2.16 2.26v42.82a2.16 2.16 0 104.31 0V40.45a2.21 2.21 0 00-2.15-2.26"
      />
    </svg>
  );
}

export default SvgReloadCircle;
