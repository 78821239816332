import React from "react";

function SvgReloadArrow(props : any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#2F2F2F"
        d="M7.073 9.144a.5.5 0 10.865.501l-.865-.501zm3.264-2.066l.16.473-.16-.473zm3.656.124l.192-.462-.192.462zm2.668 2.503l.448-.22-.448.22zm.356 3.641l-.483-.13.483.13zm-2.133 2.973l.278.416-.278-.416zm-3.563.83l.066-.496-.066.495zm-2.85-2.055a.5.5 0 10-.752.66l.752-.66zm1.581-5.191a.5.5 0 000-1v1zm-2.597-.5h-.5a.5.5 0 00.5.5v-.5zm.5-2.597a.5.5 0 10-1 0h1zM22.5 12c0 5.799-4.701 10.5-10.5 10.5v1c6.351 0 11.5-5.149 11.5-11.5h-1zM12 22.5C6.201 22.5 1.5 17.799 1.5 12h-1c0 6.351 5.149 11.5 11.5 11.5v-1zM1.5 12C1.5 6.201 6.201 1.5 12 1.5v-1C5.649.5.5 5.649.5 12h1zM12 1.5c5.799 0 10.5 4.701 10.5 10.5h1C23.5 5.649 18.351.5 12 .5v1zM7.938 9.645a4.694 4.694 0 012.559-2.094l-.32-.947a5.694 5.694 0 00-3.104 2.54l.865.501zm2.559-2.094a4.694 4.694 0 013.304.112l.384-.923a5.694 5.694 0 00-4.008-.136l.32.947zm3.304.112a4.695 4.695 0 012.411 2.263l.897-.442a5.694 5.694 0 00-2.924-2.744l-.384.923zm2.411 2.263a4.694 4.694 0 01.322 3.29l.966.26a5.694 5.694 0 00-.39-3.992l-.898.442zm.322 3.29a4.695 4.695 0 01-1.927 2.687l.555.832a5.694 5.694 0 002.338-3.26l-.966-.259zm-1.927 2.687a4.695 4.695 0 01-3.22.75l-.131.991a5.694 5.694 0 003.906-.91l-.555-.831zm-3.22.75a4.694 4.694 0 01-2.916-1.559l-.752.66a5.694 5.694 0 003.537 1.89l.13-.991zm-1.335-7.75H7.455v1h2.597v-1zm-2.097.5V6.806h-1v2.597h1z"
      ></path>
    </svg>
  );
}

export default SvgReloadArrow;
