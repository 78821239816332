declare global {
  interface Window {
    _paq: any;
  }
}

export default class MatamoHelper {
  static trackEvent(data: any[]) {
    const _paq: any = (window._paq = window._paq || []);
    data.unshift("trackEvent");
    _paq.push(data);
  }
}
