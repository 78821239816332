export function checkDefaultValue(value: string): string {
  const values = ["#??", "Inconnu", "#NA", "Non applicable", "1900 / 9999", "31/12/9999", "#?? Inconnu", "#NA Non applicable"];
  if (values.includes(value)) return "";
  return value;
}

export const normalizeText = (text: any): string => {
  return text && text !== ""
    ? text
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    : "";
};
