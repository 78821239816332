import * as React from "react";

function SvgUserCogs(props: any) {
  return (
    <svg
      id="user-cogs_svg__Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="user-cogs_svg__clip-path">
          <path
            className="user-cogs_svg__cls-1"
            d="M352.25-93.32L378-129.47l1.35 1.05-26.69 37.17-.79.29zM296-138.73h58.77v24.21a3.14 3.14 0 002.7 3.13l-10.67 15a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.18 3.18 0 001.11 2.9 3.09 3.09 0 003 .54l6.55-2.46a3.18 3.18 0 00-.64 1.12c-.2.59-.3.89-.17 18.69l-11.1 11.43H296zm-3.1-6.27a3.14 3.14 0 00-3.11 3.16v90.68A3.14 3.14 0 00292.9-48H345a3.12 3.12 0 002.2-.94l12.92-13.3a3.22 3.22 0 00.89-2.24c-.06-8.08-.09-17.47 0-18.51a3.16 3.16 0 00-2.09-3.45 3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26l29.02-40.5a3.09 3.09 0 00.59-1.83v-.05a3.15 3.15 0 00-1.23-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3 3 0 00-2.46-1 3.06 3.06 0 00-2.37 1.25L361-116.3v-25.59a3.13 3.13 0 00-3.1-3.16z"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-2">
          <path
            className="user-cogs_svg__cls-1"
            d="M301.27-119.75c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h44.53c1.67 0 3-1 3-2.11s-1.37-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-3">
          <path
            className="user-cogs_svg__cls-1"
            d="M301.27-107.1c-1.68 0-3 .95-3 2.11s1.36 2.11 3 2.11h44.53c1.67 0 3-1 3-2.11s-1.37-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-4">
          <path
            className="user-cogs_svg__cls-1"
            d="M301.27-94.44c-1.68 0-3 .94-3 2.1s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.1-3-2.1z"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-5">
          <path
            className="user-cogs_svg__cls-1"
            d="M301.27-77.58c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-6">
          <path
            className="user-cogs_svg__cls-1"
            d="M-254.23 26.77c-1.71 0-3.1 1-3.1 2.15s1.39 2.16 3.1 2.16h45.45c1.71 0 3.1-1 3.1-2.16s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-7">
          <path
            className="user-cogs_svg__cls-2"
            d="M-254.23 39.69c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h45.45c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-8">
          <path
            className="user-cogs_svg__cls-1"
            d="M-254.23 52.6c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-9">
          <path
            className="user-cogs_svg__cls-1"
            d="M-254.23 69.82c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-10">
          <path
            className="user-cogs_svg__cls-1"
            d="M484.2-107.31a2.2 2.2 0 00-2.15 2.26v42.82A2.2 2.2 0 00484.2-60a2.2 2.2 0 002.15-2.26V-105a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-11">
          <path
            className="user-cogs_svg__cls-1"
            d="M458.37-107.31a2.2 2.2 0 00-2.15 2.26v42.82a2.2 2.2 0 002.15 2.23 2.2 2.2 0 002.15-2.26V-105a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="user-cogs_svg__clip-path-12">
          <path
            className="user-cogs_svg__cls-2"
            d="M471.29-107.31a2.21 2.21 0 00-2.16 2.26v42.82a2.21 2.21 0 002.16 2.23 2.21 2.21 0 002.15-2.26V-105a2.21 2.21 0 00-2.15-2.26"
          />
        </clipPath>
        <style>
          {
            ".user-cogs_svg__cls-1,.user-cogs_svg__cls-10,.user-cogs_svg__cls-3{fill:#333}.user-cogs_svg__cls-1,.user-cogs_svg__cls-2{clip-rule:evenodd}.user-cogs_svg__cls-2{fill:none}.user-cogs_svg__cls-3{fill-rule:evenodd}"
          }
        </style>
      </defs>
      <path
        className="user-cogs_svg__cls-3"
        d="M352.25-93.32L378-129.47l1.35 1.05-26.69 37.17-.79.29zM296-138.73h58.77v24.21a3.14 3.14 0 002.7 3.13l-10.67 15a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.18 3.18 0 001.11 2.9 3.09 3.09 0 003 .54l6.55-2.46a3.18 3.18 0 00-.64 1.12c-.2.59-.3.89-.17 18.69l-11.1 11.43H296zm-3.1-6.27a3.14 3.14 0 00-3.11 3.16v90.68A3.14 3.14 0 00292.9-48H345a3.12 3.12 0 002.2-.94l12.92-13.3a3.22 3.22 0 00.89-2.24c-.06-8.08-.09-17.47 0-18.51a3.16 3.16 0 00-2.09-3.45 3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26l29.02-40.5a3.09 3.09 0 00.59-1.83v-.05a3.15 3.15 0 00-1.23-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3 3 0 00-2.46-1 3.06 3.06 0 00-2.37 1.25L361-116.3v-25.59a3.13 3.13 0 00-3.1-3.16z"
      />
      <path
        className="user-cogs_svg__cls-3"
        d="M301.27-119.75c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h44.53c1.67 0 3-1 3-2.11s-1.37-2.11-3-2.11zM301.27-107.1c-1.68 0-3 .95-3 2.11s1.36 2.11 3 2.11h44.53c1.67 0 3-1 3-2.11s-1.37-2.11-3-2.11zM301.27-94.44c-1.68 0-3 .94-3 2.1s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.1-3-2.1zM301.27-77.58c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.11-3-2.11zM-259.61 7.4h60s-.14 56.27 0 74.43L-211 93.5h-48.65zm-3.16-6.4a3.19 3.19 0 00-3.17 3.22v92.5a3.2 3.2 0 003.17 3.23h53.13a3.15 3.15 0 002.25-1l13.18-13.58a3.28 3.28 0 00.92-2.28c-.06-8.24 0-79 0-79A3.19 3.19 0 00-196.46 1z"
      />
      <path
        className="user-cogs_svg__cls-3"
        d="M-254.23 26.77c-1.71 0-3.1 1-3.1 2.15s1.39 2.16 3.1 2.16h45.45c1.71 0 3.1-1 3.1-2.16s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#user-cogs_svg__clip-path-6)">
        <path
          className="user-cogs_svg__cls-10"
          d="M-2400.9-950.31h6973.04v4317.26H-2400.9z"
        />
      </g>
      <path
        className="user-cogs_svg__cls-3"
        d="M-254.23 52.6c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#user-cogs_svg__clip-path-8)">
        <path
          className="user-cogs_svg__cls-10"
          d="M-2400.9-950.31h6973.04v4317.26H-2400.9z"
        />
      </g>
      <path
        className="user-cogs_svg__cls-3"
        d="M-254.23 69.82c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#user-cogs_svg__clip-path-9)">
        <path
          className="user-cogs_svg__cls-10"
          d="M-2400.9-950.31h6973.04v4317.26H-2400.9z"
        />
      </g>
      <path
        className="user-cogs_svg__cls-3"
        d="M484.2-107.31a2.2 2.2 0 00-2.15 2.26v42.82A2.2 2.2 0 00484.2-60a2.2 2.2 0 002.15-2.26V-105a2.2 2.2 0 00-2.15-2.26"
      />
      <g clipPath="url(#user-cogs_svg__clip-path-10)">
        <path
          className="user-cogs_svg__cls-10"
          d="M-1924.08-1097.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="user-cogs_svg__cls-3"
        d="M458.37-107.31a2.2 2.2 0 00-2.15 2.26v42.82a2.2 2.2 0 002.15 2.23 2.2 2.2 0 002.15-2.26V-105a2.2 2.2 0 00-2.15-2.26"
      />
      <path
        className="user-cogs_svg__cls-10"
        d="M39.19 41.66a20.73 20.73 0 1120.72-20.73 20.75 20.75 0 01-20.72 20.73zm0-37.31a16.58 16.58 0 1016.58 16.58A16.6 16.6 0 0039.19 4.35zM37.12 87.25H4a2.08 2.08 0 01-2.08-2.07V68.6A18.68 18.68 0 0120.54 50h24.87a2.07 2.07 0 010 4.14H20.54A14.53 14.53 0 006 68.6v14.51h31.12a2.07 2.07 0 110 4.14zM72.35 83.11a10.37 10.37 0 1110.36-10.37 10.38 10.38 0 01-10.36 10.37zm0-16.58a6.22 6.22 0 106.22 6.21 6.22 6.22 0 00-6.22-6.21z"
      />
      <path
        className="user-cogs_svg__cls-10"
        d="M75.65 99.69H69A4.13 4.13 0 0164.92 96l-.53-5.27a19.59 19.59 0 01-3.59-2.13L56 90.78a4.11 4.11 0 01-5.29-1.71l-3.3-5.72a4.13 4.13 0 011.17-5.44l4.26-3.06a15.9 15.9 0 010-4.21l-4.26-3.07a4.12 4.12 0 01-1.17-5.43l3.3-5.73A4.14 4.14 0 0156 54.7l4.84 2.19a19.64 19.64 0 013.59-2.09l.53-5.26A4.12 4.12 0 0169 45.81h6.61a4.14 4.14 0 014.13 3.73l.52 5.27a19.83 19.83 0 013.59 2.08l4.84-2.18A4.13 4.13 0 0194 56.42l3.31 5.72a4.15 4.15 0 01-1.17 5.44l-4.27 3.07a15.82 15.82 0 010 4.2l4.27 3.07a4.14 4.14 0 011.17 5.43L94 89.07a4.13 4.13 0 01-5.3 1.71l-4.81-2.18a19.51 19.51 0 01-3.58 2.08L79.78 96a4.15 4.15 0 01-4.13 3.73zM61.09 84.12a2.11 2.11 0 011.34.49A15.53 15.53 0 0067 87.29 2.08 2.08 0 0168.39 89l.61 6.54h6.62l.69-6.54a2.07 2.07 0 011.35-1.74 15.66 15.66 0 004.61-2.68 2.06 2.06 0 012.18-.3l6 2.7 3.3-5.72-5.3-3.81a2.1 2.1 0 01-.83-2 15.93 15.93 0 00.28-2.69 15.7 15.7 0 00-.28-2.68 2.09 2.09 0 01.83-2l5.3-3.8-3.3-5.73-6 2.7a2.06 2.06 0 01-2.18-.3 15.46 15.46 0 00-4.62-2.68 2.08 2.08 0 01-1.35-1.74L75.65 50H69l-.65 6.51A2.08 2.08 0 0167 58.2a15.32 15.32 0 00-4.61 2.68 2.06 2.06 0 01-2.18.3l-6-2.7L51 64.2l5.25 3.8a2.08 2.08 0 01.84 2 13 13 0 000 5.37 2.08 2.08 0 01-.84 2L51 81.27 54.26 87l6-2.69a2 2 0 01.84-.18z"
      />
    </svg>
  );
}

export default SvgUserCogs;
