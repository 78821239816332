import * as React from "react";

function SvgUserCircle(props: any) {
  return (
    <svg
      id="user-circle_svg__Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="user-circle_svg__clip-path">
          <path className="user-circle_svg__cls-1" d="M.61.62h99v99h-99z" />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-2">
          <path
            className="user-circle_svg__cls-1"
            d="M.61.62h103.3v103.3H.61z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-3">
          <path
            className="user-circle_svg__cls-3"
            d="M50.12.62a49.5 49.5 0 1049.49 49.5A49.54 49.54 0 0050.12.62zm0 5a44.55 44.55 0 11-44.56 44.5A44.5 44.5 0 0150.12 5.57z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-4">
          <path className="user-circle_svg__cls-4" d="M.61.62h99v99h-99z" />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-5">
          <path
            className="user-circle_svg__cls-1"
            d="M24.81 19.37h51.47v58.57H24.81z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-6">
          <path
            className="user-circle_svg__cls-1"
            d="M22.01 19.37H80.3v62.78H22.01z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-7">
          <path
            d="M65.66 47.57a22.35 22.35 0 001.71-8.67 21.32 21.32 0 00-4.72-13.65 15 15 0 00-23.79 0 21.38 21.38 0 00-4.72 13.65 22.54 22.54 0 001.69 8.63 13.29 13.29 0 00-7.33 4.92 15.93 15.93 0 00-3.69 9.3v13.94a2.27 2.27 0 004.53 0V61.75A11.62 11.62 0 0132 55.27c1.8-2.2 4-3.47 6-3.47h.28c.2.27.4.52.61.78a15 15 0 0023.79 0c.27-.33.53-.66.78-1a2.52 2.52 0 00.33.14A11.78 11.78 0 0170.06 56a10 10 0 011.7 5.74v13.95a2.26 2.26 0 004.52 0V61.75c0-6.66-3.86-11.8-10.62-14.18m-14.9 6.35c-6.67 0-12.09-6.73-12.09-15s5.42-15 12.09-15 12.08 6.73 12.08 15-5.42 15-12.08 15"
            fill="#408c7a"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-8">
          <path
            className="user-circle_svg__cls-6"
            d="M22.01 19.37H80.3v62.78H22.01z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-9">
          <path
            className="user-circle_svg__cls-2"
            d="M352.25 52.68L378 16.53l1.35 1-26.69 37.22-.79.29zM296 7.27h58.77v24.21a3.14 3.14 0 002.7 3.13l-10.67 15a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.18 3.18 0 001.11 2.9 3.09 3.09 0 003 .54l6.55-2.46a3.18 3.18 0 00-.64 1.12c-.2.59-.3.89-.17 18.69l-11.1 11.43H296zM292.9 1a3.14 3.14 0 00-3.11 3.16v90.63A3.14 3.14 0 00292.9 98H345a3.12 3.12 0 002.2-.94l12.92-13.3a3.22 3.22 0 00.89-2.24c-.06-8.08-.09-17.47 0-18.51a3.16 3.16 0 00-2.09-3.45 3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26l29.02-40.5a3.09 3.09 0 00.59-1.83 3.15 3.15 0 00-1.23-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3.14 3.14 0 00-4.83.23L361 29.7V4.11A3.13 3.13 0 00357.87 1z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-10">
          <path
            className="user-circle_svg__cls-2"
            d="M301.27 26.25c-1.68 0-3 .95-3 2.11s1.36 2.11 3 2.11h44.53c1.67 0 3-.95 3-2.11s-1.37-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-11">
          <path
            className="user-circle_svg__cls-2"
            d="M301.27 38.9c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h44.53c1.67 0 3-.95 3-2.11s-1.37-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-12">
          <path
            className="user-circle_svg__cls-2"
            d="M301.27 51.56c-1.68 0-3 .94-3 2.1s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.1-3-2.1z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-13">
          <path
            className="user-circle_svg__cls-2"
            d="M301.27 68.42c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-14">
          <path
            className="user-circle_svg__cls-2"
            d="M-254.23 172.77c-1.71 0-3.1 1-3.1 2.15s1.39 2.16 3.1 2.16h45.45c1.71 0 3.1-1 3.1-2.16s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-15">
          <path
            className="user-circle_svg__cls-3"
            d="M-254.23 185.69c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h45.45c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-16">
          <path
            className="user-circle_svg__cls-2"
            d="M-254.23 198.6c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-17">
          <path
            className="user-circle_svg__cls-2"
            d="M-254.23 215.82c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-18">
          <path
            className="user-circle_svg__cls-2"
            d="M484.2 38.69a2.2 2.2 0 00-2.15 2.31v42.77a2.15 2.15 0 104.3 0V41a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-19">
          <path
            className="user-circle_svg__cls-2"
            d="M458.37 38.69a2.2 2.2 0 00-2.15 2.31v42.77a2.15 2.15 0 104.3 0V41a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="user-circle_svg__clip-path-20">
          <path
            className="user-circle_svg__cls-3"
            d="M471.29 38.69a2.21 2.21 0 00-2.16 2.31v42.77a2.16 2.16 0 104.31 0V41a2.21 2.21 0 00-2.15-2.26"
          />
        </clipPath>
        <linearGradient
          id="user-circle_svg__Nouvelle_nuance_de_d\xE9grad\xE9_1"
          x1={0.61}
          y1={50.12}
          x2={99.61}
          y2={50.12}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#187687" />
          <stop offset={1} stopColor="#1d96a3" />
        </linearGradient>
        <style>
          {
            ".user-circle_svg__cls-1,.user-circle_svg__cls-3{fill:none}.user-circle_svg__cls-17,.user-circle_svg__cls-2,.user-circle_svg__cls-24{fill:#333}.user-circle_svg__cls-2,.user-circle_svg__cls-3{clip-rule:evenodd}.user-circle_svg__cls-4{fill:url(#user-circle_svg__Nouvelle_nuance_de_d\xE9grad\xE9_1)}.user-circle_svg__cls-6{fill:#408c7a}.user-circle_svg__cls-8{isolation:isolate}.user-circle_svg__cls-17{fill-rule:evenodd}"
          }
        </style>
      </defs>
      <g clipPath="url(#user-circle_svg__clip-path)">
        <g clipPath="url(#user-circle_svg__clip-path-2)">
          <g clipPath="url(#user-circle_svg__clip-path-3)">
            <path className="user-circle_svg__cls-4" d="M.61.62h99v99h-99z" />
            <g clipPath="url(#user-circle_svg__clip-path-4)">
              <path
                className="user-circle_svg__cls-6"
                d="M-2.72-2.71h105.66v105.66H-2.72z"
              />
            </g>
          </g>
        </g>
      </g>
      <g clipPath="url(#user-circle_svg__clip-path-5)">
        <g clipPath="url(#user-circle_svg__clip-path-6)">
          <path
            d="M65.66 47.57a22.35 22.35 0 001.71-8.67 21.32 21.32 0 00-4.72-13.65 15 15 0 00-23.79 0 21.38 21.38 0 00-4.72 13.65 22.54 22.54 0 001.69 8.63 13.29 13.29 0 00-7.33 4.92 15.93 15.93 0 00-3.69 9.3v13.94a2.27 2.27 0 004.53 0V61.75A11.62 11.62 0 0132 55.27c1.8-2.2 4-3.47 6-3.47h.28c.2.27.4.52.61.78a15 15 0 0023.79 0c.27-.33.53-.66.78-1a2.52 2.52 0 00.33.14A11.78 11.78 0 0170.06 56a10 10 0 011.7 5.74v13.95a2.26 2.26 0 004.52 0V61.75c0-6.66-3.86-11.8-10.62-14.18m-14.9 6.35c-6.67 0-12.09-6.73-12.09-15s5.42-15 12.09-15 12.08 6.73 12.08 15-5.42 15-12.08 15"
            fillRule="evenodd"
            fill="#408c7a"
          />
          <g clipPath="url(#user-circle_svg__clip-path-7)">
            <path
              className="user-circle_svg__cls-6"
              d="M22.01 19.37H80.3v62.78H22.01z"
            />
            <g clipPath="url(#user-circle_svg__clip-path-8)">
              <path
                className="user-circle_svg__cls-6"
                d="M20.33 14.89h60.44v67.54H20.33z"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        className="user-circle_svg__cls-17"
        d="M352.25 52.68L378 16.53l1.35 1-26.69 37.22-.79.29zM296 7.27h58.77v24.21a3.14 3.14 0 002.7 3.13l-10.67 15a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.18 3.18 0 001.11 2.9 3.09 3.09 0 003 .54l6.55-2.46a3.18 3.18 0 00-.64 1.12c-.2.59-.3.89-.17 18.69l-11.1 11.43H296zM292.9 1a3.14 3.14 0 00-3.11 3.16v90.63A3.14 3.14 0 00292.9 98H345a3.12 3.12 0 002.2-.94l12.92-13.3a3.22 3.22 0 00.89-2.24c-.06-8.08-.09-17.47 0-18.51a3.16 3.16 0 00-2.09-3.45 3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26l29.02-40.5a3.09 3.09 0 00.59-1.83 3.15 3.15 0 00-1.23-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3.14 3.14 0 00-4.83.23L361 29.7V4.11A3.13 3.13 0 00357.87 1z"
      />
      <path
        className="user-circle_svg__cls-17"
        d="M301.27 26.25c-1.68 0-3 .95-3 2.11s1.36 2.11 3 2.11h44.53c1.67 0 3-.95 3-2.11s-1.37-2.11-3-2.11zM301.27 38.9c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h44.53c1.67 0 3-.95 3-2.11s-1.37-2.11-3-2.11zM301.27 51.56c-1.68 0-3 .94-3 2.1s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.1-3-2.1zM301.27 68.42c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h36.09c1.68 0 3-.94 3-2.11s-1.36-2.11-3-2.11zM-259.61 153.4h60s-.14 56.27 0 74.43L-211 239.5h-48.65zm-3.16-6.45a3.19 3.19 0 00-3.17 3.22v92.55a3.2 3.2 0 003.17 3.23h53.13a3.15 3.15 0 002.25-1l13.18-13.58a3.28 3.28 0 00.92-2.28c-.06-8.24 0-79 0-79a3.19 3.19 0 00-3.17-3.22z"
      />
      <path
        className="user-circle_svg__cls-17"
        d="M-254.23 172.77c-1.71 0-3.1 1-3.1 2.15s1.39 2.16 3.1 2.16h45.45c1.71 0 3.1-1 3.1-2.16s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#user-circle_svg__clip-path-14)">
        <path
          className="user-circle_svg__cls-24"
          d="M-2400.9-804.31h6973.04v4317.26H-2400.9z"
        />
      </g>
      <path
        className="user-circle_svg__cls-17"
        d="M-254.23 198.6c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#user-circle_svg__clip-path-16)">
        <path
          className="user-circle_svg__cls-24"
          d="M-2400.9-804.31h6973.04v4317.26H-2400.9z"
        />
      </g>
      <path
        className="user-circle_svg__cls-17"
        d="M-254.23 215.82c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#user-circle_svg__clip-path-17)">
        <path
          className="user-circle_svg__cls-24"
          d="M-2400.9-804.31h6973.04v4317.26H-2400.9z"
        />
      </g>
      <path
        className="user-circle_svg__cls-17"
        d="M484.2 38.69a2.2 2.2 0 00-2.15 2.31v42.77a2.15 2.15 0 104.3 0V41a2.2 2.2 0 00-2.15-2.26"
      />
      <g clipPath="url(#user-circle_svg__clip-path-18)">
        <path
          className="user-circle_svg__cls-24"
          d="M-1924.08-951.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="user-circle_svg__cls-17"
        d="M458.37 38.69a2.2 2.2 0 00-2.15 2.31v42.77a2.15 2.15 0 104.3 0V41a2.2 2.2 0 00-2.15-2.26"
      />
    </svg>
  );
}

export default SvgUserCircle;
