import { UserType } from "../Utils/enum";

class StorageManagerService {
  Get(name: string): any {
    const value = localStorage.getItem(name);
    return value !== null ? JSON.parse(value) : null;
  }

  Set(name: string, value: any): void {
    localStorage.setItem(name, JSON.stringify(value));
  }

  IsSet(name: string): boolean {
    return localStorage[name];
  }

  Delete(name: string): void {
    localStorage.removeItem(name);
  }

  GetPerimater(): any {
    const user = this.Get("current");
    return user !== null ? user?.representants : [];
  }

  GetUserPermission(): any {
    const user = this.Get("current");
    return user?.permission ? user.permission : [];
  }

  GetUserRole(): string {
    const user = this.Get("current");
    return user !== null ? user?.role : "";
  }

  isTft(): boolean {
    const user = this.Get("current");
    return user !== null ? user?.tft : false;
  }

  isAdmin(): boolean {
    const user = this.Get("current");
    return user !== null ? user?.admin : false;
  }
  getCurrentId(): string {
    const user = this.Get("current");
    return user !== null ? user?.id : "";
  }
  getCurrentFirstName(): string {
    const user = this.Get("current");
    return user !== null ? user?.prenom : "";
  }
  getCurrentLastName(): string {
    const user = this.Get("current");
    return user !== null ? user?.nom : "";
  }
  getCurrentCGU(): boolean {
    const user = this.Get("current");
    return user !== null ? user?.cgu : false;
  }
  getCurrentOrigin(): string {
    const user = this.Get("current");
    return user !== null ? user?.codeOrigine : "";
  }
  getCurrentPremCnx(): boolean {
    const user = this.Get("current");
    return user !== null ? user?.premCnx : false;
  }

  isInternalUser(id?: string): boolean {
    const userId: string = id || this.getCurrentId();
    return userId.includes(UserType.INT);
  }

  getRepresentantId(): string {
    const user = this.Get("current");
    return user?.representants[0];
  }

  public hasPermission(permissions: Array<string>): boolean {
    const userPermissions: any[] = this.GetUserPermission();
    for (let i = 0; i < permissions.length; i++) {
      if (userPermissions.includes(permissions[i])) {
        return true;
      }
    }
    return false;
  }
};

export default new StorageManagerService();
