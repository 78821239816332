import React, { useCallback, useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Form, Spinner } from "react-bootstrap";
import { UtilisateurService } from "../../Services";

import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import "./SignUp.scss";
import Loading from "../Loading/Loading";
import logoOnf from "../../assets/logo/logo-onf.svg";
import logoFeedback from "../../images/check.png";
import { CTooltip, SelectField } from "../Components";
import { CGUModal } from "./CGUModal";
import SvgEye from "../Components/SVG/SvgEye";
import { EventHelper } from "../../Utils";
import CheckboxField from "../Components/CheckboxField/CheckboxField";
import { SITE } from "../../Utils/Constants/Constants";
import CHeaderForm from "../Components/CHeaderForm/CHeaderForm";
import Feedback from "../Feedback/Feedback";

const TITLE = "Formulaire de demande d'inscription";

const SignUp = () => {
  const emailToken: any = new URLSearchParams(window.location.search).get(
    "token"
  );

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formikRef = useRef(null as any);
  //const [captchaResponse, setCaptchaResponse] = useState<any>(null);
  const [modeContactPriv, setModeContactPriv] = useState<any>();
  const [emailSuccess, setEmailSuccess] = useState<string>();


  const [user, setUser] = useState<any>({
    id: "",
    nom: "",
    prenom: "",
    service: "",
    fonction: "",
  });

  /* const handleReCaptchaVerify = useCallback(() => {
    if (scriptLoaded) {
      window.grecaptcha &&
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(captchaKey, { action: "signup" })
            .then((token: any) => {
              UtilisateurService.verifyRecaptchaResponse(token).then(
                (res: any) => {
                  if (res.isSuccess()) {
                    setCaptchaResponse(res?.value?.data);
                  }
                }
              );
            });
        });
    }
  }, [captchaKey, scriptLoaded]); */

  /* useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify, scriptLoaded]); */

  useEffect(() => {
    setLoading(true);
    UtilisateurService.getAInscrireById(emailToken).then((res: any) => {
      if (res.isSuccess()) {
        const data: any = res?.value?.data;
        setUser({ ...data });
        UtilisateurService.getAllModeContactPriviligier().then((res: any) => {
          let list: any[] = [];
          res?.value?.data.map((mode: any) => {
            list.push({ value: mode.id, label: mode.modeLabel });
          });
          setModeContactPriv(list);
        });
      }
      setLoading(false);
    });
  }, [emailToken]);

  const updateUser = (values: any, setErrors: any) => {
    //if (captchaResponse) {
      setIsLoading(true);
      UtilisateurService.inscrire({
        email: values.email,
        telephone: values.telephone,
        telephoneMobile: values.telephoneMobile,
        siteWeb: values.siteWeb,
        modeContactPriviligie: values.modeContactPriviligie,
        horairesRepresentant: values.horairesRepresentant,
        horairesRespRepresentant: values.horairesRespRepresentant,
        token: emailToken,
      }).then((res: any) => {
        if (res?.isSuccess()) {
          setSuccess(true);
          setEmailSuccess(values.email);
        } else if (res.error.data.code === "VALIDATION_EMAIL_EXISTANT") {
          setErrors({email: "Ce mail est déjà enregistré pour une autre collectivité, veuillez renseigner un mail nominatif dédié à votre rôle de représentant pour cette collectivité."})
        }
        setIsLoading(false);
      });
  };

  const handleShowModal = (e: any) => {
    EventHelper.stopPropagation(e);
    setShow(true);
  };

  const emailAlert: any = (
    <div className="email-tooltip">
      <SvgEye className="svg-eye" />{" "}
      <div>
        Veuillez renseigner un mail <b>nominatif</b> pour nous aider à sécuriser
        notre base de données
      </div>
    </div>
  );

  if (loading) return <Loading />;

  return (
    <>
      <CHeaderForm />
      {!success ? (
        <div className="signup">
          <div className="signup-form">
            <Formik
              innerRef={formikRef}
              enableReinitialize
              validateOnMount
              initialValues={{
                ...user,
                email: "",
                siteWeb: "",
                horairesRepresentant: "",
                horairesRespRepresentant: "",
                //captchaResponse: captchaResponse,
                conditions: false,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Le format du mail est invalide")
                  .required("Le mail est obligatoire"),
                conditions: Yup.bool().oneOf(
                  [true],
                  "Les termes et conditions générales sont obligatoires"
                ),
                modeContactPriviligie: Yup.string()
                  .required("Le mode de contact privilégié est obligatoire")
                  .typeError("Le mode de contact privilégié est obligatoire"),
              })}
              onSubmit={(values, {setErrors}) => {
                updateUser(values, setErrors);
              }}
              validateOnBlur
            >
              {({ errors, touched, handleSubmit, setFieldValue }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <CGUModal
                      showProps={show}
                      setShowProps={setShow}
                      setFieldValue={setFieldValue}
                    />
                    <div className="logo">
                      <img src={logoOnf} alt="logo" />
                    </div>
                    <hr />
                    <div className="title title-header">
                      PORTAIL DES COLLECTIVITES
                    </div>
                    <div className="title">{TITLE}</div>

                    <fieldset className="border p-2 mb-3">
                      <legend
                        className="w-auto border bg-light rounded pl-4 pr-4 pt-1 pb-1"
                        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                      >
                        RESPONSABLE{" "}
                        <strong>{user?.commune?.toUpperCase()}</strong>
                      </legend>
                      <div className="row">
                        <div className="col">
                          <Form.Label className="font-weight-bold">
                            Nom
                          </Form.Label>
                          <br />
                          {user?.nom}
                        </div>
                        <div className="col">
                          <Form.Label className="font-weight-bold">
                            Prénom
                          </Form.Label>
                          <br />
                          {user?.prenom}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Form.Label>
                            <span className="font-weight-bold">
                              Horaires d'ouverture
                            </span>
                          </Form.Label>
                          <Field
                            className={`form-control ${
                              touched.definition &&
                              !!errors.definition &&
                              "is-invalid"
                            }`}
                            as="textarea"
                            name="horairesRepresentant"
                            rows={3}
                          />
                        </div>
                        <div className="col">
                          <Form.Label>
                            <span className="font-weight-bold">Site web</span>
                          </Form.Label>
                          <Field as={Form.Control} name="siteWeb" type="text" />
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="border p-2">
                      <legend
                        className="w-auto border bg-light rounded pl-4 pr-4 pt-1 pb-1"
                        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                      >
                        VOS INFORMATIONS
                      </legend>
                      <div className="row">
                        <div className="col">
                          <Form.Label>
                            <span className="font-weight-bold">Mail nominatif</span>{" "}
                            <span className="text-muted">(obligatoire)</span>
                          </Form.Label>
                          <CTooltip
                            id="userTuto"
                            value={emailAlert}
                            placement="top"
                          >
                            <Field
                              as={Form.Control}
                              name="email"
                              type="text"
                              placeholder="nom.prenom@email.fr"
                              isInvalid={touched.email && !!errors.email}
                            />
                          </CTooltip>
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <Form.Label>
                            <span className="font-weight-bold">Fonction</span>{" "}
                          </Form.Label>
                          <br />
                          {user?.titre}
                        </div>
                        <div className="col">
                          <Form.Label>
                            <span className="font-weight-bold">Service</span>{" "}
                          </Form.Label>
                          <br />
                          {user?.service}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Form.Label className="font-weight-bold">
                            Téléphone mobile{" "}
                            {/* <span className="text-muted">(obligatoire)</span> */}
                          </Form.Label>
                          <Field
                            as={Form.Control}
                            name="telephoneMobile"
                            type="text"
                          />
                        </div>
                        <div className="col">
                          <Form.Label className="font-weight-bold">
                            Téléphone fixe{" "}
                            {/* <span className="text-muted">(obligatoire)</span> */}
                          </Form.Label>
                          <Field
                            as={Form.Control}
                            name="telephone"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Form.Label>
                            <span className="font-weight-bold">
                              Mode de contact privilégié
                            </span>
                            <span className="text-muted"> (obligatoire)</span>
                          </Form.Label>
                          <Field
                            name="modeContactPriviligie"
                            component={SelectField}
                            options={modeContactPriv}
                          />
                          <ErrorMessage
                            name="modeContactPriviligie"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="col">
                          <Form.Label>
                            <span className="font-weight-bold">
                              Horaires privilégiés
                            </span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            as="textarea"
                            name="horairesRespRepresentant"
                            rows={3}
                          />
                        </div>
                      </div>

                      <div className="row my-4" onClick={handleShowModal}>
                        <div className="col">
                          <Field
                            id="checkbox-conditions"
                            className="d-block"
                            type="checkbox"
                            name="conditions"
                            component={CheckboxField}
                            label="Accepter les termes et conditions générales"
                          />
                          <ErrorMessage
                            name="conditions"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col">
                          En cas de difficulté, vous pouvez contacter le support
                          du portail au{" "}
                          <a href={`tel:${SITE.contact_href}`}>
                            {SITE.contact}
                          </a>
                          .
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="actions">
                            <Button
                              type="submit"
                              className="btn btn-success"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />{" "}
                                  &nbsp;
                                </>
                              ) : null}
                              Enregistrer
                            </Button>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="signup-feedback">
          <Feedback>
            <br />
            <div>
              Un mail vous permettant d’initialiser votre mot de passe vient de vous être envoyé à l’adresse suivante : <span className="email-success"> {emailSuccess} </span> <br/>
              Franchissez cette dernière étape pour compléter votre inscription. <br/>
              NB : <span className="email-success">{emailSuccess}</span> est votre identifiant de connexion au portail des collectivités.
            </div>
            <img src={logoFeedback} alt="logo" />
            <div className="text-success mb-3">Envoi confirmé</div>
          </Feedback>
        </div>
      )}
    </>
  );
};

export default SignUp;
