import React from "react";
import { FormCheck } from "react-bootstrap";
import './CheckboxField.scss';

const CheckboxField: React.FC<any> = ({
  field,
  form,
  ...rest
}) => {
  return (
    <div className="CheckboxField">
      <FormCheck
        name={field.name}
        checked={field.value}
        isInvalid={
          form.touched[field.name] && !!form.errors[field.name]
        }
        onChange={(e: any) =>
          form.setFieldValue(field.name, e?.target?.checked)
        }
        {...rest}
      />
    </div>
  );
};

export default CheckboxField;
