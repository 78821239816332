import React, {useState} from "react";
import {Button, Modal, Spinner, Form} from "react-bootstrap";
import {X} from "react-bootstrap-icons";
import "./ConfirmationModal.scss";

export interface Props {
  showConfirmModal: boolean;
  role?: string;
  validationComptes?: number;
  loading?: boolean;
  isMailNominatif?: boolean;
  deleteMessageParam?: boolean;
  email?:string;
  setShowConfirmModal: Function;
  setIsMailNominatif?: Function;
  action: () => void;
  count?: number;
  loadingText?: boolean;
}

export const ConfirmationModal: React.FC<Props> = ({
  showConfirmModal,
  setShowConfirmModal,
  action,
  role,
  validationComptes,
  loading,
  isMailNominatif,
  setIsMailNominatif,
  email,
  deleteMessageParam,
  count,
  loadingText,
}) => {
  const handleClose = () => {
    setShowConfirmModal(false);
  };


  const [checkedMailGeneric,setCheckedMailGenric]=useState("");
  return (
    <Modal centered show={showConfirmModal} onHide={handleClose} className={"confirm-modal-block"} backdropClassName={"confirm-modal-backdrop"}>
      <div
        className={`modal-header ${
          (validationComptes === 2 || validationComptes === 4) && "bg-danger"
        }`}
      >
        <X size="40" onClick={handleClose} />
      </div>

      <div className="text-center mt-4">
        {!role && !validationComptes && !deleteMessageParam && <h5>Confirmez-vous cette action ?</h5>}
        {role && (
          <>
            <h5>Voulez-vous vraiment supprimer le rôle</h5>
            <h5>«{role}»?</h5>
            <br />
            <label>
              Tous les utlisateurs ayant ce rôle n'auront plus les accès
              spécifique à ce rôle
            </label>
          </>
        )}
        {validationComptes === 1  && setIsMailNominatif != null && (
          <>
            <h5>Validation</h5>
              <div className="generic-mail">
                     <label>Le mail « {email} »  est-il un mail nominatif ? </label>
                  <Form.Check
                      id="1"
                      type="radio"
                      label={"Oui"}
                      onChange={()=>{
                          setCheckedMailGenric("oui");
                          setIsMailNominatif(true);
                      }}
                      checked={checkedMailGeneric === "oui"}
                  />
                  <Form.Check
                      id="2"
                      type="radio"
                      label={"Non"}
                      onChange={()=>{
                          setCheckedMailGenric("non");
                          setIsMailNominatif(false);
                      }}
                      checked={checkedMailGeneric === "non"}
                  />
              </div>
            <label>Confirmez-vous la demande de création de compte?</label>
          </>
        )}
        {validationComptes === 2 && (
          <>
            <h5>Refus</h5>
            <label>
              Confirmez-vous le rejet de la demande de création de compte?
            </label>
          </>
        )}
        {validationComptes === 3 && loadingText && (
          <Spinner animation={"border"} />
        )}
        {validationComptes === 3 && !loadingText && (
          <>
            <h5>Renvoi de mail</h5>
            <label>
              Confirmez-vous le renvoi du mail d'inscription?
              <br/>
              {count} collectivités seront relancées.
            </label>
          </>
        )}
        {validationComptes === 4 && (
          <>
            <h5>Suppression</h5>
            <label>
              Confirmez-vous la suppression de l'utilisateur à inscrire?
            </label>
          </>
        )}
        {
            deleteMessageParam && (
                <>
                    <h5>Suppression</h5>
                    <label>
                        Confirmez-vous la suppression de ce message?
                    </label>
                </>
            )
        }
      </div>
      <div className="inline-btn-bloc-modal">
        <Button
          className="mr-2"
          variant="outline-secondary"
          onClick={handleClose}
        >
          Annuler
        </Button>
        <Button
          className="ml-2"
          variant={
            (validationComptes === 2 || validationComptes === 4)
              ? "outline-danger"
              : "outline-success"
          }
          onClick={action}
          disabled={isMailNominatif===null && checkedMailGeneric === ""}
        >
          {loading && (
            <Spinner
              style={{
                marginLeft: "7.5px",
                marginRight: "15px",
                marginBottom: "2px",
              }}
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Confirmer
        </Button>
      </div>
    </Modal>
  );
};
