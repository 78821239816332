import React, { useRef, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import "./MessageParametrable.scss";
import SvgInfoBull from "../../../Shared/Components/SVG/SvgInfoBull";
import SvgAlertBull from "../../../Shared/Components/SVG/SvgAlertBull";
import { Field, Formik, Form } from "formik";
import CheckboxField from "../../../Shared/Components/CheckboxField/CheckboxField";
import {
  MessageParamModal,
  MessageStatus,
  MessageType,
} from "../../../Utils/enum";

import SvgReloadArrow from "../../../Shared/Components/SVG/SvgReloadArrow";
import SvgEye from "../../../Shared/Components/SVG/SvgEye";
import { MESSAGE_COCHE } from "../../../Utils/Constants/Constants";

export interface Props {
  show: boolean;
  prev?: boolean;
  onClose: (value?: boolean) => void;
  setPrev?: (value?: boolean) => void;
  message: any;
}

export const MessageParametrableModal: React.FC<Props> = ({
  show,
  prev,
  onClose,
  setPrev,
  message,
}) => {

  const formikRef = useRef(null as any);

  const handleClose = () => {
    if (!prev && message.statut === MessageStatus.SANS_ACCEPTATION) {
      onClose(false);
    }
  };

  const onClosePrevHandler = () => {
    if (setPrev) setPrev(false);
    onClose(false);
  };

  const onSaveHandler = (value: boolean) => {
    if (!prev) onClose(value);
  };


  

  return (
    <>
      {prev && (
        <div className="previsualisation">
          <span />
          <div className="label-bloc">
            <SvgEye width={25} height={25} />
            <label>Prévisualisation</label>
          </div>
          <Button
            className="confirmation-btn"
            variant="outline-success"
            onClick={onClosePrevHandler}
          >
            <SvgReloadArrow /> Retour au parametrage
          </Button>
        </div>
      )}
      <Modal
        className="MessageParametrableModal"
        scrollable={true}
        centered
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <div className="close-icon-bloc">
          <span />
          {message.statut === MessageStatus.SANS_ACCEPTATION && (
            <X size="40" onClick={handleClose} />
          )}
        </div>
        <div className="modal-title-param">
          {message.type === MessageType.ALERTE ? (
            <SvgAlertBull width={45} height={45} />
          ) : (
            <SvgInfoBull width={45} height={45} />
          )}
          <label
            className={
              message.type === MessageType.ALERTE ? "label-alert" : "label-info"
            }
          >
            {message.titre}
          </label>
        </div>
        <div
          className="modal-body-desc"
          dangerouslySetInnerHTML={{ __html: message.description }}
        />
        <Formik
          innerRef={formikRef}
          enableReinitialize
          validateOnMount
          initialValues={{
            acceptationValue: false,
            desactivationValue: false,
          }}
          onSubmit={({ desactivationValue }) => {
            onSaveHandler(
              message.statut === MessageStatus.SANS_ACCEPTATION &&
                message.avecCoche === 1
                ? desactivationValue
                : (message.statut === MessageStatus.SANS_ACCEPTATION &&
                    message.avecCoche === 0 ? false : true)
            );
          }}
          validateOnBlur
        >
          {({ values }) => {
            return (
              <Form>
                {message.statut === MessageStatus.AVEC_ACCEPTATION && (
                  <Row className="form-inline">
                    <Field
                      id="checkbox-condition-1"
                      type="checkbox"
                      name={MessageParamModal.ACCEPTATION_VALUE}
                      component={CheckboxField}
                      label={message.texteStatut}
                    />
                  </Row>
                )}
                {(message.avecCoche === 1 || message.avecCoche === true) && (
                  <Row className="form-inline">
                    <Field
                      id="checkbox-conditions"
                      type="checkbox"
                      name={MessageParamModal.DESACTIVATION_VALUE}
                      component={CheckboxField}
                      label={MESSAGE_COCHE}
                    />
                  </Row>
                )}
                <div className="inline-btn-bloc-modal">
                  <Button
                    type="submit"
                    className="confirmation-btn"
                    variant="outline-success"
                    disabled={
                      message.statut === MessageStatus.AVEC_ACCEPTATION &&
                      !values.acceptationValue
                    }
                  >
                    OK
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
