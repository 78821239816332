import "./../Layout/Layout.scss";
import React, { useEffect, useRef, useState } from "react";
import logoOnf from "../../assets/logo/logo-onf.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

import { Button, Col, Form, Row } from "react-bootstrap";
import { UtilisateurService } from "../../Services";
import "./initPassword.scss";

import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import CLoginForm from "../Components/CLoginForm/CLoginForm";
import CHeaderForm from "../Components/CHeaderForm/CHeaderForm";

const INIT: string = "init";

const InitPassword: React.FC = () => {
  const emailToken: any = new URLSearchParams(window.location.search).get(
    "token"
  );

  const id: any = Buffer.from(emailToken, "base64")
    .toString("binary")
    .split(";")[1];
  const type = Buffer.from(emailToken, "base64")
    .toString("binary")
    .split(";")[2];
  const formikRef = useRef(null as any);
  const [tokenStatus, setTokenStatus] = useState(false);

  const initPassword = (password: string) => {
    UtilisateurService.initPassword(id, password, emailToken, type)
      .then((res: any) => {
        if (res.isSuccess()) {
          window.location.replace(
            window.origin + process.env.PUBLIC_URL + "/login"
          );
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const getReInitPassTokenStatus = () => {
    UtilisateurService.getReInitPassTokenStatus(emailToken).then((res: any) => {
      setTokenStatus(res?.value?.data);
    });
  };
  useEffect(() => {
    if (emailToken !== undefined) getReInitPassTokenStatus();
  }, []);

  return (
    <>
      <CHeaderForm />
      <CLoginForm>
        <Formik
          innerRef={formikRef}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required(
                "Le mot de passe et sa confirmation sont obligatoires. Merci de saisir les deux champs"
              )
              .min(
                8,
                "Le mot de passe saisi ne respecte pas les conditions d’acceptabilité : 8 caractères minimum"
              )
              .matches(
                new RegExp("(?=.*[a-z])"),
                "Le mot de passe saisi ne respecte pas les conditions d’acceptabilité : Au moins une minuscule"
              )
              .matches(
                new RegExp("(?=.*[A-Z])"),
                "Le mot de passe saisi ne respecte pas les conditions d’acceptabilité : Au moins une majuscule"
              )
              .matches(
                new RegExp("(?=.*[0-9])"),
                "Le mot de passe saisi ne respecte pas les conditions d’acceptabilité : Au moins un chiffre"
              )
              .matches(
                new RegExp("(?=.*[-+_!@#$%^&*.,?])"),
                "Le mot de passe saisi ne respecte pas les conditions d’acceptabilité : Au moins un caractère spécial"
              ),
            confirm: Yup.string().required(
              "Le mot de passe et sa confirmation sont obligatoires. Merci de saisir les deux champs"
            ),
            checkEqual: Yup.boolean().isTrue(
              "Le mot de passe doit être identique à la confirmation. Merci de corriger votre saisie"
            ),
          })}
          onSubmit={(values) => {
            initPassword(values.password);
          }}
          initialValues={{
            password: "",
            confirm: "",
            checkEqual: "",
          }}
          enableReinitialize
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => {
            return (
              <form
                style={{ overflowY: "auto", height: "90%" }}
                onSubmit={handleSubmit}
                onBlur={() => {
                  setFieldValue(
                    "checkEqual",
                    !values.confirm || values.password === values.confirm
                  );
                }}
              >
                <div className="init-pass-container">
                  <div className="init-pass-form">
                    <img src={logoOnf} alt="logo ONF" />
                    <hr />
                    {type === INIT ? (
                      <div className="title">
                        Initialisation de mot de passe
                      </div>
                    ) : (
                      <div className="title">
                        Réinitialisation de mot de passe
                      </div>
                    )}

                    {!tokenStatus ? (
                      <div className="status-error">
                        Le lien{" "}
                        {type === INIT
                          ? "d'initialisation"
                          : "de réinitialisation"}{" "}
                        de mot de passe a expiré, merci de contacter votre
                        administrateur.
                      </div>
                    ) : (
                      <>
                        <div className="form-container">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faUnlockAlt} />
                              </span>
                            </div>
                            <Field
                              as={Form.Control}
                              name="password"
                              type="password"
                              isInvalid={touched.password && !!errors.password}
                              placeholder="Mot de passe"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faUnlockAlt} />
                              </span>
                            </div>
                            <Field
                              as={Form.Control}
                              name="confirm"
                              type="password"
                              isInvalid={
                                touched.password &&
                                (!!errors.password || errors.checkEqual)
                              }
                              placeholder="Confirmation mot de passe"
                            />
                            <ErrorMessage
                              name="confirm"
                              component="div"
                              className="invalid-feedback"
                            />
                            <ErrorMessage
                              name="checkEqual"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="actions">
                          <Button type="submit" className="btn btn-primary">
                            Enregistrer
                          </Button>
                        </div>
                        <Row className="container-rules">
                          <Col className="container-left">
                            * Au moins une majuscule
                            <br /> * Au moins une minuscule
                            <br /> * Au moins un caractère spécial
                          </Col>
                          <Col className="container-right">
                            * Au moins un chiffre
                            <br /> * 8 caractères minimum
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </CLoginForm>
    </>
  );
};

export default InitPassword;
