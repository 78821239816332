import React from "react";

import { Button, Modal } from "react-bootstrap";
import "./SignUp.scss";
import { X } from "react-bootstrap-icons";
import CGUContent from "../Components/CGUContent/CGUContent";

interface Props {
  showProps: boolean;
  setShowProps: Function;
  setFieldValue: Function;
}

export const CGUModal: React.FC<Props> = ({
  showProps,
  setShowProps,
  setFieldValue,
}) => {
  const handleShowModal = () => {
    setFieldValue("conditions", false);
    setShowProps(false);
  };
  const doAction = () => {
    setFieldValue("conditions", true);
    setShowProps(false);
  };

  return (
    <Modal
      className="cgu-modal"
      centered
      size="lg"
      show={showProps}
      onHide={() => setShowProps(false)}
    >
      <div className="modal-body">
        <CGUContent modal>
          <div className="row">
            <div className="col">
              <div className="btn-actions">
                <Button variant="link" onClick={handleShowModal}>
                  <X size={30} />
                  Refuser
                </Button>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  onClick={doAction}
                >
                  Confirmer
                </Button>
              </div>
            </div>
          </div>
        </CGUContent>
      </div>
    </Modal>
  );
};
