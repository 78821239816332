import React from "react";
import {Dropdown} from "react-bootstrap";
import {EventHelper} from "../../../Utils";
import {IFormatterProps} from "./CInterface";
import CToggle from "./CToggle";

const CHeaderFormatter = ({
  column,
  components: { sortElement, filterElement },
  filter,
  show,
  onToggle,
}: IFormatterProps) => {

  const element = document.querySelector("#container-div");

  const popperConfig: any = {
    strategy: "fixed",
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
      {
        name: "flip",
        options: {
          rootBoundary: element,
        },
      },
    ],
  };

  return (
    <div className="table-filter-column">
      <div className="table-column-title-container">
        <div className="table-column-sorters">
          <div className="table-column-title">{column.text}</div>
          <div className={sortElement ? "table-column-sorter" : ""}>
            {sortElement}
          </div>
        </div>
      </div>
      {filter && (
        <Dropdown
          bsPrefix={`table-filter-trigger ${column?.isFilter && "bg-filter"}`}
          alignRight
          show={show[column.dataField]}
          onToggle={(isOpen: boolean) => onToggle(column.dataField, isOpen)}
          onClick={(e: any) => {
            e.stopPropagation();
            if (!e?.currentTarget?.className?.includes("show")) {
              onToggle(column.dataField, !show[column.dataField]);
            }
          }}
          onKeyUp={EventHelper.stopPropagation}
        >
          <div className="table-dropdown">
            <Dropdown.Toggle
              as={CToggle}
              id="dropdown-basic"
              isFilter={column?.isFilter}
            />

            <Dropdown.Menu
              bsPrefix="table-dropdown-menu"
              rootCloseEvent="click"
              flip
              popperConfig={popperConfig}
            >
              <div className="table-filter-dropdown">
                <Dropdown.Header className="text-center m-0 p-0">
                  {filterElement}
                </Dropdown.Header>
              </div>
            </Dropdown.Menu>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default React.memo(CHeaderFormatter);
