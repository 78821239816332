import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./ForgotPwd.scss";
import logo from "../../images/check.png";

import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { UtilisateurService } from "../../Services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import logoOnf from "../../assets/logo/logo-onf.svg";
import CHeaderForm from "../Components/CHeaderForm/CHeaderForm";
import CLoginForm from "../Components/CLoginForm/CLoginForm";
import { Link, useHistory } from "react-router-dom";

const ForgotPwd: React.FC = () => {
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<string>("");
  const history = useHistory();

  const formikRef = useRef(null as any);

  const userExist = (email: string) => {
    UtilisateurService.getByEmailForInitPass(email).then((res: any) => {
      if (res?.value?.data === "INT" || res?.value?.data === "NE") {
        setFormVisible(true);
      } else if (res?.value?.data === "BLK") {
        setMsgError(
          "Votre compte est bloqué, il vous est impossible d’en modifier le mot de passe."
        );
      } else {
        UtilisateurService.sendMailMotPasseOublie(email).then((res: any) => {
          if (res?.value?.data) setFormVisible(true);
        });
      }
    });
  };

  const goLogin = () => {
    history.replace("/login");
  };

  return (
    <div className="ForgotPwd">
      <CHeaderForm />
      <CLoginForm>
        <div className="forgot-pass-container">
          <div className="forgot-pass-form">
            <img className="logo-onf" src={logoOnf} alt="logo ONF" />
            <hr />
            <div className="title">Mot de passe oublié</div>

            {formVisible && (
              <div className=" model-body text-center mt-4">
                <div>
                  <img className="logo-success" src={logo} alt="logo" />
                </div>
                <div className="msg-success">
                  <span>Envoi confirmé</span>
                </div>
                <div>
                  Si l'adresse que vous nous avez indiquée est référencée, un
                  mail contenant un lien de réinitialisation vous a été envoyé.
                </div>
                <br />
                <div className="actions">
                  <Button variant="btn btn-primary" onClick={goLogin}>
                    Se connecter
                  </Button>
                </div>
              </div>
            )}
            {!formVisible && (
              <div>
                <div className="text-center message">
                  Veuillez entrer votre adresse mail dans le champ ci-dessous.
                  Un lien de redirection vous sera envoyé par mail afin de
                  réinitialiser votre mot de passe.
                </div>
                <Formik
                  innerRef={formikRef}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().required("Le mail est obligatoire"),
                  })}
                  onSubmit={(values) => {
                    userExist(values.email);
                  }}
                  initialValues={{
                    email: "",
                  }}
                  enableReinitialize
                >
                  {({ errors, touched, handleSubmit }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {msgError.length > 0 && (
                          <div className="error-container">
                            <p className="title"> Erreur </p>
                            <p> {msgError}</p>
                          </div>
                        )}
                        <div className="text-center">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faAt} />
                              </span>
                            </div>
                            <Field
                              as={Form.Control}
                              name="email"
                              type="email"
                              isInvalid={touched.email && !!errors.email}
                              placeholder="Mail"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="actions">
                            <Button type="submit" className="btn btn-primary">
                              Envoyer
                            </Button>
                          </div>
                          <div className="mb-2">
                            <Link className="forgot-mdp" to="login">
                              Retour à la page de connexion
                            </Link>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </CLoginForm>
    </div>
  );
};
export default ForgotPwd;
