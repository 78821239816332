import React, { useState, useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { SocleConstants } from "@onf/socleweb-react";
import { MessageParametrableModal } from "../../Pages/Parametre/MessageParametrable/MessageParametrableModal";
import { UtilisateurService } from "../../Services";
import ParametrageService from "../../Services/ParametrageService";
import { MATOMO_CNX_CONST } from "../../Utils/Constants/MatomoConstant";

type PrivateRouteProps = {
  [x: string]: any;
};

const MESSAGE_PARAMETRE_KEY = "hasMessageParametre";

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  let location = useLocation();
  const isAuth = () => localStorage.getItem(SocleConstants.ACCES_TOKEN);
  const [activeMessages, setActiveMessages] = useState<any[]>([]);
  const [messageToShow, setMessageToShow] = useState<any>();

  useEffect(() => {
    if(activeMessages && activeMessages.length > 0) {
        setMessageToShow(activeMessages[activeMessages.length - 1]);
    } else {
        setMessageToShow(undefined);
    }
  }, [activeMessages])

  const isFromLogin = (location: any): boolean => {
    return location?.state?.from?.includes("login");
  };

  const sendNotifToMatomo = (isLogin: boolean) => {
    if (isLogin) {
      localStorage.setItem(MATOMO_CNX_CONST.nbCnxTypeUser, '"true"');
      localStorage.setItem(MATOMO_CNX_CONST.nbCnxDpt, '"true"');
    }
  };

  const getAllActiveMessagesForUserConnecte = (isLogin: boolean) => {
    if (isLogin || localStorage.getItem(MESSAGE_PARAMETRE_KEY)) {
      ParametrageService.getAllActiveMessagesForUserConnecte().then(
        (response: any) => {
          if (response.isSuccess()) {
            const data: any[] = response?.value?.data?.map((d: any) => ({
              ...d,
              show: true,
            }));
            setActiveMessages(data);
            localStorage.setItem(MESSAGE_PARAMETRE_KEY, "true");
          }
        }
      );
    }
  };

  const onCloseHandler = (
    messageId: number,
    index: number,
    persist?: boolean
  ) => {
    const newActiveMessages: any[] = [...activeMessages];
    newActiveMessages.pop();
    if (newActiveMessages.every((msg: any) => !msg.show))
      localStorage.removeItem(MESSAGE_PARAMETRE_KEY);

    setActiveMessages(newActiveMessages);

    if (persist) ParametrageService.hideActiveMessageForUserConnecte(messageId);
  };

  useEffect(() => {
    const isLogin: boolean = isFromLogin(location);
    const getCurrentUser = () => {
      UtilisateurService.getCurrentUser().then((res: any) => {
        if (res?.isSuccess()) {
          const user: any = res?.value?.data;

          if (user && user?.actif && user?.role !== "" && user?.role !== null) {
            sendNotifToMatomo(isLogin);
            getAllActiveMessagesForUserConnecte(isLogin);
          }
        }
      });
    };

    if (isLogin || localStorage.getItem(MESSAGE_PARAMETRE_KEY))
      getCurrentUser();
  }, [location]);

  return (
    <>
      {messageToShow && (
        <MessageParametrableModal
            key={JSON.stringify(messageToShow)}
            show={messageToShow.show}
            onClose={(persist?: boolean) =>
                onCloseHandler(messageToShow.id, activeMessages.length - 1, persist)
            }
            message={messageToShow}
        />
      )}
      <Route
        {...rest}
        render={({ location }) => {
          return isAuth() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }}
      />
    </>
  );
};

export default PrivateRoute;
