import "./Layout.scss";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Image } from "react-bootstrap";
import {
  Layout as SwLayout,
  LeftColumn,
  SideBar,
} from "@onf/socleweb-react";
import Header from "./Header";
import CContent from "./Content";
import Footer from "./Footer";

import { StorageManagerService, UtilisateurService } from "../../Services";
import ParametreService from "../../Services/ParametreService";
import { initConfigStore } from "../../hooks-store";
import NotFound from "../NotFound/NotFound";
import Loading from "../Loading/Loading";
import LogoFNCOFOR from "../../assets/logo/logo-fncofor.svg";
import { getMenu } from "./Menu";
import { IMenu } from "../../Utils/interface";
import { recursiveMenu } from "../../Utils/Helpers/MenuHelper";
import SessionService from "../../Services/SessionService";

const Layout: React.FC<{}> = () => {
  const [menu, setMenu] = useState<any[]>([]);
  const [currentUser, setCurrentUser] = useState({ actif: true });
  const [noValidRole, setNoValidRole] = useState<boolean>(false);
  const [utilisateurDesactive, setUtilisateurDesactive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    const getCurrentUser = () => {
      UtilisateurService.getCurrentUser().then((res: any) => {
        if (res.isSuccess()) {
          const user: any = res?.value?.data;
          
          StorageManagerService.Set("current", user);
          setCurrentUser(user);

          let menuTmp: any[] = getMenu();
          menuTmp = menuTmp?.map((m: IMenu) => recursiveMenu(m))?.filter((m: any) => m !== undefined);

          setMenu(menuTmp);

        } else {
          if (res.error.data.code==="UTILISATEUR_ROLE_INACTIF") {
            setNoValidRole(true)
          } else if (res.error.data.code==="UTILISATEUR_DESACTIVE") {
            setUtilisateurDesactive(true)
          } else {
            localStorage.clear();
            setRedirect(true);
          }
        }
        setLoading(false);
      })
    };

    const getParametrage = () => {
      ParametreService.getCartoParameters().then((response: any) => {
        if (response.isSuccess()) {
          const data: any = response?.value?.data;
          localStorage.setItem(
            "url_sig",
            data["portailservices.cartographie.url.sig"]
          );
          localStorage.setItem(
            "sig_suffixe",
            data["portailservices.cartographie.url.sig.suffixe"]
          );
          localStorage.setItem(
            "sig_suffixe_rdf",
            data["portailservices.cartographie.url.sig.rdf.suffixe"]
          );
          localStorage.setItem(
            "fond_carte",
            data["portailservices.cartographie.fondcarte.token"]
          );
          localStorage.setItem(
            "print_url",
            data["portailservices.cartographie.print.url"]
          );
          localStorage.setItem(
            "bio_sig_url",
            data["portailservices.cartographie.biodiversite.url.sig"]
          );
          localStorage.setItem(
            "prsf_sig_url",
            data["portailservices.cartographie.prsf.url.sig"]
          );
          localStorage.setItem(
            "plan_v2_url",
            data["portailservices.cartographie.plan.v2.url"]
          );
          localStorage.setItem(
            "plan_ortho_url",
            data["portailservices.cartographie.plan.ortho.url"]
          );
          localStorage.setItem(
            "plan_parcellaire_url",
            data["portailservices.cartographie.plan.parcellaire.url"]
          );
          localStorage.setItem(
            "plan_infrarouge_url",
            data["portailservices.cartographie.plan.infrarouge.url"]
          );
        }
      });
    };

    const updateLastConnectionDateTime = () => {
      if (StorageManagerService.Get("previousDateDerniereConnexion")) {
        // update once per session
        return;
      }
      UtilisateurService.updateLastConnectionDateTime().then((res: any) => {
          if (res.isSuccess()) {
            const previousDateDerniereConnexion: any = res?.value?.data;
            StorageManagerService.Set("previousDateDerniereConnexion", previousDateDerniereConnexion);
          }
        })
        .catch((error: any) => {
          throw error;
        });
    };

    getCurrentUser();
    getParametrage();
    updateLastConnectionDateTime();
  }, []);

  initConfigStore();

  if (redirect) return <Redirect to="/login" />;
  if (loading) return <Loading />;

  return (
    <Container fluid id="container-fluid-table">
      
      <SwLayout>
        <Header
          currentUser={currentUser}
          isNotActive={
            (menu.length === 0 && currentUser?.actif) || !currentUser?.actif
          }
        />
        {menu.length > 0 && currentUser?.actif && (
          <Row className="layout-row">
            <LeftColumn>
              <SideBar
                footerIcon={<Image src={LogoFNCOFOR} />}
                footerTitle={
                  <div>
                    En collaboration avec la{" "}
                    <span className="font-weight-bold">FNCOFOR</span>
                  </div>
                }
                menu={menu}
              />
            </LeftColumn>

            <div className="content" id="layout-content">
              <div>
              <CContent />
              </div>
              <Footer />
            </div>
          </Row>
        )}
        {noValidRole && <NotFound type="menu" />}
        {utilisateurDesactive && <NotFound type="user" />}
      </SwLayout>
    </Container>
  );
};

export default Layout;
