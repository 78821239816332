import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    UPDATE_TFT_SELECTED: (_: any, codesParcelles: any[]) => {
      return { codesParcelles: codesParcelles };
    },
    UPDATE_CLEAR_TFT: (_: any, clear: boolean) => {
      return { clearTFT: clear };
    },
  };

  initStore(actions, {
    codesParcelles: [],
    clearTFT: false,
  });
};

export default configureStore;
