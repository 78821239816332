import React from 'react'
import CGUContent from '../../Shared/Components/CGUContent/CGUContent';
import FooterPage from '../../Shared/Components/FooterPage/FooterPage';
import "./ConditionsGeneralesUtilisation.scss";


const ConditionsGeneralesUtilisation = () => {
  return (
    <div className="ConditionsGeneralesUtilisation">
      <FooterPage title="Conditions Générales d'Utilisation">
        <CGUContent />
      </FooterPage>
    </div>
  );
};

export default ConditionsGeneralesUtilisation;
