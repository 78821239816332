import React from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import {X} from "react-bootstrap-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import "./ExportModal.scss";

export interface Props {
  showModal: boolean;
  pdfLoading?: boolean;
  excelLoading?: boolean;
  setShowModal: Function;
  exportPdf: Function;
  exportExcel: Function;
}

export const ExportModal: React.FC<Props> = (props) => {
  const {
    showModal,
    setShowModal,
    exportPdf,
    exportExcel,
    pdfLoading,
    excelLoading,
  } = props;
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal className="export-modal" centered show={showModal} onHide={handleClose}>
      <div className="modal-header">
        <X size="40" onClick={handleClose} />
      </div>

      <div className="inline-btn-bloc-modal">
        <Button
          className="mr-2"
          variant="outline-success"
          onClick={() => exportPdf()}
          disabled={pdfLoading}
        >
          {pdfLoading ? (
            <Spinner
              style={{
                marginLeft: "7.5px",
                marginRight: "15px",
                marginBottom: "2px",
              }}
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <FontAwesomeIcon icon={faFilePdf} />
          )}
          PDF
        </Button>
        <Button
          className="ml-2"
          variant="outline-success"
          onClick={() => exportExcel()}
          disabled={excelLoading}
        >
          {excelLoading ? (
            <Spinner
              style={{
                marginLeft: "7.5px",
                marginRight: "15px",
                marginBottom: "2px",
              }}
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <FontAwesomeIcon icon={faFileExcel} />
          )}
          Excel
        </Button>
      </div>
    </Modal>
  );
};
