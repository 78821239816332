import React from "react";
import "./Login.scss";
import { Login as SocleLogin } from "@onf/socleweb-react";
import { Link, withRouter } from "react-router-dom";
import logoOnf from "../../assets/logo/logo-onf.svg";
import CHeaderForm from "../Components/CHeaderForm/CHeaderForm";
import CLoginForm from "../Components/CLoginForm/CLoginForm";

const Login = ({ history }: any) => {
  const redirectTo: string = history?.location?.state
    ? history?.location?.state?.from?.pathname +
      history?.location?.state?.from?.search
    : "/";

  return (
    <>
      <CHeaderForm />
      <CLoginForm>
        <SocleLogin
          title="PORTAIL DES COLLECTIVITES"
          subtitle="Accédez à votre espace"
          logo={logoOnf}
          redirectTo={redirectTo}
        >
          <Link className="forgot-mdp" to="forgot-pass">
            Mot de passe oublié
          </Link>
        </SocleLogin>
      </CLoginForm>
    </>
  );
};

export default withRouter(Login);
