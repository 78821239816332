import React from "react";
import { Row, Col } from "react-bootstrap";
import { SITE } from "../../../Utils/Constants/Constants";
import "./CGUContent.scss";

interface IProps {
  modal?: boolean;
  children?: any;
}

const EMAIL = "dpo@onf.fr";

const CGUContent = ({ modal, children }: IProps) => {
  return (
    <div className="CGUContent">
      {modal && (
        <Row>
          <Col className="label-title">Conditions Générales d'Utilisation</Col>
        </Row>
      )}
      <div className="cgu-body">
        <ol>
          <li className="mt-0">
            <span>OBJET</span>
          </li>
          <p>
            Les présentes CGU ou Conditions Générales d'Utilisation encadrent
            juridiquement l'utilisation des services du site Portail des
            collectivités (ci-après dénommé « le site »).
          </p>
          <p>
            L'accès et l'utilisation du Service sont soumis aux présentes
            Conditions Générales d'Utilisation (ci-après les « Conditions »),
            que l'Utilisateur accepte expressément lors de son inscription au
            Service. L'Utilisation de ce Service entraine l'acceptation pleine
            et entière des présentes CGU.
          </p>
          <li>
            <span>DEFINITIONS</span>
            <p>
              Dans les présentes Conditions Générales d'Utilisation, les termes
              suivants ont, sauf précision contraire, le sens qui leur est donné
              dans cet article.
            </p>
            <ol>
              <li>« Compte »</li>
              <p>
                L'ensemble des ressources informatiques et réseaux attribuées à
                un Utilisateur qui ne peut les utiliser qu'en s'identifiant sur
                le Service avec son nom d'utilisateur (« identifiant ») et son
                mot de passe.
              </p>
              <li>« Utilisateur »</li>
              <p>
                Personne ayant souscrit au Service pour ses besoins propres dans
                le cadre d'un usage strictement professionnel et non commercial.
              </p>
              <li>« Contenu »</li>
              <p>
                L'ensemble des contenus mis à disposition des Utilisateurs par
                l'ONF et des contenus édités par les Utilisateurs dans le cadre
                du Service (et notamment textes, images, liens...).
              </p>
              <li>« Service »</li>
              <p>
                Le Service Portail des collectivités édité et proposé par l'ONF
                est accessible à l'url :{" "}
                <a href={SITE.url} target="_blank">
                  {SITE.url}
                </a>
              </p>
              <p className="font-weight-bold">
                L'utilisation du Service est subordonnée à la validation des
                présentes CGU par l'Utilisateur lors de sa première connexion.
              </p>
            </ol>
          </li>
          <li>
            <span>MODALITES D'ACCES AU SERVICE</span>
          </li>
          <p>
            Le site Portail des Collectivités, ci-après désigné le « service »,
            permet d'accéder aux services suivants :
          </p>
          <ul>
            <li>Consultation de données cartographiques de ses forêts ;</li>
            <li>
              Consultation de données chiffrées traduisant la gestion de ses
              forêts ;
            </li>
            <li>
              Consultation de documents produits dans le cadre de la mise en
              œuvre du régime forestier de ses forêts par l'ONF ;
            </li>
            <li>
              Consultation de documents d'aide à la compréhension de la gestion
              sylvicole de ses forêts (glossaire, FAQ) ;
            </li>
            <li>
              Echange de notes partagées (« mémos ») entre plusieurs
              utilisateurs de l'ONF et de la collectivité : échanges réservés
              aux personnes habilitées de chaque structure (rôle de «
              communicant ») ;
            </li>
            <li>
              Information actualisée des personnes à contacter à l'ONF pour la
              gestion de ses forêts.
            </li>
          </ul>
          <p>Le service est accessible gratuitement.</p>
          <p>
            L'Utilisateur est informé que l'accès au service nécessite la
            validation des présentes CGU.
          </p>
          <p>
            L'authentification des Utilisateurs est obligatoire pour toutes
            actions sur le Service dans les conditions définies ci-dessous.
          </p>
          <p>La première connexion au service nécessite :</p>
          <ul>
            <li>
              L'identification de l'Utilisateur via un identifiant (adresse
              mail) enregistré lors de la création du compte ;
            </li>
            <li>
              La création d'un mot de passe défini lors de la première
              connexion;
            </li>
          </ul>
          <p>Par la suite, et pour chaque utilisation du service :</p>
          <ul>
            <li>
              L'Utilisateur s'identifiera au moyen de son identifiant (ou login)
              et du mot de passe définis lors de l'inscription au service.
            </li>
          </ul>
          <p>
            Pour des raisons de maintenance, de mises à jour ou toute opération
            associée, l'accès au site hébergeant le service peut être interrompu
            ou suspendu temporairement par l'ONF sans préavis.
          </p>
          <li>
            <span>ENGAGEMENTS DE L'ONF</span>
            <ol>
              <li>Délivrance du service</li>
              <p>
                L'ONF met en place les moyens nécessaires à la bonne marche du
                Service et prend les mesures nécessaires au maintien de la
                continuité et de la qualité de ce Service.
              </p>
              <p>
                L'ONF se réserve le droit de modifier, de faire évoluer (par la
                mise à disposition de nouvelles fonctionnalités par exemple),
                d'interrompre ou d'arrêter le Service à tout moment.
              </p>
              <p>
                L'ONF informera les Utilisateurs à chaque évolution apportée au
                service et le cas échéant des motivations l'ayant conduit à
                l'interruption du service.
              </p>
              <li>Responsabilités ONF</li>
              <p>
                Les informations mises en ligne par l'ONF sur le site &nbsp;
                <a href={SITE.url}>{SITE.dns}</a> sont réputées fiables et
                présentées à titre informatif.
              </p>
              <p>
                L'ONF s'engage à prendre toutes précautions utiles pour
                préserver la sécurité des données collectées auprès de
                l'utilisateur et notamment empêcher qu'elles soient déformées,
                endommagées ou que des tiers non autorisés y aient accès.
              </p>
              <p>
                L'utilisation de ce Service n'exonère pas l'utilisateur de la
                conservation de tout document mis à sa disposition dans cet
                espace.
              </p>
              <p>
                Les éventuels virus pouvant infecter le matériel informatique de
                l'Utilisateur après l'utilisation ou l'accès au service ne sont
                pas de la responsabilité du site hébergeant le service.
              </p>
              <p>
                Le site ne peut être tenu pour responsable en cas de force
                majeure ou du fait imprévisible et insurmontable d'un tiers.
              </p>
            </ol>
          </li>
          <li>
            <span>ENGAGEMENTS DE L'UTILISATEUR</span>
            <ol>
              <li>
                Responsabilités de l'Utilisateur sur ses informations
                personnelles
              </li>
              <p>
                L'Utilisateur est seul responsable des données qu'il communique
                au travers du Service et déclare que les données fournies sont
                parfaitement renseignées et exactes.
              </p>
              <p>
                L'Utilisateur est informé qu'il est seul responsable de la
                conservation du caractère confidentiel de son identifiant et son
                mot de passe.
              </p>
              <p>
                L'Utilisateur privilégiera autant que possible le recours comme
                identifiant à une messagerie nominative et institutionnelle dans
                laquelle le nom de domaine de la collectivité apparaît.
              </p>
              <p>
                Il s'engage à informer immédiatement l'ONF de toute utilisation
                non autorisée de son Compte, et de toute atteinte à la sécurité
                dudit Compte. Il appartient à l'Utilisateur du Service de
                personnaliser et de changer son mot de passe régulièrement.
                Cette modification est possible à tout moment dans la gestion du
                profil Utilisateur.
              </p>
              <p>
                L'Utilisateur s'interdit de tenter d'induire en erreur les tiers
                en usurpant le nom ou la dénomination sociale d'autres
                personnes.
              </p>
              <li>
                Responsabilités de l'Utilisateur sur la diffusion des données
                fournies par le service
              </li>
              <p>
                Les informations déposées par l'ONF sur le portail, sont mises à
                disposition de l'utilisateur au titre de la mise en œuvre du
                régime forestier, en sa qualité de représentant du propriétaire
                forestier.
              </p>
              <p>
                L'Utilisateur est invité à faire preuve de discernement et de
                prudence s'agissant du caractère communicable ou non à des tiers
                des informations mises à sa disposition via ce service.
              </p>
              <p>
                L'Utilisateur s'engage ainsi à respecter les dispositions
                légales et réglementaires en matière de diffusion et de
                communication des données visées au code des relations entre le
                public et l'administration (CRPA).
              </p>
              <p>
                Ainsi, conformément à l'article L 311-5 du CRPA et L 124-5 du
                code de l'environnement, l'utilisateur s'engage notamment à ne
                pas diffuser à des tiers des informations susceptibles de porter
                atteinte :
              </p>
              <ul>
                <li>
                  Aux secrets protégés par la loi, tel que le secret des
                  affaires, ou de favoriser des pratiques anticoncurrentielles
                  susceptibles de poursuites pénales en application de l'article
                  L 420-6 du code de commerce (ex: volumes prévisionnels,
                  désignés, mis en vente, vendus, le bilan économique figurant à
                  l' aménagement…) ;
                </li>
                <li>
                  Aux enjeux de protection environnementale protégés ou de
                  défense nationale (ex: localisation d'espèces protégées, de
                  captages d'eau…).
                </li>
              </ul>
              <li>
                Responsabilités de l'Utilisateur sur les contenus partagés
              </li>
              <p>
                L'Utilisateur est seul responsable des contenus qu'il envoie
                vers les espaces communautaires du Service (espace Mémos).
              </p>
              <p>
                Le ton des échanges doit être courtois et professionnel, et les
                contenus déposés ne doivent pas être contraires aux dispositions
                légales et/ou réglementaires en vigueur, ainsi qu'aux
                dispositions des présentes Conditions.
              </p>
              <p>
                Par ailleurs ils ne doivent pas porter atteinte aux droits des
                tiers, ni être susceptibles de porter atteinte à l'image du
                Service ou de tout tiers associé.
              </p>
              <p>
                Ainsi, l'Utilisateur s'engage notamment à ce qu'aucun contenu
                publié ne contienne :
              </p>
              <ul>
                <li>
                  Des propos et/ou images susceptibles de porter atteinte à
                  l'ordre public ;
                </li>
                <li>
                  Des propos et/ou des images encourageant la commission de
                  crimes et délits ;
                </li>
                <li>
                  Des propos et/ou des images grossiers, insultants, injurieux
                  ou diffamants à l'encontre d'autrui, ou portant atteinte à la
                  réputation d'une quelconque personne physique ou morale, de
                  quelque manière que ce soit ;
                </li>
                <li>
                  Des propos et/ou des images encourageant, contenant ou
                  provoquant à la discrimination, la haine ou la violence à
                  l'égard d'une personne ou d'un groupe de personnes ;
                </li>
                <li>
                  Des propos mensongers constitutifs de parasitisme, de
                  concurrence déloyale et/ou de dénigrement à l'égard des
                  produits et services de l'ONF.
                </li>
              </ul>
              <p>
                Dans ce cadre l'ONF se réserve le droit de rejeter sans préavis
                tout Contenu qui ne respecterait pas les conditions
                d'utilisation.
              </p>
              <li>
                Suspension ou fermeture d'un Compte d'un Utilisateur du Service
              </li>
              <p>
                Sans préjudice des actions en responsabilité pénale ou civile
                que l'ONF se réserve le droit d'engager, l'ONF peut décider,
                conformément aux stipulations figurant aux présentes de
                suspendre ou de fermer de plein droit le Compte d'un
                Utilisateur, sans préavis, dans les cas suivants :
              </p>
              <ul>
                <li>
                  Un quelconque acte de piratage ou de tentative d'utilisation
                  illicite des informations circulant sur le réseau ayant pour
                  cause ou origine le Compte de l'Utilisateur ;
                </li>
                <li>
                  Un acte de piratage ou tentative de piratage des équipements
                  du réseau de l'ONF nécessaires à la fourniture du Service
                  ayant pour cause ou origine la connexion avec les équipements
                  de l'Utilisateur ;
                </li>
                <li>
                  Les informations concernant l'Utilisateur telles que
                  communiquées lors de son inscription par celui-ci sont
                  fausses, incomplètes, manifestement fantaisistes ou illicites
                  ;
                </li>
                <li>
                  Commercialisation du Service sous quelque forme que ce soit
                  par l'Utilisateur à un ou plusieurs tiers ou d'utilisation
                  abusive du Service ;
                </li>
                <li>
                  Publication par l'Utilisateur de Contenus contraire aux
                  dispositions des présentes et notamment en cas de Contenus
                  (manifestement) illicites ;
                </li>
                <li>
                  Non-respect des conditions et engagements d'utilisation.
                </li>
              </ul>
              <p>
                En cas d'arrêt définitif du Service, l'Utilisateur en sera
                averti par mail adressé dans le délai d'un (1) mois avant la
                fermeture du Service.
              </p>
            </ol>
          </li>
          <li>
            <span>
              COLLECTE ET TRAITEMENT DES DONNEES A CARACTERE PERSONNEL
            </span>
            <p>
              L'Office national des forêts (ONF) est particulièrement attentif
              au respect par ses services des obligations légales et
              réglementaires en matière de protection des données personnelles
              et suit les recommandations de la Commission Nationale
              Informatique et Libertés (CNIL).
            </p>
            <p>
              Conformément au règlement (UE) général relatif à la protection des
              données à caractère personnel (RGPD) entré en vigueur au sein de
              l'Union européenne le 25 mai 2018, l'ONF poursuit un double
              objectif :
            </p>
            <ul>
              <li>
                Renforcer les droits dont vous disposez sur vos données à
                caractère personnel ;
              </li>
              <li>
                Responsabiliser les responsables de traitement au sein de l'ONF.
              </li>
            </ul>
            <ol>
              <li>Engagements de l'ONF sur vos données personnelles</li>
              <p>
                L'ONF s'engage à prendre toutes les mesures nécessaires
                permettant de garantir la sécurité et la confidentialité des
                informations à caractère personnel fournies par les
                utilisateurs.
              </p>
              <p>
                L'ONF s'engage à n'opérer aucune commercialisation des
                informations et documents transmis par les utilisateurs au moyen
                du Service, et à ne pas les communiquer à des tiers, en dehors
                des cas prévus par la loi tels que la communication
                d'information à caractère personnel relatives à l'Utilisateur
                dans le cadre de réquisitions judiciaires.
              </p>
              <p>
                Les données recueillies dans le cadre du Service sont traitées
                et utilisées par l'ONF dans le cadre de la fourniture du Service
                lié à la mise en œuvre du Régime Forestier.
              </p>
              <p>
                Pour la création du compte de l'Utilisateur, la collecte
                d'informations personnelles est nécessaire et obligatoire au
                moment de l'inscription sur le site.
              </p>
              <p>
                Toute demande relative aux données personnelles de l'usager
                s'effectue auprès du correspondant informatique et libertés de
                l'ONF à l'adresse{" "}
                <a href={`mailto:${EMAIL}`} target="_blank">
                  {EMAIL}
                </a>
              </p>
              <li>Les données collectées par l'ONF</li>
              <p>
                L'ONF veille à ne collecter que les données dont il a
                strictement besoin afin de répondre aux finalités exposées
                précédemment. Des données de navigation (recherches, nombre de
                visites…) et autres données (navigateur utilisé, taille écran,
                localisation...) peuvent être collectées à des fins de
                statistiques (7 Collecte des données de navigation).
              </p>
              <p>
                Les données personnelles que l'ONF est dans la possibilité de
                collecter sont les suivantes :
              </p>
              <ul>
                <li>
                  Obligatoire : nom, prénom, fonction, adresse mail nominative
                  (identifiant) ;
                </li>
                <li>
                  Facultatif : service, numéro de téléphone fixe, numéro de
                  téléphone mobile, horaires à privilégier, modes de contact à
                  privilégier, image du profil utilisateur.
                </li>
              </ul>
              <li>Obtention des données</li>
              <p>
                Les données personnelles sont collectées dans les formulaires
                d'inscription au site ou dans la page de création d'un nouvel
                utilisateur.
              </p>
              <li>Durée de conservation des données</li>
              <p>
                Vos données personnelles ne sont conservées que pour des durées
                strictement nécessaires aux contraintes légales et
                réglementaires.
              </p>
              <li>Les moyens mis en œuvre pour assurer leur sécurité</li>
              <p>
                L'ONF s'engage à prendre toutes les mesures nécessaires
                permettant de garantir la sécurité et la confidentialité des
                informations à caractère personnel fournies par les
                utilisateurs. L'ONF met notamment en œuvre les mesures de
                sécurité suivantes :
              </p>
              <ul>
                <li>
                  Des systèmes de protections automatisés contre les
                  cyberattaques ;
                </li>
                <li>Des tests de sécurité automatisés et périodiques ;</li>
                <li>Des audits de sécurité externes ;</li>
                <li>
                  Un accès aux données personnelles des clients soumis à des
                  contrôles d'accès stricts.
                </li>
              </ul>
              <li>Droits de l'Utilisateur</li>
              <p>
                Conformément au règlement (UE) général relatif à la protection
                des données à caractère personnel (RGPD) entré en vigueur au
                sein de l'Union européenne le 25 mai 2018, et à la loi n° 78-17
                du 6 janvier 1978 relative à l'informatique aux fichiers et aux
                libertés, l'ONF garantit aux utilisateurs du Service les droits
                d'accès, de rectification et d'opposition prévus par la loi.
              </p>
              <p>
                Toute demande relative aux données personnelles de l'usager
                s'effectue auprès du correspondant informatique et libertés de
                l'ONF à l'adresse{" "}
                <a href={`mailto:${EMAIL}`} target="_blank">
                  {EMAIL}
                </a>
                .
              </p>
              <p>
                L'ONF s'engage à n'opérer aucune commercialisation des
                informations et documents transmis par les utilisateurs au moyen
                du Service, et à ne pas les communiquer à des tiers, en dehors
                des cas prévus par la loi tels que la communication
                d'information à caractère personnel relatives à l'Utilisateur
                dans le cadre de réquisitions judiciaires.
              </p>
            </ol>
          </li>
          <li>
            <span>COLLECTE ET REGIME DES DONNES DE NAVIGATION (COOKIES)</span>
          </li>
          <p>
            Un « cookie » est une suite d'informations, généralement de petite
            taille et identifié par un nom, déposées temporairement sur le
            disque dur de l'ordinateur de l'Utilisateur, qui peut être transmis
            à votre navigateur par un site web sur lequel vous vous connectez.
            Votre navigateur web le conservera pendant une certaine durée (13
            mois), et le renverra au serveur web chaque fois que vous vous y
            re-connecterez. Ces fichiers ne comportent pas d'informations
            personnelles et ne peuvent pas être utilisés pour l'identification
            d'une personne.
          </p>
          <p>2 types de cookies sont déposés sur le présent site :</p>
          <ul>
            <li>
              Cookies internes nécessaires au site pour fonctionner Ces cookies
              permettent au site de fonctionner et ne peuvent pas être
              désactivés ;
            </li>
            <li>Cookies de mesure d'audience (Matomo).</li>
          </ul>
          <p>
            Ces cookies sont déployés afin d'obtenir des informations sur la
            navigation des visiteurs. Ils permettent notamment de comprendre
            comment les utilisateurs utilisent le site et de reconstituer leur
            parcours. Ils sont utilisés pour améliorer les performances de
            navigation sur le site <a href={SITE.url}>{SITE.dns}</a>
          </p>
          <p>
            Vous pouvez vous opposer au suivi de votre navigation sur ce site
            web. Cela protégera votre vie privée, mais empêchera également l'ONF
            d'apprendre de vos actions et de créer une meilleure expérience pour
            vous et les autres utilisateurs.
          </p>
          <p>
            La désactivation des cookies peut s'effectuer via les paramètres du
            logiciel de navigation à la connexion.
          </p>
          <li>
            <span>LIENS HYPERTEXTES</span>
          </li>
          <p>
            Le service peut être constitué de liens hypertextes. En cliquant sur
            ces derniers, l'Utilisateur sortira du portail. Ce dernier n'a pas
            de contrôle et ne peut pas être tenu responsable du contenu des
            pages web relatives à ces liens.
          </p>
        </ol>
        {children}
      </div>
    </div>
  );
};

export default CGUContent;
