import React from "react";
import Select from "react-select";
import {InputSelectStyles} from "../../Constants";

const CSelect: React.FC<any> = ({ ...props }) => {
  return (
    <Select
      styles={InputSelectStyles}
      placeholder=""
      {...props}
      noOptionsMessage={() => "Aucune donnée disponible"}
      loadingMessage={() => "Chargement..."}
    />
  );
};

export default CSelect;
