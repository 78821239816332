import {AbstractCrudService, PostPutModel} from "@onf/socleweb-react";
import {MessageParametre} from "../Utils/types";

class ParametrageService extends AbstractCrudService {
  createMessage(message: MessageParametre) {
    return this.httpService.post(
      this.getEndpointPath(),
      new PostPutModel(message)
    );
  }

  updateMessage(message: any) {
    return this.httpService.put(
      this.getEndpointPath() + "/" + message.id,
      new PostPutModel(message)
    );
  }

  getAllActiveMessages() {
    return this.httpService.get(this.getEndpointPath() + "/search/actif");
  }

  getAllDraftMessages(criteres: any) {
    return this.httpService.get(
      this.getEndpointPath() +
        "/search/brouillon?" +
        "page=" +
        criteres.page +
        "&taillePage=" +
        criteres.taillePage
    );
  }

  getAllInactiveMessages(criteres: any) {
    return this.httpService.get(
      this.getEndpointPath() +
        "/search/inactif?" +
        "page=" +
        criteres.page +
        "&taillePage=" +
        criteres.taillePage
    );
  }

  getAllActiveMessagesForUserConnecte() {
    return this.httpService.get(this.getEndpointPath() + "/notif");
  }

  hideActiveMessageForUserConnecte(messageId: number) {
    return this.httpService.post(
      this.getEndpointPath() + "/notif/hide",
      new PostPutModel(messageId)
    );
  }

  deteteMessage(id: number) {
    return this.httpService.delete(this.getEndpointPath() + "/" + id);
  }

  addToFavoris(id: number, fav: number) {
    const data: any = {
      favoris: fav === 1,
    };
    return this.httpService.put(
      this.getEndpointPath() + "/favoris/" + id,
      new PostPutModel(data)
    );
  }

  public async exportAllExcel() {
    return this.httpService.axiosInstance({
      url: `${this.getEndpointPath()}/export/excel`,
      method: "GET",
      responseType: "arraybuffer",
    });
  }

  public async exportAllPDF() {
    return this.httpService.axiosInstance({
      url: `${this.getEndpointPath()}/export/pdf`,
      method: "GET",
      responseType: "blob",
    });
  }

  getEndpointPath(): string {
    return "/message_parametre";
  }
}

export default new ParametrageService();
