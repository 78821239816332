import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState,} from "react";
import {Dropdown} from "react-bootstrap";
import CActionsButtons from "./CActionsButtons";
import {IFilterProps} from "./CInterface";

const CDefaultFilter = forwardRef(
  ({ column, onFilter, onToggle, show }: IFilterProps, ref: any) => {
    const [input, setInput] = useState<string>("");

    const inputRef = useCallback(
      (node) => {
        if (node && show[column.dataField]) {
          node?.focus();
        }
      },
      [show[column.dataField]]
    );

    useEffect(() => {
      if (column.filter.props.getFilter) {
        column.filter.props.getFilter((filterVal: any) => {
          onFilter(filterVal);
        });
      }
    }, [column]);

    useImperativeHandle(ref, () => ({
      onReset: () => {
        onReset();
      },
    }));

    const onFilterHandler = () => {
      onFilter(input.trim());
    };

    const onSubmit = (e: any) => {
      e.preventDefault();
      onToggle(column.dataField, false);
      onFilterHandler();
    };

    const onReset = () => {
      setInput("");
      onToggle(column.dataField, false);
      onFilter(null);
    };

    return (
      <form onSubmit={onSubmit} noValidate className="p-2">
        <input
          ref={inputRef}
          className="table-input"
          type="text"
          value={input}
          placeholder={`Rechercher par ${column.text.toLowerCase()}`}
          onChange={(e: any) => setInput(e.target.value)}
          autoComplete="off"
        />

        <Dropdown.Divider />

        <CActionsButtons onSubmit={onSubmit} onReset={onReset} />
      </form>
    );
  }
);

export default CDefaultFilter;
