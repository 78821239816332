import React, { useEffect, useRef, useState } from "react";
import { Alert, Image } from "react-bootstrap";
import defaultAvatar from "../../../images/img_avatar.png";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Avatar.scss";
import { UtilisateurService } from "../../../Services";
import { useStore } from "../../../hooks-store/store";
import AlertSuccess from "../../../assets/alert/alert-success.svg";
import AlertError from "../../../assets/alert/alert-error.svg";

interface Props {
  width: string;
  height: string;
  binary?: Blob;
  loading?: boolean;
  upload?: boolean;
}
const MAX_SIZE = 2097152;
const NOTIF_DELAY = 15000;

const Avatar: React.FC<Props> = (props) => {
  const dispatch = useStore()[1];
  const [avatarSrc, setAvatarSrc] = useState<any>(null);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [messageError, setMessageError] = useState("");

  const avatarURL = props?.binary
    ? `data:image/jpeg;base64,${props.binary}`
    : defaultAvatar;

  useEffect(() => {
    if (!props?.loading) {
      if (avatarURL && avatarURL !== avatarSrc) {
        setAvatarSrc(avatarURL);
      }
    }
  }, [props]);

  useEffect(() => {
    if (messageError !== "")
      setTimeout(() => {
        setMessageError("");
      }, NOTIF_DELAY);
  }, [messageError]);

  useEffect(() => {
    if (messageSuccess !== "")
      setTimeout(() => {
        setMessageSuccess("");
      }, NOTIF_DELAY);
  }, [messageSuccess]);

  const uploadImageRef = useRef(null);
  let inputFile: any;
  let styleCSS = {
    verticalAlign: "middle",
    borderRadius: "50%",
    height: props.height ? props.height : "100px",
    width: props.width ? props.width : "100px",
  };

  const readURL = (files: any) => {
    if (files && files[0]) {
      const extension = files[0].name.split(".").pop();
      if (
        extension !== "png" &&
        extension !== "jpg" &&
        extension !== "jpeg" &&
        extension !== "JPEG" &&
        extension !== "JPG" &&
        extension !== "PNG"
      ) {
        setMessageError("Merci de choisir un fichier de format image.");
        setMessageSuccess("");

        return null;
      }

      if (files[0].size > MAX_SIZE) {
        setMessageError(
          "La taille de l’image est trop grande , veuillez recharger une image en dessous de 2Mo."
        );
        setMessageSuccess("");

        return null;
      }

      let reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = (e: any) => {
        setAvatarSrc(e?.target?.result);
      };

      let data = new FormData();

      data.append("avatar", files[0]);

      UtilisateurService.uploadAvatar(data)
        .then((res: any) => {
          if (res.status === 200 || res.status === 201) {
            dispatch("UPDATE_AVATAR", res.data.avatar);
            setMessageSuccess("Votre avatar a bien été enregistré.");
            setMessageError("");
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  return (
    <>
      {props.upload ? (
        <>
          <div className="avatar-wrapper">
            <Image
              ref={uploadImageRef}
              className="profile-pic"
              src={avatarSrc}
              alt="Avatar"
              style={styleCSS}
            />

            {props?.loading && <div className="spinner" />}

            <div className="upload-button" onClick={() => inputFile?.click()}>
              <FontAwesomeIcon
                className="cloud-upload"
                icon={faCloudUploadAlt}
              />
            </div>
            <input
              style={{ display: "none" }}
              ref={(input) => (inputFile = input)}
              onChange={(e) => readURL(e?.target?.files)}
              className="file-upload"
              type="file"
              accept=".jpg, .jpeg, .png"
            />
          </div>

          {messageError !== "" && (
            <Alert
              className="custom-alert error"
              show={messageError !== ""}
              onClose={() => setMessageSuccess("")}
              dismissible
            >
              <div>
                <Image src={AlertError} />
              </div>
              <div>{messageError}</div>
            </Alert>
          )}

          {messageSuccess !== "" && (
            <Alert
              className="custom-alert success"
              show={messageSuccess !== ""}
              onClose={() => setMessageSuccess("")}
              dismissible
            >
              <div>
                <Image src={AlertSuccess} />
              </div>
              <div>{messageSuccess}</div>
            </Alert>
          )}
        </>
      ) : (
        <Image
          className="avatar"
          src={avatarURL}
          alt="Avatar"
          style={styleCSS}
        />
      )}
    </>
  );
};

export default Avatar;
