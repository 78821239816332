import React, { useEffect } from "react";
import "./FooterPage.scss";

interface IProps {
  title: string;
  children?: any;
}
const FooterPage = ({ title, children }: IProps) => {
  useEffect(() => {
    const container: any = document.getElementById("container-fluid");
    
    window.scrollTo(0, 0);
    container?.parentNode?.classList?.add("footer-page");
    container?.parentNode?.parentNode?.classList?.add("footer-page-parent");

    return () => {
      container?.parentNode?.classList?.remove("footer-page");
      container?.parentNode?.parentNode?.classList?.remove(
        "footer-page-parent"
      );
    };
  }, []);
  
  return (
    <div className="FooterPage">
      <div className="footer-header">{title}</div>
      <div className="footer-content">{children}</div>
    </div>
  );
};

export default FooterPage;
