export const MATOMO_CONSTANT = {
  // Nombre de connexion des utilisateurs
  nbConnexion: {
    categorie: "Connexion",
    actions: {
      seConnecter: "Se connecter",
    },
    libelles: {
      typeUtilisateur:"Connexion utilisateur ",
      values: {
        interne: "Interne",
        externe: "Externe",
      },
      departement: {
        multiDep: "multi-départements",
      },
    },
  },
  // Nombre d'export
  nbExport: {
    categorie: "Export",
    actions: {
      carte: "Carte",
      tabCoupeAmg: "Tableau coupes aménagements",
      tabCoupeIns: "Tableau coupes inscrites",
      tabCoupeDes: "Tableau coupes désignées",
    },
    libelles: {
      typeUtilisateur: {
        interne: "Interne",
        externe: "Externe",
      },
      format: {
        pdf: "PDF",
        excel: "Excel",
      },
    },
  },
  // Nombre de navigation
  nbNavigation: {
    categorie: "Navigation",
    actions: {
      tutoriel: "Tutoriel",
      tabDeBordCom: "Tableau de bord commun",
      consultation: "Consultation",
    },
    libelles: {
      typeTutoriel: {
        presentation: "Présentation",
        carto: "Carto",
        utilisateur: "Utilisateur",
        tableaux: "Tableaux",
      },
      departement: {
        multiDep: "multi-dpt",
      },
      consultation: {
        infoGeneraL: "Infos générales forêt",
        contacts: "Contacts",
        autresContacts: "Autres contacts",
        tabCoupeAmg: "Tableau coupes aménagements",
        tabCoupeIns: "Tableau coupes inscrites",
        tabCoupeDes: "Tableau coupes désignées",
      },
    },
  },
  // Nombre de doc GED
  nbDocGed: {
    categorie: "Doc GED",
    actions: {
      previsualisation: "Prévisualisation",
      telechargement: "Téléchargement",
    },
  },
  // Nombre de mémo
  nbMemo: {
    categorie: "Mémo",
    actions: {
      creation: "Création",
    },
    libelles: {
      typeUtilisateur: {
        interne: "Interne",
        externe: "Externe",
      },
    },
  },
  // Nombre de recherche
  nbRecherche: {
    categorie: "Recherche",
    actions: {
      glossaire: "Glossaire",
    },
  },
  // Nombre d'inscription
  nbInscription: {
    categorie: "Inscription",
    actions: {
      validation: "Validation",
    },
    libelles: {
      nbInsEffect: "Nombre d'inscriptions effectuées",
    },
  },
  // Sats relatives au formulaire utilisateur
  nbUtilisateur: {
    categorie: "Utilisateur",
    actions: {
      ajoutPerimetre: "Ajout périmètre",
      reductionPerimetre: "Réduction périmètre",
    },
    libelles: {
      nombre: "Nombre",
    },
  },
};

export const MATOMO_CNX_CONST = {
  nbCnxTypeUser: "matomo-send-connexion-tu",
  nbCnxDpt: "matomo-send-connexion-dpt",
};
