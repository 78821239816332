import * as React from "react";

function SvgTrash(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.78 99" {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path
            d="M53.8 38.34a2.21 2.21 0 00-2.15 2.26v42.82a2.16 2.16 0 104.31 0V40.6a2.21 2.21 0 00-2.16-2.26"
            fill="#9e2a40"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="prefix__b">
          <path
            d="M28 38.34a2.2 2.2 0 00-2.15 2.26v42.82a2.15 2.15 0 104.3 0V40.6A2.2 2.2 0 0028 38.34"
            fill="#9e2a40"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="prefix__c">
          <path fill="none" d="M0 0h81.78v99H0z" />
        </clipPath>
        <clipPath id="prefix__d">
          <path fill="none" d="M-4.3-4.3h90.39v107.61H-4.3z" />
        </clipPath>
        <clipPath id="prefix__e">
          <path
            d="M40.89 38.34a2.2 2.2 0 00-2.15 2.26v42.82a2.15 2.15 0 104.3 0V40.6a2.2 2.2 0 00-2.15-2.26"
            fill="#9e2a40"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g data-name="Calque 2">
        <g data-name="Calque 1">
          <path
            d="M53.8 38.34a2.21 2.21 0 00-2.15 2.26v42.82a2.16 2.16 0 104.31 0V40.6a2.21 2.21 0 00-2.16-2.26"
            fill="#9e2a40"
            fillRule="evenodd"
          />
          <g clipPath="url(#prefix__a)">
            <path fill="#9e2a40" d="M47.35 34.04h12.91V90H47.35z" />
          </g>
          <path
            d="M28 38.34a2.2 2.2 0 00-2.15 2.26v42.82a2.15 2.15 0 104.3 0V40.6A2.2 2.2 0 0028 38.34"
            fill="#9e2a40"
            fillRule="evenodd"
          />
          <g clipPath="url(#prefix__b)">
            <path fill="#9e2a40" d="M21.52 34.04h12.91V90H21.52z" />
          </g>
          <g clipPath="url(#prefix__c)">
            <g clipPath="url(#prefix__d)">
              <path
                d="M6.7 29.47V86.6a12.69 12.69 0 003.46 8.82A11.72 11.72 0 0018.58 99H63.2a11.74 11.74 0 008.43-3.58 12.69 12.69 0 003.46-8.82V29.47a8.86 8.86 0 006.62-9.7 9 9 0 00-8.94-7.71H60.7v-2.9a9.06 9.06 0 00-2.7-6.5A9.36 9.36 0 0051.36 0H30.42a9.36 9.36 0 00-6.62 2.66 9.06 9.06 0 00-2.72 6.5v2.9H9a9 9 0 00-8.92 7.71 8.84 8.84 0 006.62 9.7zm56.5 64.89H18.58c-4 0-7.17-3.4-7.17-7.76V29.68h59V86.6c-.04 4.4-3.18 7.76-7.21 7.76zM25.8 9.16a4.4 4.4 0 011.34-3.22 4.54 4.54 0 013.28-1.3h20.94a4.58 4.58 0 013.29 1.3A4.39 4.39 0 0156 9.16v2.9H25.8zM9 16.69h63.77a4.18 4.18 0 110 8.35H9a4.18 4.18 0 110-8.35z"
                fill="#9e2a40"
                fillRule="evenodd"
              />
            </g>
          </g>
          <path
            d="M40.89 38.34a2.2 2.2 0 00-2.15 2.26v42.82a2.15 2.15 0 104.3 0V40.6a2.2 2.2 0 00-2.15-2.26"
            fill="#9e2a40"
            fillRule="evenodd"
          />
          <g clipPath="url(#prefix__e)">
            <path fill="#9e2a40" d="M34.43 34.04h12.91V90H34.43z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgTrash;
