import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState,} from "react";
import {Dropdown, Form} from "react-bootstrap";
import {normalizeText} from "../../../Utils/Helpers/TextHelper";
import CActionsButtons from "./CActionsButtons";
import {IFilterProps} from "./CInterface";
import { Comparator } from "react-bootstrap-table2-filter";

const CSelectFilter = forwardRef(
  ({ column, onFilter, onToggle, show, defaultFilter, comparator}: IFilterProps, ref: any) => {
    const [input, setInput] = useState<string>("");
    const [columnData, setColumnData] = useState<any[]>(column?.dataList);
    const [dropdownCheckedList, setDropdownCheckedList] = useState<any[]>([]);

    const inputRef = useCallback(
      (node) => {
        if (node && show[column.dataField]) {
          node?.focus();
        }
      },
      [show[column.dataField]]
    );

    useEffect(() => {
      if (column.filter.props.getFilter) {
        column.filter.props.getFilter((filterVal: any) => {          
          onFilter(filterVal);
        });
      }
    });

    useImperativeHandle(ref, () => ({
      onReset: () => {
        onReset();
      },
    }));

    useEffect(() => {
      if (input !== '' && comparator) {
        let operation = (input: any, value: any) => value.includes(input);
        switch (comparator) {
          case Comparator.EQ:
            operation = (input: any, value: any) => value === input;
            break;
          case Comparator.NE:
            operation = (input: any, value: any) => value !== input;
            break;
          case Comparator.GT:
            operation = (input: any, value: any) => value > input;
            break;
          case Comparator.GE:
            operation = (input: any, value: any) => value >= input;
            break;
          case Comparator.LT:
            operation = (input: any, value: any) => value < input;
            break;
          case Comparator.LE:
            operation = (input: any, value: any) => value <= input;
            break;
          case Comparator.LIKE:
          default:
            operation = (input: any, value: any) => value.includes(input);
            break;
        }

        const data: any = column?.dataList?.filter((d: any) => {
          let formattedInput = normalizeText(input).toLowerCase().trim();
          let formattedValue = normalizeText(d).toLowerCase().trim();
          return operation(formattedInput, formattedValue);
        });

        setColumnData(data);
      } else if (column?.dataList) {
        const data: any = column?.dataList?.filter((d: any) =>
          normalizeText(d)
            .toLowerCase()
            .trim()
            .includes(normalizeText(input).toLowerCase().trim())
        );

        setColumnData(data);
      }
      if(column?.defaultFilter?.length>0){
        let tmp :any[]= [];
            column?.dataList.map((d:any)=>{
                 if(column?.defaultFilter.includes(d)){
                     tmp.push(d);
                 }
             });
        setDropdownCheckedList(tmp)
      }
    }, [input, column?.dataList, column?.defaultFilter]);

    const onFilterHandler = () => {
      onFilter(dropdownCheckedList);
    };

    const onSubmit = (e: any) => {
      e.preventDefault();
      onToggle(column.dataField, false);
      onFilterHandler();
    };

    const onReset = () => {
      setInput("");
      setDropdownCheckedList([]);
      onToggle(column.dataField, false);
      onFilter(null);
    };

    const onCheckItem = (e: any, data: any) => {
      const arrayHelpers: any[] = [...dropdownCheckedList];

      if (e.target.checked) {
        arrayHelpers.push(data);
      } else {
        const idx = arrayHelpers.indexOf(data);
        if (idx > -1) arrayHelpers.splice(idx, 1);
      }

      setDropdownCheckedList(arrayHelpers);
    };

    const onCheckAll = (e: any) => {
      if (e.target.checked) {
        setDropdownCheckedList(columnData);
      } else {
        setDropdownCheckedList([]);
      }
    };

    const getText = (column: any) => {
      if (typeof column.text === 'string') {
        return column.text.toLowerCase();
      }
      return column.title.toLowerCase(); // used when column.text is a react element
    };

    return (
      <form onSubmit={onSubmit} noValidate className="p-2">
        <input
          ref={inputRef}
          className="table-input"
          type="text"
          value={input}
          placeholder={`Rechercher par ${getText(column)}`}
          onChange={(e: any) => setInput(e.target.value)}
          autoComplete="off"
        />

        <ul className="table-dropdown-menu-content">
          {columnData && columnData.length > 0 && (
            <>
              <li className="table-dropdown-menu-item">
                <Form.Check
                  id={`tout-selectionner ${column.dataField}`}
                  type="checkbox"
                  label="TOUT SELECTIONNER"
                  name={column.dataField}
                  value=""
                  checked={dropdownCheckedList.length === columnData.length}
                  onChange={(e: any) => onCheckAll(e)}
                />
              </li>
              {columnData.map((d: any) => (
                <li key={d} className="table-dropdown-menu-item">
                  <Form.Check
                    id={column.dataField + d}
                    type="checkbox"
                    label={d}
                    name={column.dataField}
                    value={d}
                    checked={dropdownCheckedList.includes(d)}
                    onChange={(e: any) => onCheckItem(e, d)}
                  />
                </li>
              ))}
            </>
          )}
          {columnData.length === 0 && (
            <div className="text-center">Aucun résultat</div>
          )}
        </ul>

        <Dropdown.Divider />

        <CActionsButtons onSubmit={onSubmit} onReset={onReset} />
      </form>
    );
  }
);

export default CSelectFilter;
