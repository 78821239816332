import React from "react";
import { IMenu } from "../../Utils/interface";
import {
    FAQ_ADMIN_ACCESS,
    GED_ACCESS,
    ROLE_CREATE,
    ROLE_DELETE,
    ROLE_MODIFY,
    SDI_ACCESS,
    USER_ACTIVATE_MASS,
    USER_DEACTIVATE_MASS,
    USER_MANAGEMENT_ACCESS,
    VALIDATION_COMPTES_ACCESS
} from "../../Utils/Permissions";
import {
    SvgFile,
    SvgHome,
    SvgQuestionCircle,
    SvgUserCogs
} from "../Components/SVG";
import SvgParametre from "../Components/SVG/SvgParametre";

export const getMenu = () => {
  const Menu: IMenu[] = [
    {
      title: "Mes données",
      link: "/accueil",
      icon: <SvgHome />,
    },
    {
      title: "Mes documents",
      link: "/documents",
      icon: <SvgFile />,
      permissions: [GED_ACCESS],
      isAdmin: true,
    },
    {
      title: "En savoir plus",
      link: "/glossaire",
      icon: <SvgQuestionCircle />,
    },
    {
      title: "Gestion des utilisateurs",
      link: "/gestion",
      icon: <SvgUserCogs />,
      permissions: [USER_MANAGEMENT_ACCESS],
      subMenu: [
        {
          title: "Représentant",
          link: "/gestion/representant",
          permissions: [USER_MANAGEMENT_ACCESS],
        },
        {
          title: "Utilisateurs",
          link: "/gestion/utilisateurs",
          permissions: [USER_MANAGEMENT_ACCESS],
        },
        {
          title: "Admin des inscriptions",
          link: "/gestion/validation_comptes",
          permissions: [VALIDATION_COMPTES_ACCESS],
          isAdmin: true,
        },
        {
          title: "Suivi des inscriptions",
          link: "/gestion/suivi_inscription",
          permissions: [SDI_ACCESS],
          isAdmin: true,
          isInternalUser: true,
        },
        {
          title: "Rôles et permissions",
          link: "/gestion/roles",
          permissions: [ROLE_CREATE, ROLE_MODIFY, ROLE_DELETE],
          isAdmin: true,
        },
        {
          title: "Actions de masse",
          link: "/gestion/action_masse",
          permissions: [USER_ACTIVATE_MASS, USER_DEACTIVATE_MASS],
          isAdmin: true,
        },
        {
          title: "Glossaire",
          link: "/gestion/glossaire_faq",
          permissions: [FAQ_ADMIN_ACCESS],
          isAdmin: true,
        },
      ],
    },
    {
      title: "Paramétrage",
      link: "/parametre",
      icon: <SvgParametre />,
      isAdmin: true,
    },
  ];

  return Menu;
};