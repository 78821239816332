import React from "react";
import Spinner from "../Components/Spinner/Spinner";
import "./Loading.scss"

export const Loading = () => {
  return (
    <div className="Loading">
      <Spinner />
    </div>
  );
};

export default Loading;
