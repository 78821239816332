import * as React from "react";

function SvgEdit(props: any) {
  return (
    <svg
      id="edit_svg__Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="edit_svg__clip-path">
          <path
            className="edit_svg__cls-1"
            d="M63.8 52.68l25.79-36.15 1.35 1-26.73 37.22-.79.29zM7.55 7.27h58.77v24.21A3.14 3.14 0 0069 34.61l-10.67 15a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.2 3.2 0 001.08 2.94 3.09 3.09 0 003 .54l6.55-2.46a3.18 3.18 0 00-.64 1.12c-.19.59-.29.89-.17 18.69L55.21 91.63H7.55zM4.45 1a3.13 3.13 0 00-3.1 3.16v90.63A3.14 3.14 0 004.45 98h52.06a3.14 3.14 0 002.21-1l12.91-13.3a3.22 3.22 0 00.89-2.24c-.06-8.08-.08-17.47 0-18.51a3.16 3.16 0 00-2.09-3.45 3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26L97.75 18.8a3.18 3.18 0 00.6-1.83 3.19 3.19 0 00-1.24-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3.14 3.14 0 00-4.83.23l-14 19.58V4.11A3.13 3.13 0 0069.42 1z"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-2">
          <path fill="none" d="M-2090.48-931.1h6832.17v4230.04h-6832.17z" />
        </clipPath>
        <clipPath id="edit_svg__clip-path-3">
          <path className="edit_svg__cls-3" d="M1.35.95h97v97h-97z" />
        </clipPath>
        <clipPath id="edit_svg__clip-path-6">
          <path
            className="edit_svg__cls-1"
            d="M12.82 26.25c-1.67 0-3 .95-3 2.11s1.37 2.11 3 2.11h44.53c1.68 0 3-.95 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-8">
          <path className="edit_svg__cls-3" d="M9.78 26.25h50.61v4.22H9.78z" />
        </clipPath>
        <clipPath id="edit_svg__clip-path-11">
          <path
            className="edit_svg__cls-1"
            d="M12.82 38.9c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11h44.53c1.68 0 3-.95 3-2.11s-1.36-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-13">
          <path className="edit_svg__cls-3" d="M9.78 38.9h50.61v4.22H9.78z" />
        </clipPath>
        <clipPath id="edit_svg__clip-path-16">
          <path
            className="edit_svg__cls-1"
            d="M12.82 51.56c-1.68 0-3 .94-3 2.1s1.36 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.1-3-2.1z"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-18">
          <path className="edit_svg__cls-3" d="M9.78 51.56h42.17v4.22H9.78z" />
        </clipPath>
        <clipPath id="edit_svg__clip-path-21">
          <path
            className="edit_svg__cls-1"
            d="M12.82 68.42c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.11-3-2.11z"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-23">
          <path className="edit_svg__cls-3" d="M9.78 68.42h42.17v4.22H9.78z" />
        </clipPath>
        <clipPath id="edit_svg__clip-path-26">
          <path
            className="edit_svg__cls-1"
            d="M-542.67 172.77c-1.72 0-3.11 1-3.11 2.15s1.39 2.16 3.11 2.16h45.44c1.71 0 3.1-1 3.1-2.16s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-27">
          <path
            className="edit_svg__cls-5"
            d="M-542.67 185.69c-1.72 0-3.11 1-3.11 2.15s1.39 2.15 3.11 2.15h45.44c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-28">
          <path
            className="edit_svg__cls-1"
            d="M-542.68 198.6c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-29">
          <path
            className="edit_svg__cls-1"
            d="M-542.68 215.82c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-30">
          <path
            className="edit_svg__cls-1"
            d="M195.75 38.69A2.2 2.2 0 00193.6 41v42.77a2.15 2.15 0 104.3 0V41a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-31">
          <path
            className="edit_svg__cls-1"
            d="M169.92 38.69a2.21 2.21 0 00-2.15 2.31v42.77a2.16 2.16 0 104.31 0V41a2.21 2.21 0 00-2.16-2.26"
          />
        </clipPath>
        <clipPath id="edit_svg__clip-path-32">
          <path
            className="edit_svg__cls-5"
            d="M182.84 38.69a2.2 2.2 0 00-2.15 2.31v42.77a2.15 2.15 0 104.3 0V41a2.2 2.2 0 00-2.15-2.26"
          />
        </clipPath>
        <style>
          {
            ".edit_svg__cls-1,.edit_svg__cls-3,.edit_svg__cls-4{fill:#333}.edit_svg__cls-1,.edit_svg__cls-5{clip-rule:evenodd}.edit_svg__cls-5{fill:none}.edit_svg__cls-4{fill-rule:evenodd}.edit_svg__cls-6{clip-path:url(#edit_svg__clip-path)}.edit_svg__cls-7{clip-path:url(#edit_svg__clip-path-2)}.edit_svg__cls-8{isolation:isolate}.edit_svg__cls-9{clip-path:url(#edit_svg__clip-path-3)}.edit_svg__cls-10{clip-path:url(#edit_svg__clip-path-6)}.edit_svg__cls-11{clip-path:url(#edit_svg__clip-path-8)}.edit_svg__cls-12{clip-path:url(#edit_svg__clip-path-11)}.edit_svg__cls-13{clip-path:url(#edit_svg__clip-path-13)}.edit_svg__cls-14{clip-path:url(#edit_svg__clip-path-16)}.edit_svg__cls-15{clip-path:url(#edit_svg__clip-path-18)}.edit_svg__cls-16{clip-path:url(#edit_svg__clip-path-21)}.edit_svg__cls-17{clip-path:url(#edit_svg__clip-path-23)}"
          }
        </style>
      </defs>
      <path
        className="edit_svg__cls-4"
        d="M63.8 52.68l25.79-36.15 1.35 1-26.73 37.22-.79.29zM7.55 7.27h58.77v24.21A3.14 3.14 0 0069 34.61l-10.67 15a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.2 3.2 0 001.08 2.94 3.09 3.09 0 003 .54l6.55-2.46a3.18 3.18 0 00-.64 1.12c-.19.59-.29.89-.17 18.69L55.21 91.63H7.55zM4.45 1a3.13 3.13 0 00-3.1 3.16v90.63A3.14 3.14 0 004.45 98h52.06a3.14 3.14 0 002.21-1l12.91-13.3a3.22 3.22 0 00.89-2.24c-.06-8.08-.08-17.47 0-18.51a3.16 3.16 0 00-2.09-3.45 3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26L97.75 18.8a3.18 3.18 0 00.6-1.83 3.19 3.19 0 00-1.24-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3.14 3.14 0 00-4.83.23l-14 19.58V4.11A3.13 3.13 0 0069.42 1z"
      />
      <g className="edit_svg__cls-6">
        <g className="edit_svg__cls-7">
          <g className="edit_svg__cls-8">
            <path className="edit_svg__cls-3" d="M1.35.95h97v97h-97z" />
            <g className="edit_svg__cls-9">
              <path
                className="edit_svg__cls-4"
                d="M63.8 52.68l25.79-36.15 1.35 1-26.73 37.22-.79.29zM7.55 7.27h58.77v24.21A3.14 3.14 0 0069 34.61l-10.67 15a3.22 3.22 0 00-.55 1.35l-1.36 8.45a3.2 3.2 0 001.08 2.94 3.09 3.09 0 003 .54l6.55-2.46a3.18 3.18 0 00-.64 1.12c-.19.59-.29.89-.17 18.69L55.21 91.63H7.55zM4.45 1a3.13 3.13 0 00-3.1 3.16v90.63A3.14 3.14 0 004.45 98h52.06a3.14 3.14 0 002.21-1l12.91-13.3a3.22 3.22 0 00.89-2.24c-.06-8.08-.08-17.47 0-18.51a3.16 3.16 0 00-2.09-3.45 3.07 3.07 0 00-1.94 0 2.56 2.56 0 00.2-.26L97.75 18.8a3.18 3.18 0 00.6-1.83 3.19 3.19 0 00-1.24-2.51c-3.18-2.43-5.59-4.34-5.79-4.52a3.14 3.14 0 00-4.83.23l-14 19.58V4.11A3.13 3.13 0 0069.42 1z"
              />
              <g className="edit_svg__cls-6">
                <path className="edit_svg__cls-3" d="M1.35.95h97v97h-97z" />
                <g className="edit_svg__cls-9">
                  <path
                    className="edit_svg__cls-3"
                    d="M-2.87-3.27h105.43v105.43H-2.87z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        className="edit_svg__cls-4"
        d="M12.82 26.25c-1.67 0-3 .95-3 2.11s1.37 2.11 3 2.11h44.53c1.68 0 3-.95 3-2.11s-1.36-2.11-3-2.11z"
      />
      <g className="edit_svg__cls-10">
        <g className="edit_svg__cls-7">
          <g className="edit_svg__cls-8">
            <path
              className="edit_svg__cls-3"
              d="M9.78 26.25h50.61v4.22H9.78z"
            />
            <g className="edit_svg__cls-11">
              <path
                className="edit_svg__cls-4"
                d="M12.82 26.25c-1.67 0-3 .95-3 2.11s1.37 2.11 3 2.11h44.53c1.68 0 3-.95 3-2.11s-1.36-2.11-3-2.11z"
              />
              <g className="edit_svg__cls-10">
                <path
                  className="edit_svg__cls-3"
                  d="M9.78 26.25h50.61v4.22H9.78z"
                />
                <g className="edit_svg__cls-11">
                  <path
                    className="edit_svg__cls-3"
                    d="M5.56 22.03H64.6v12.65H5.56z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        className="edit_svg__cls-4"
        d="M12.82 38.9c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11h44.53c1.68 0 3-.95 3-2.11s-1.36-2.11-3-2.11z"
      />
      <g className="edit_svg__cls-12">
        <g className="edit_svg__cls-7">
          <g className="edit_svg__cls-8">
            <path className="edit_svg__cls-3" d="M9.78 38.9h50.61v4.22H9.78z" />
            <g className="edit_svg__cls-13">
              <path
                className="edit_svg__cls-4"
                d="M12.82 38.9c-1.67 0-3 1-3 2.11s1.37 2.11 3 2.11h44.53c1.68 0 3-.95 3-2.11s-1.36-2.11-3-2.11z"
              />
              <g className="edit_svg__cls-12">
                <path
                  className="edit_svg__cls-3"
                  d="M9.78 38.9h50.61v4.22H9.78z"
                />
                <g className="edit_svg__cls-13">
                  <path
                    className="edit_svg__cls-3"
                    d="M5.56 34.69H64.6v12.65H5.56z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        className="edit_svg__cls-4"
        d="M12.82 51.56c-1.68 0-3 .94-3 2.1s1.36 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.1-3-2.1z"
      />
      <g className="edit_svg__cls-14">
        <g className="edit_svg__cls-7">
          <g className="edit_svg__cls-8">
            <path
              className="edit_svg__cls-3"
              d="M9.78 51.56h42.17v4.22H9.78z"
            />
            <g className="edit_svg__cls-15">
              <path
                className="edit_svg__cls-4"
                d="M12.82 51.56c-1.68 0-3 .94-3 2.1s1.36 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.1-3-2.1z"
              />
              <g className="edit_svg__cls-14">
                <path
                  className="edit_svg__cls-3"
                  d="M9.78 51.56h42.17v4.22H9.78z"
                />
                <g className="edit_svg__cls-15">
                  <path
                    className="edit_svg__cls-3"
                    d="M5.56 47.34h50.61v12.65H5.56z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        className="edit_svg__cls-4"
        d="M12.82 68.42c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.11-3-2.11z"
      />
      <g className="edit_svg__cls-16">
        <g className="edit_svg__cls-7">
          <g className="edit_svg__cls-8">
            <path
              className="edit_svg__cls-3"
              d="M9.78 68.42h42.17v4.22H9.78z"
            />
            <g className="edit_svg__cls-17">
              <path
                className="edit_svg__cls-4"
                d="M12.82 68.42c-1.68 0-3 1-3 2.11s1.36 2.11 3 2.11h36.1c1.67 0 3-.94 3-2.11s-1.37-2.11-3-2.11z"
              />
              <g className="edit_svg__cls-16">
                <path
                  className="edit_svg__cls-3"
                  d="M9.78 68.42h42.17v4.22H9.78z"
                />
                <g className="edit_svg__cls-17">
                  <path
                    className="edit_svg__cls-3"
                    d="M5.56 64.21h50.61v12.65H5.56z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        className="edit_svg__cls-4"
        d="M-548.06 153.4h60s-.14 56.27 0 74.43l-11.33 11.67h-48.65zm-3.16-6.45a3.19 3.19 0 00-3.17 3.22v92.55a3.2 3.2 0 003.17 3.23h53.13a3.15 3.15 0 002.25-1l13.19-13.58a3.32 3.32 0 00.91-2.28c-.06-8.24 0-79 0-79a3.19 3.19 0 00-3.17-3.22z"
      />
      <path
        className="edit_svg__cls-4"
        d="M-542.67 172.77c-1.72 0-3.11 1-3.11 2.15s1.39 2.16 3.11 2.16h45.44c1.71 0 3.1-1 3.1-2.16s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#edit_svg__clip-path-26)">
        <path
          className="edit_svg__cls-3"
          d="M-2689.34-804.31H4283.7v4317.26h-6973.04z"
        />
      </g>
      <path
        className="edit_svg__cls-4"
        d="M-542.68 198.6c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#edit_svg__clip-path-28)">
        <path
          className="edit_svg__cls-3"
          d="M-2689.34-804.31H4283.7v4317.26h-6973.04z"
        />
      </g>
      <path
        className="edit_svg__cls-4"
        d="M-542.68 215.82c-1.71 0-3.1 1-3.1 2.15s1.39 2.15 3.1 2.15h36.84c1.71 0 3.1-1 3.1-2.15s-1.39-2.15-3.1-2.15z"
      />
      <g clipPath="url(#edit_svg__clip-path-29)">
        <path
          className="edit_svg__cls-3"
          d="M-2689.34-804.31H4283.7v4317.26h-6973.04z"
        />
      </g>
      <path
        className="edit_svg__cls-4"
        d="M195.75 38.69A2.2 2.2 0 00193.6 41v42.77a2.15 2.15 0 104.3 0V41a2.2 2.2 0 00-2.15-2.26"
      />
      <g clipPath="url(#edit_svg__clip-path-30)">
        <path
          className="edit_svg__cls-3"
          d="M-2212.53-951.31h6973.04v4317.26h-6973.04z"
        />
      </g>
      <path
        className="edit_svg__cls-4"
        d="M169.92 38.69a2.21 2.21 0 00-2.15 2.31v42.77a2.16 2.16 0 104.31 0V41a2.21 2.21 0 00-2.16-2.26"
      />
    </svg>
  );
}

export default SvgEdit;
